import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import styled from "styled-components";
import LaddaButton, { ZOOM_OUT } from "react-ladda";
import FormHooks from "../../Hooks/FormHook";
import classNames from "classnames";
import { connect } from "react-redux";
import { ContactPost } from "../../action/contactAction";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ToastMessage from "../../DefaultComponets/ToastMessage/ToastMessage";
const Contact = (props) => {
  const [
    valuesObj,
    InputChange,
    OnSubmit,
    setDefaultValue,
    InputError,
    DefaultError,
  ] = FormHooks({
    name: {
      rule: "required|",
      field: "Name",
      defaultValue: "",
    },
    email: {
      rule: "required|email",
      field: "Email",
      defaultValue: "",
    },
    message: {
      rule: "required|max:355",
      field: "Message",
      defaultValue: "",
    },
  });
  const [loginLoading, setSubmitLoading] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    const validationFlag = await OnSubmit();

    if (validationFlag) {
      setSubmitLoading(true);
      let newObj = {
        name: valuesObj.name,
        email: valuesObj.email,
        message: valuesObj.message,
      };
      props.ContactPost(newObj).then((res) => {
        setSubmitLoading(false);
        if (res.status === 203) {
          toast.error(res.data.message);
        } else {
          toast.success(res.data.message);
        }
        setDefaultValue([{ name: "" }, { email: "" }, { message: "" }]);
      });
    }
  };

  return (
    <Container>
      <ToastMessage />
      <CenterRow>
        <Col xl="7" sm="7" lg="7" md="7">
          {/* <Title>Primary Example</Title> */}
          <section className="mb-4">
            {/* <!--Section heading--> */}
            <h2 className="h1-responsive font-weight-bold text-center my-4">
              Contact us
            </h2>
            {/* <p className="text-center w-responsive mx-auto mb-5">
              In publishing and graphic design, Lorem ipsum is a placeholder
              text commonly used to demonstrate the visual form of a document or
              a typeface without
            </p> */}
            <div className="row">
              <div
                className="col-lg-12 wow animate__animated animate__fadeInUp"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <Form>
                  <FormGroup className="mt-2">
                    <Label for="exampleEmail">Name</Label>
                    <span className="required">*</span>
                    <Col xs="12">
                      <Input
                        placeholder="Enter Name"
                        type="text"
                        name="name"
                        value={valuesObj.name ? valuesObj.name : ""}
                        className={classNames({
                          "is-invalid": InputError.name,
                        })}
                        onChange={InputChange}
                      />

                      {InputError.name && InputError.name.length > 0 ? (
                        <div className="invalid-feedback capital">
                          <em>{InputError.name}</em>
                        </div>
                      ) : null}
                    </Col>
                  </FormGroup>

                  <FormGroup className="mt-2">
                    <Label for="exampleEmail">Email</Label>
                    <span className="required">*</span>
                    <Col xs="12">
                      <Input
                        placeholder="Enter Email"
                        name="email"
                        type="email"
                        value={valuesObj.email ? valuesObj.email : ""}
                        className={classNames({
                          "is-invalid": InputError.email,
                        })}
                        onChange={InputChange}
                      />

                      {InputError.email && InputError.email.length > 0 ? (
                        <div className="invalid-feedback capital">
                          <em>{InputError.email}</em>
                        </div>
                      ) : null}
                    </Col>
                  </FormGroup>

                  <FormGroup className="mt-2">
                    <Label for="exampleText">Message</Label>
                    <span className="required">*</span>
                    <Input
                      name="message"
                      type="textarea"
                      placeholder="Enter Your Message"
                      value={valuesObj.message ? valuesObj.message : ""}
                      className={classNames({
                        "is-invalid": InputError.message,
                      })}
                      onChange={InputChange}
                      rows={4}
                    />
                    {InputError.message && InputError.message.length > 0 ? (
                      <div className="invalid-feedback capital">
                        <em>{InputError.message}</em>
                      </div>
                    ) : null}
                  </FormGroup>

                  <ContactUsButton>
                    <LaddaButton
                      onClick={(e) => onSubmit(e)}
                      className="btn ladda-button orange zoom-out px-4 btn-ladda mt-2"
                      loading={loginLoading}
                      data-color="#00b050"
                      data-style={ZOOM_OUT}
                    >
                      Submit
                    </LaddaButton>
                  </ContactUsButton>
                </Form>
              </div>
            </div>
          </section>
        </Col>
      </CenterRow>
    </Container>
  );
};

export default connect(null, { ContactPost })(Contact);
const CenterRow = styled(Row)`
  display: flex;
  justify-content: center;
`;

const Title = styled.p`
  font-size: 16px;
  font-weight: 800;
  margin: 0;
  color: #000;
`;
const ContactUsButton = styled.div`
  button {
    border: none;
    border-radius: 7px;
    padding: 5px 30px !important;
    color: #fff;
    font-size: 16px;

    background-color: ${(props) => props.theme.primaryColor};

    &:hover {
      background-color: ${(props) => props.theme.buttonHover};
    }

    &:active {
      background-color: ${(props) => props.theme.buttonHover};
      border-color: ${(props) => props.theme.buttonHover};
      box-shadow: ${(props) => "0 0 0 0.25rem " + props.theme.buttonActive};
    }
    &:focus {
      background-color: ${(props) => props.theme.buttonHover};
      border-color: ${(props) => props.theme.buttonHover};
      box-shadow: ${(props) => "0 0 0 0.25rem " + props.theme.buttonActive};
    }
  }
`;
