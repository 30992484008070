import React, { Fragment } from 'react'

import styled from 'styled-components'
import { Col } from 'reactstrap'
import { threePartString } from '../../../Helper'

const FillFunsheet = (props) => {
  const { selectedComment } = props
  let String1, String2, String3
  if (selectedComment) {
    if (/\s/g.test(selectedComment)) {
      let StringData = threePartString(selectedComment.trimStart())
      String1 = StringData.String1
      String2 = StringData.String2
      String3 = StringData.String3
    } else {
      String1 = selectedComment.substr(0, 16)
      String2 = selectedComment.substr(16, 32)
      String3 = selectedComment.substr(32)
    }
  }

  const InputField1 = () => {
    let content = []
    for (let i = 0; i < 16; i++) {
      content.push(
        <FieldButton key={i}>{String1 ? String1[i] : ''}</FieldButton>
      )
    }
    return content
  }
  const InputField2 = () => {
    let content = []
    for (let i = 0; i < 16; i++) {
      content.push(
        <FieldButton key={i}>{String2 ? String2[i] : ''}</FieldButton>
      )
    }
    return content
  }
  const InputField3 = () => {
    let content = []
    for (let i = 0; i < 16; i++) {
      content.push(
        <FieldButton key={i}>{String3 ? String3[i] : ''}</FieldButton>
      )
    }
    return content
  }
  return (
    <Fragment>
      <Col sm='12' md='12'>
        <FieldDISPLAY>{InputField1()}</FieldDISPLAY>
        <FieldDISPLAY className='my-4'>{InputField2()}</FieldDISPLAY>
        <FieldDISPLAY>{InputField3()}</FieldDISPLAY>
      </Col>
    </Fragment>
  )
}

FillFunsheet.propTypes = {}

export default FillFunsheet

const FieldDISPLAY = styled.div`
  display: flex;
`
const FieldButton = styled.button`
  height: 28px;
  width: 29px;
  border-radius: 4px;
  background: #ffffff;
  border: ${(props) => `1px solid ${props.theme.primaryColor}`};
  margin: 0px 4px;
`
