import axios from "axios";
import { toast } from "react-toastify";

const PROXY = process.env.REACT_APP_URL + "api/";
const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

export const getAllfraction = (type) => async (dispatch, getState) => {
  try {
    let { rationalList } = getState().primaryMaths;
    const res = await axios.get(`${PROXY}primary/rational/simplify-fractions`);
    let data = {
      title: res.data.result.title,
      result: res.data.result.result,
      type: type,
    };
    rationalList.push(data);

    dispatch({
      type: "GET_RATIONAL_NUMBER",
      payload: rationalList,
    });
  } catch (err) {}
};

export const getAllImproper = (type, optType) => async (dispatch, getState) => {
  try {
    let { rationalList } = getState().primaryMaths;
    const res = await axios.get(
      `${PROXY}primary/rational/simplify-fractions?type=${optType}`
    );
    let data = {
      title: res.data.result.title,
      result: res.data.result.result,
      type: type,
    };
    rationalList.push(data);
    dispatch({
      type: "GET_RATIONAL_NUMBER",
      payload: rationalList,
    });
  } catch (err) {
    if (err.response?.data) {
      toast.error(err.response.data.message);
    } else {
      toast.error("error occured please try again later");
    }
  }
};

export const getAllMidImproper =
  (type, optType) => async (dispatch, getState) => {
    try {
      let { rationalList } = getState().primaryMaths;
      const res = await axios.get(
        `${PROXY}primary/rational/simplify-fractions-mid?type=${optType}`
      );
      let data = {
        title: res.data.result.title,
        result: res.data.result.result,
        type: type,
      };
      rationalList.push(data);
      dispatch({
        type: "GET_RATIONAL_NUMBER",
        payload: rationalList,
      });
    } catch (err) {
      if (err.response?.data) {
        toast.error(err.response.data.message);
      } else {
        toast.error("error occured please try again later");
      }
    }
  };

export const getAllFraction = (type, optType) => async (dispatch, getState) => {
  try {
    let { rationalList } = getState().primaryMaths;
    const res = await axios.get(
      `${PROXY}primary/rational/fraction_operations?type=${optType}`
    );

    let data = {
      title: res.data.result.title,
      result: res.data.result.result,
      type: type,
    };
    rationalList.push(data);
    dispatch({
      type: "GET_RATIONAL_NUMBER",
      payload: rationalList,
    });
  } catch (err) {
    if (err.response) {
      toast.error(err.response.data.message);
    } else {
      toast.error("Server error");
    }
  }
};

export const getAllFindOf = (type, optType) => async (dispatch, getState) => {
  try {
    let { rationalList } = getState().primaryMaths;
    const res = await axios.get(
      `${PROXY}primary/rational/find-of?type=${optType}`
    );

    let data = {
      title: res.data.result.title,
      result: res.data.result.result,
      type: type,
    };
    rationalList.push(data);
    dispatch({
      type: "GET_RATIONAL_NUMBER",
      payload: rationalList,
    });
  } catch (err) {
    if (err.response) {
      toast.error(err.response.data.message);
    } else {
      toast.error("Server error");
    }
  }
};

export const getAllDecimal = (type, optType) => async (dispatch, getState) => {
  try {
    let { rationalList } = getState().primaryMaths;
    const res = await axios.get(
      `${PROXY}primary/rational/decimal_fractions?type=${optType}`
    );

    let data = {
      title: res.data.result.title,
      result: res.data.result.result,
      type: type,
    };
    rationalList.push(data);
    dispatch({
      type: "GET_RATIONAL_NUMBER",
      payload: rationalList,
    });
  } catch (err) {
    if (err.response) {
      toast.error(err.response.data.message);
    } else {
      toast.error("Server error");
    }
  }
};

export const getAllDecimalFind =
  (type, optType) => async (dispatch, getState) => {
    try {
      let { rationalList } = getState().primaryMaths;
      const res = await axios.get(
        `${PROXY}primary/rational/decimal_fractions/find?type=${optType}`
      );

      let data = {
        title: res.data.result.title,
        result: res.data.result.result,
        type: type,
      };
      rationalList.push(data);
      dispatch({
        type: "GET_RATIONAL_NUMBER",
        payload: rationalList,
      });
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.message);
      } else {
        toast.error("Server error");
      }
    }
  };

//
export const getAllPercentage =
  (type, optType) => async (dispatch, getState) => {
    try {
      let { rationalList } = getState().primaryMaths;
      const res = await axios.get(
        `${PROXY}primary/rational/percentages?type=${optType}`
      );

      let data = {
        title: res.data.result.title,
        result: res.data.result.result,
        type: type,
      };
      rationalList.push(data);
      dispatch({
        type: "GET_RATIONAL_NUMBER",
        payload: rationalList,
      });
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.message);
      } else {
        toast.error("Server error");
      }
    }
  };

export const rationPdfGenerate = (data) => async (dispatch) => {
  const body = JSON.stringify(data);
  try {
    const res = await axios.post(
      `${PROXY}primary/rational/pdf-generate`,
      body,
      config
    );
    // toast.success(res.data.message);
    toast.success("PDF Generate SuccessFully. Wait to be Displayed");
    // setTimeout(() => {
    //   const link = document.createElement("a");
    //   link.href = res.data.result;
    //   link.target = "_blank";
    //   link.setAttribute("download", res.data.result);
    //   document.body.appendChild(link);
    //   link.click();
    //   link.parentNode.removeChild(link);
    // }, 1500);
    return res.data;
  } catch (err) {
    toast.error(err.response.data.message);
  }
};
