import axios from 'axios';
import { toast } from 'react-toastify';

const PROXY = process.env.REACT_APP_URL + 'api/';
const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export const getAllAdditionNumber =
  (operationType, type = 1) =>
  async (dispatch, getState) => {
    let { primaryMathAddition } = getState().primaryMaths;
    let code = '';
    const res = await axios.get(
      `${PROXY}primary/maths/whole-number/addition?type=${type}&code=${code}`
    );
    let data = {
      title: res.data.result.title,
      type: operationType,
      result: res.data.result.result,
      symbol: res.data.result.selectedSymbol,
    };

    primaryMathAddition.push(data);

    dispatch({
      type: 'GET_WHOLE_NUMBER',
      payload: primaryMathAddition,
    });

    //
  };
export const getAllSubtractNumber =
  (operationType, type = 1) =>
  async (dispatch, getState) => {
    let { primaryMathAddition } = getState().primaryMaths;
    let code = primaryMathAddition.map((s) => s.symbol);

    const res = await axios.get(
      `${PROXY}primary/maths/whole-number/subtract?type=${type}&code=${code}`
    );

    let data = {};
    if (type === 1) {
      data = {
        title: res.data.result.title,
        type: operationType,
        result: res.data.result.result,
        symbol: res.data.result.selectedSymbol,
      };
    } else {
      data = {
        title: res.data.result.title,
        type: operationType,
        result: res.data.result.result,
        symbol: res.data.result.selectedSymbol,
      };
    }

    primaryMathAddition.push(data);

    dispatch({
      type: 'GET_WHOLE_NUMBER',
      payload: primaryMathAddition,
    });
  };
// Multiplication
export const getAllMultiplication =
  (operationType, type = 1) =>
  async (dispatch, getState) => {
    let { primaryMathAddition } = getState().primaryMaths;
    let code = primaryMathAddition.map((s) => s.symbol);
    const res = await axios.get(
      `${PROXY}primary/maths/whole-number/multiply?type=${type}&code=${code}`
    );

    let data = {};
    if (type === 1) {
      data = {
        title: res.data.result.title,
        type: operationType,
        result: res.data.result.result,
        symbol: res.data.result.selectedSymbol,
      };
    } else {
      data = {
        title: res.data.result.title,
        type: operationType,
        result: res.data.result.result,
        symbol: res.data.result.selectedSymbol,
      };
    }

    primaryMathAddition.push(data);

    dispatch({
      type: 'GET_WHOLE_NUMBER',
      payload: primaryMathAddition,
    });
  };

//  Divide
export const getAllDivide =
  (operationType, type = 1) =>
  async (dispatch, getState) => {
    let { primaryMathAddition } = getState().primaryMaths;
    let code = primaryMathAddition.map((s) => s.symbol);
    const res = await axios.get(
      `${PROXY}primary/maths/whole-number/divide?type=${type}&code=${code}`
    );

    let data = {};
    if (type === 1) {
      data = {
        title: res.data.result.title,
        type: operationType,
        result: res.data.result.result,
        symbol: res.data.result.selectedSymbol,
      };
    } else {
      data = {
        title: res.data.result.title,
        type: operationType,
        result: res.data.result.result,
        symbol: res.data.result.selectedSymbol,
      };
    }

    primaryMathAddition.push(data);

    dispatch({
      type: 'GET_WHOLE_NUMBER',
      payload: primaryMathAddition,
    });
  };

//  Square
export const getAllSquare =
  (operationType, type = 1) =>
  async (dispatch, getState) => {
    let { primaryMathAddition } = getState().primaryMaths;
    let code = primaryMathAddition.map((s) => s.symbol);
    const res = await axios.get(
      `${PROXY}primary/maths/whole-number/square?type=${type}&code=${code}`
    );
    let data = {};
    if (type === 1) {
      data = {
        title: res.data.result.title,
        type: operationType,
        result: res.data.result.result,
        symbol: res.data.result.selectedSymbol,
      };
    } else {
      data = {
        title: res.data.result.title,
        type: operationType,
        result: res.data.result.result,
        symbol: res.data.result.selectedSymbol,
      };
    }
    primaryMathAddition.push(data);

    dispatch({
      type: 'GET_WHOLE_NUMBER',
      payload: primaryMathAddition,
    });
  };

//  Square root
export const getAllSquareRoot =
  (operationType, type = 1) =>
  async (dispatch, getState) => {
    let { primaryMathAddition } = getState().primaryMaths;
    let code = primaryMathAddition.map((s) => s.symbol);
    const res = await axios.get(
      `${PROXY}primary/maths/whole-number/square-root?type=${type}&code=${code}`
    );

    let data = {};
    if (type === 1) {
      data = {
        title: res.data.result.title,
        type: operationType,
        result: res.data.result.result,
        symbol: res.data.result.selectedSymbol,
      };
    } else {
      data = {
        title: res.data.result.title,
        type: operationType,
        result: res.data.result.result,
        symbol: res.data.result.selectedSymbol,
      };
    }

    primaryMathAddition.push(data);

    dispatch({
      type: 'GET_WHOLE_NUMBER',
      payload: primaryMathAddition,
    });
  };

// ┌──────────────────────────────────────────────────────────────────────────────┐
// │ Two operation                                                                │
// └──────────────────────────────────────────────────────────────────────────────┘

// add then multiply

export const getAddThenMultiply =
  (operationType, type = 1) =>
  async (dispatch, getState) => {
    let { primaryMathAddition } = getState().primaryMaths;
    let code = primaryMathAddition.map((s) => s.symbol);
    const res = await axios.get(
      `${PROXY}primary/maths/whole-number/two-operation/add-multiply?type=${type}&code=${code}`
    );
    let data = {
      title: res.data.result.title,
      type: operationType,
      result: res.data.result.result,
      symbol: res.data.result.selectedSymbol,
    };
    primaryMathAddition.push(data);
    dispatch({
      type: 'GET_WHOLE_NUMBER',
      payload: primaryMathAddition,
    });
  };

//
export const getSubtractThenMultiply =
  (operationType, type = 1) =>
  async (dispatch, getState) => {
    let { primaryMathAddition } = getState().primaryMaths;
    let code = primaryMathAddition.map((s) => s.symbol);
    const res = await axios.get(
      `${PROXY}primary/maths/whole-number/two-operation/subtract-multiply?type=${type}&code=${code}`
    );
    let data = {
      title: res.data.result.title,
      type: operationType,
      result: res.data.result.result,
      symbol: res.data.result.selectedSymbol,
    };
    primaryMathAddition.push(data);
    dispatch({
      type: 'GET_WHOLE_NUMBER',
      payload: primaryMathAddition,
    });
  };

export const getMultiplyThenAdd =
  (operationType, type = 1) =>
  async (dispatch, getState) => {
    let { primaryMathAddition } = getState().primaryMaths;
    let code = primaryMathAddition.map((s) => s.symbol);
    const res = await axios.get(
      `${PROXY}primary/maths/whole-number/two-operation/multiply-add?type=${type}&code=${code}`
    );
    let data = {
      title: res.data.result.title,
      type: operationType,
      result: res.data.result.result,
      symbol: res.data.result.selectedSymbol,
    };
    primaryMathAddition.push(data);
    dispatch({
      type: 'GET_WHOLE_NUMBER',
      payload: primaryMathAddition,
    });
  };

export const getMultiplyThenSubtract =
  (operationType, type = 1) =>
  async (dispatch, getState) => {
    let { primaryMathAddition } = getState().primaryMaths;
    let code = primaryMathAddition.map((s) => s.symbol);
    const res = await axios.get(
      `${PROXY}primary/maths/whole-number/two-operation/multiply-subtract?type=${type}&code=${code}`
    );
    let data = {
      title: res.data.result.title,
      type: operationType,
      result: res.data.result.result,
      symbol: res.data.result.selectedSymbol,
    };
    primaryMathAddition.push(data);
    dispatch({
      type: 'GET_WHOLE_NUMBER',
      payload: primaryMathAddition,
    });
  };

export const getDivideThenAdd =
  (operationType, type = 1) =>
  async (dispatch, getState) => {
    let { primaryMathAddition } = getState().primaryMaths;
    let code = primaryMathAddition.map((s) => s.symbol);
    const res = await axios.get(
      `${PROXY}primary/maths/whole-number/two-operation/divide-add?type=${type}&code=${code}`
    );
    let data = {
      title: res.data.result.title,
      type: operationType,
      result: res.data.result.result,
      symbol: res.data.result.selectedSymbol,
    };
    primaryMathAddition.push(data);
    dispatch({
      type: 'GET_WHOLE_NUMBER',
      payload: primaryMathAddition,
    });
  };
//

export const getDivideThenSubtract =
  (operationType, type = 1) =>
  async (dispatch, getState) => {
    let { primaryMathAddition } = getState().primaryMaths;
    let code = primaryMathAddition.map((s) => s.symbol);
    const res = await axios.get(
      `${PROXY}primary/maths/whole-number/two-operation/divide-subtract?type=${type}&code=${code}`
    );
    let data = {
      title: res.data.result.title,
      type: operationType,
      result: res.data.result.result,
      symbol: res.data.result.selectedSymbol,
    };
    primaryMathAddition.push(data);
    dispatch({
      type: 'GET_WHOLE_NUMBER',
      payload: primaryMathAddition,
    });
  };

export const getDivideThenMultiply =
  (operationType, type = 1) =>
  async (dispatch, getState) => {
    let { primaryMathAddition } = getState().primaryMaths;
    let code = primaryMathAddition.map((s) => s.symbol);
    const res = await axios.get(
      `${PROXY}primary/maths/whole-number/two-operation/divide-multiply?type=${type}&code=${code}`
    );
    let data = {
      title: res.data.result.title,
      type: operationType,
      result: res.data.result.result,
      symbol: res.data.result.selectedSymbol,
    };
    primaryMathAddition.push(data);
    dispatch({
      type: 'GET_WHOLE_NUMBER',
      payload: primaryMathAddition,
    });
  };

export const getSquareRootThenMultiply =
  (operationType, type = 1) =>
  async (dispatch, getState) => {
    let { primaryMathAddition } = getState().primaryMaths;
    let code = primaryMathAddition.map((s) => s.symbol);
    const res = await axios.get(
      `${PROXY}primary/maths/whole-number/two-operation/square-root-multiply?type=${type}&code=${code}`
    );
    let data = {
      title: res.data.result.title,
      type: operationType,
      result: res.data.result.result,
      symbol: res.data.result.selectedSymbol,
    };
    primaryMathAddition.push(data);
    dispatch({
      type: 'GET_WHOLE_NUMBER',
      payload: primaryMathAddition,
    });
  };

// ┌──────────────────────────────────────────────────────────────────────────────┐
// │ PDF GENERATE                                                                 │
// └──────────────────────────────────────────────────────────────────────────────┘

export const generateWholeNumberPdf = (data) => async (dispatch) => {
  const body = JSON.stringify(data);
  try {
    const res = await axios.post(
      `${PROXY}primary/maths/whole-number/two-operation/pdf`,
      body,
      config
    );
    toast.success(res.data.message);
    setTimeout(() => {
      const link = document.createElement('a');
      link.href = res.data.result;
      link.target = '_blank';
      link.setAttribute('download', res.data.result);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    }, 1500);
  } catch (err) {
    toast.error(err.response.data.message);
  }
};
