import React from 'react'
import { Col, Row, Table } from 'reactstrap'
import styled from 'styled-components'

// three table

export const SecondaryTables = (props) => {
  const { List, title, displayFlex, className } = props
  return (
    <PlainBox>
      <Row>
        <Col sm='12' md='12' lg='12' xs='12' className='text-center mt-2'>
          {title ? <p className='mb-0'>{title}</p> : null}
        </Col>
        <Col sm='12' md='12' lg='12' xs='12'>
          <TableCustoms borderless flexshow={displayFlex} className={className}>
            <tbody>
              {List.map((data, i) => (
                <tr key={i}>
                  <th>
                    <button>{data.title}</button>
                  </th>
                  <td>
                    <button
                      dangerouslySetInnerHTML={{
                        __html: data.ops1,
                      }}></button>
                  </td>
                  <td>
                    <button
                      dangerouslySetInnerHTML={{
                        __html: data.ops2,
                      }}></button>
                  </td>
                  {data.ops3 ? (
                    <td>
                      <button
                        dangerouslySetInnerHTML={{
                          __html: data.ops3,
                        }}></button>
                    </td>
                  ) : null}
                </tr>
              ))}
            </tbody>
          </TableCustoms>
        </Col>
      </Row>
    </PlainBox>
  )
}

export const ButtonsGroups = (props) => {
  const { title, List, displayFlex } = props

  return (
    <React.Fragment>
      <Col sm='6' md='6' lg='6' xs='6'>
        <LogButton>{title}</LogButton>
      </Col>
      <Col sm='12' md='12' lg='12' xs='12'>
        <LogButtonGroup flexshow={displayFlex}>
          {List.map((data) => (
            <button>{data.value}</button>
          ))}
        </LogButtonGroup>
      </Col>
    </React.Fragment>
  )
}

export const ButtonGroupData = (props) => {
  const { title, List, displayFlex, className, largeButton, smallBTN } = props

  return (
    <PlainBox className={className}>
      <Col sm='12' md='12' lg='12' xs='12' className='text-center mt-2'>
        <p className='mb-0'>{title}</p>
      </Col>
      <Col sm='12' md='12' lg='12' xs='12'>
        <BTNGROUP
          flexshow={displayFlex}
          largeButton={largeButton}
          smallBTN={smallBTN}>
          {List.map((data) => (
            <button>{data}</button>
          ))}
        </BTNGROUP>
      </Col>
    </PlainBox>
  )
}
export const InlineButtonGroup = (props) => {
  const { title, List, displayFlex } = props
  return (
    <Row>
      <FlexBox>
        <Col sm='10' md='10' lg='10' xs='10'>
          <Row>
            <Col sm='8' md='8' lg='8' xs='8'>
              <LogButton>{title}</LogButton>
            </Col>
            <Col sm='12' md='12' lg='12' xs='12'>
              <LogButtonGroup flexshow={displayFlex}>
                {List.map((data) => (
                  <button>{data.value}</button>
                ))}
              </LogButtonGroup>
            </Col>
          </Row>
        </Col>
      </FlexBox>
    </Row>
  )
}
//

const TableCustoms = styled(Table)`
  tr {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: ${(props) =>
      props.flexshow ? 'center' : 'space-between'};
  }
  th {
    width: ${(props) => (props.flexshow ? '30%' : '28%')};
    margin-right: ${(props) => (props.flexshow ? '40px' : '30px')};
    button {
      width: 100%;
      border: none;
      text-align: start;
      background: #fff;
      box-shadow: 0px 4px 9px rgb(0 0 0 / 5%);
      border-radius: 5px;
      font-size: 13px;
      padding: 5px 10px;
      font-family: ${(props) => props.theme.RobotoFont};
      font-weight: 500;
      @media (max-width: 1200px) {
        width: 100%;
      }
    }
  }

  td {
    button {
      width: 115px;
      height: 29px;
      border: none;
      border-radius: 27px;
      color: ${(props) => props.theme.buttonText};
      background: ${(props) => props.theme.primaryColor};
      font-family: ${(props) => props.theme.SeraiFont};
      box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.05);
      font-size: 12px;
    }
  }
  @media (max-width: 1200px) {
    th {
      margin-right: 0px;
      width: 24%;
    }
    td {
      button {
        width: 99px;
      }
    }
  }
`
const PlainBox = styled.div`
  p {
    font-size: 18px;
    font-family: ${(props) => props.theme.SeraiFont};
  }
`

const LogButton = styled.button`
  width: 100%;
  margin-left: 10px;
  border: none;
  font-family: 'Saira';
  text-transform: capitalize;
  text-align: left;
  padding: 4px 8px;
  border-radius: 5px;
  background: white;
  box-shadow: 0px 4px 9px rgb(0 0 0 / 5%);
  font-weight: 500;
  margin-top: 20px;
`
const LogButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: ${(props) => (props.flexshow ? 'center' : 'space-between')};
  margin-top: 20px;
  button {
    border: none;
    color: ${(props) => props.theme.buttonText};
    background-color: ${(props) => props.theme.primaryColor};
    padding: 5px 70px;
    border-radius: 27px;
    margin: 5px 0px;
    font-size: 12px;
    font-family: ${(props) => props.theme.SeraiFont};
  }
  @media (max-width: 1200px) {
    button {
      padding: 5px 48px;
    }
  }
`
const BTNGROUP = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: ${(props) => (props.flexshow ? 'center' : 'space-between')};
  margin-top: 10px;
  button {
    border: none;
    color: white;
    background-color: ${(props) => props.theme.primaryColor};
    margin: 5px 0px;
    border-radius: 27px;
    font-size: 12px;
    height: 29px;
    padding: ${(props) => (props.largeButton ? '0px 50px' : '0px 40px')};

    margin: ${(props) => props.flexshow && '0px 8px'};
    box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.05);
  }
  @media (max-width: 1200px) {
    button {
      padding: 0px 23px;
    }
  }
`
const FlexBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 1.5rem;
  ${LogButtonGroup} {
    button {
      padding: 5px 0px;
      margin: 0px 5px;
      width: 111.87px;
      @media (max-width: 1200px) {
        width: 84px;
      }
    }
  }
`
