import React, { Fragment, useEffect, useState } from "react";
// import PropTypes from 'prop-types'
import { Card, CardBody, Col, Row } from "reactstrap";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
// import { EarlyPrimaryContext } from '../../../api/contexts/context'
const StudentCard = (props) => {
  const removeValue = useDispatch();

  const [displayError, setDisplayError] = useState([]);

  const MathList = useSelector((state) => state.primaryMath);
  //
  const setdisplayErrorValue = (value, type) => {
    // type 1 add 2 remove
    if (type === 1) {
      let checkData = displayError.includes(value);
      if (!checkData) {
        setDisplayError([...displayError, value]);
      }
    } else {
      var index = displayError.indexOf(value);
      if (index !== -1) {
        displayError.splice(index, 1);
      }
      setDisplayError(displayError);
    }
  };
  useEffect(() => {
    removeValue({
      type: "DISPLAY_MESSAGE_LENGTH",
      payload: displayError,
    });
  }, [displayError]);
  useEffect(() => {
    if (MathList.maxLength) {
      setDisplayError(MathList.maxLength);
    }
  }, []);

  // close function
  const addMakeClose = (e) => {
    setdisplayErrorValue(e.target.value, 2);
    removeValue({
      type: "PRIMAY_MATHS_REMOVE",
    });
  };
  const addClose = (e) => {
    setdisplayErrorValue(e.target.value, 2);

    removeValue({
      type: "ADD_TO_MARK_REMOVE",
    });
    // setAddArray(...addArray)
  };
  const addClose1 = (e) => {
    setdisplayErrorValue(e.target.value, 2);
    removeValue({
      type: "ADD_REMOVE",
    });
  };
  const subColse = (e) => {
    setdisplayErrorValue(e.target.value, 2);
    removeValue({
      type: "SUBTRACT_REMOVE",
    });
  };

  const subFrom10 = (e) => {
    setdisplayErrorValue(e.target.value, 2);
    removeValue({
      type: "SUBTRACT_FROM_10_RAMOVE",
    });
  };
  const subFromClose = (e) => {
    setdisplayErrorValue(e.target.value, 2);
    removeValue({
      type: "SUBTRACT_FROM_REMOVE",
    });
  };
  const multiclose = (e) => {
    setdisplayErrorValue(e.target.value, 2);

    removeValue({
      type: "MULTIPLE_LIST_REMOVE",
    });
  };
  const divClose = (e) => {
    setdisplayErrorValue(e.target.value, 2);
    removeValue({
      type: "DIVIDED_LIST_REMOVE",
    });
  };
  const continueAddClose = (e) => {
    setdisplayErrorValue(e.target.value, 2);
    removeValue({
      type: "ADD_CONTINUE_REMOVE",
    });
  };

  const onMultipleAddClose = (e) => {
    setdisplayErrorValue(e.target.value, 2);
    removeValue({
      type: "ADD_ON_MULTIPLE_REMOVE",
    });
  };
  const offMultipleAddClose = (e) => {
    setdisplayErrorValue(e.target.value, 2);
    removeValue({
      type: "ADD_OFF_MULTIPLE_REMOVE",
    });
  };
  const subContinueClose = (e) => {
    setdisplayErrorValue(e.target.value, 2);
    removeValue({
      type: "SUB_CONTINUE_REMOVE",
    });
  };
  const subOnMultipleAddClose = (e) => {
    setdisplayErrorValue(e.target.value, 2);
    removeValue({
      type: "SUB_ON_MULTIPLE_REMOVE",
    });
  };
  const subOffMultipleAddClose = (e) => {
    setdisplayErrorValue(e.target.value, 2);
    removeValue({
      type: "SUB_OFF_MULTIPLE_REMOVE",
    });
  };
  return (
    <Fragment>
      {!MathList.loading &&
      (MathList.primaryMathList.length > 0 ||
        MathList.addToMark.length > 0 ||
        MathList.add.length > 0 ||
        MathList.subtract.length > 0 ||
        MathList.subtractFromTEN.length > 0 ||
        MathList.subtractFrom.length > 0 ||
        MathList.subtractFromTEN.length > 0 ||
        MathList.divided.length > 0 ||
        MathList.multiple.length > 0 ||
        MathList.continue_add.length > 0 ||
        MathList.on_multiple_data.length > 0 ||
        MathList.off_multiple_data.length > 0 ||
        MathList.continue_sub.length > 0 ||
        MathList.sub_on_multiple_data.length > 0 ||
        MathList.sub_off_multiple_data.length > 0) ? (
        <div className="mt-4">
          <StudentCardBox className="card-shadow">
            <StudentBoxBody>
              <Row className="justify-content-center">
                {!MathList.loading && MathList.primaryMathList.length > 0 ? (
                  <StudentBoxed
                    title={"Add to make"}
                    number={10}
                    closeBTN={(e) => addMakeClose(e)}
                    name={"Add_make_10"}
                    mathlist={MathList.primaryMathList}
                  />
                ) : null}
                {!MathList.loading && MathList.addToMark.length > 0 ? (
                  <StudentBoxed
                    title={"Add to make"}
                    number={
                      MathList.add_to_make
                        ? MathList.add_to_make
                        : MathList.rand_add_to_make
                    }
                    // number={context.add_to_make}
                    closeBTN={(e) => addClose(e)}
                    name={"Add_make"}
                    mathlist={MathList.addToMark}
                  />
                ) : null}
                {!MathList.loading && MathList.continue_add.length > 0 ? (
                  <StudentBoxed
                    title={"Starting from"}
                    number={` ${MathList.starting_add_value}, add ${MathList.continue_add_value} five times `}
                    closeBTN={(e) => continueAddClose(e)}
                    name={"continue_adding_manually"}
                    mathlist={MathList.continue_add}
                  />
                ) : null}
                {!MathList.loading && MathList.on_multiple_data.length > 0 ? (
                  <StudentBoxed
                    title={"Starting from"}
                    number={` ${MathList.starting_on_multiple}, add ${MathList.continue_on_multiple} five times `}
                    closeBTN={(e) => onMultipleAddClose(e)}
                    name={"continue_add_on_multiple"}
                    mathlist={MathList.on_multiple_data}
                  />
                ) : null}
                {!MathList.loading && MathList.off_multiple_data.length > 0 ? (
                  <StudentBoxed
                    title={"Starting from"}
                    number={` ${MathList.starting_off_multiple}, add ${MathList.continue_off_multiple} five times `}
                    closeBTN={(e) => offMultipleAddClose(e)}
                    name={"continue_add_off_multiple"}
                    mathlist={MathList.off_multiple_data}
                  />
                ) : null}
                {!MathList.loading && MathList.continue_sub.length > 0 ? (
                  <StudentBoxed
                    title={"Starting from"}
                    number={` ${MathList.starting_sub_value}, sub ${MathList.continue_sub_value} five times `}
                    closeBTN={(e) => subContinueClose(e)}
                    name={"continue_subtract_manually"}
                    mathlist={MathList.continue_sub}
                  />
                ) : null}
                {!MathList.loading &&
                MathList.sub_on_multiple_data.length > 0 ? (
                  <StudentBoxed
                    title={"Starting from"}
                    number={` ${MathList.sub_starting_on_multiple}, sub ${MathList.sub_continue_on_multiple} five times `}
                    closeBTN={(e) => subOnMultipleAddClose(e)}
                    name={"subtract_on_multiple"}
                    mathlist={MathList.sub_on_multiple_data}
                  />
                ) : null}
                {!MathList.loading &&
                MathList.sub_off_multiple_data.length > 0 ? (
                  <StudentBoxed
                    title={"Starting from"}
                    number={` ${MathList.sub_starting_off_multiple}, sub ${MathList.sub_continue_off_multiple} five times `}
                    closeBTN={(e) => subOffMultipleAddClose(e)}
                    name={"subtract_off_multiple"}
                    mathlist={MathList.sub_off_multiple_data}
                  />
                ) : null}
                {!MathList.loading && MathList.add.length > 0 ? (
                  <StudentBoxed
                    title={"Add"}
                    number={
                      MathList.add_value
                        ? MathList.add_value
                        : MathList.rand_add_value
                    }
                    closeBTN={(e) => addClose1(e)}
                    // number={context.addition}
                    name={"Add"}
                    mathlist={MathList.add}
                  />
                ) : null}
                {!MathList.loading && MathList.subtract.length > 0 ? (
                  <StudentBoxed
                    title={"Subtract"}
                    number={
                      MathList.subtract_value
                        ? MathList.subtract_value
                        : MathList.rand_subtract_value
                    }
                    // number={10}
                    closeBTN={(e) => subColse(e)}
                    name={"subtract"}
                    mathlist={MathList.subtract}
                  />
                ) : null}
                {!MathList.loading && MathList.subtractFromTEN.length > 0 ? (
                  <StudentBoxed
                    title={"Subtract from"}
                    number={
                      MathList.subtractFromTEN_value
                        ? MathList.subtractFromTEN_value
                        : MathList.rand_subtractFromTEN_value
                    }
                    // number={context.subtraction_from_10}
                    closeBTN={(e) => subFrom10(e)}
                    name="Subtract_from_10"
                    mathlist={MathList.subtractFromTEN}
                  />
                ) : null}
                {!MathList.loading && MathList.subtractFrom.length > 0 ? (
                  <StudentBoxed
                    title={"Subtract from"}
                    number={
                      MathList.subtractFrom_value
                        ? MathList.subtractFrom_value
                        : MathList.rand_subtractFrom_value
                    }
                    // number={context.subtraction_from}
                    closeBTN={(e) => subFromClose(e)}
                    name="Subtract_from"
                    mathlist={MathList.subtractFrom}
                  />
                ) : null}

                {!MathList.loading && MathList.multiple.length > 0 ? (
                  <StudentBoxed
                    title={"Multiply by"}
                    number={
                      MathList.multiple_value
                        ? MathList.multiple_value
                        : MathList.rand_multiple_value
                    }
                    closeBTN={(e) => multiclose(e)}
                    // number={context.multiply}
                    name="Multiply"
                    mathlist={MathList.multiple}
                  />
                ) : null}
                {!MathList.loading && MathList.divided.length > 0 ? (
                  <StudentBoxed
                    title={"Divide by"}
                    number={
                      MathList.divided_value
                        ? MathList.divided_value
                        : MathList.rand_divided_value
                    }
                    closeBTN={(e) => divClose(e)}
                    name="Divide"
                    mathlist={MathList.divided}
                  />
                ) : null}
              </Row>
            </StudentBoxBody>
          </StudentCardBox>
        </div>
      ) : null}
    </Fragment>
  );
};

export default StudentCard;

//

export const StudentBoxed = (props) => {
  const { title, number, mathlist } = props;
  return (
    <Col sm="6" lg="6" md="6" xl="6" xs="6">
      <ChildBoxedMake className="mt-3">
        <Col sm="12" lg="12" md="12" xl="12" xs="12">
          <CardHeading>
            <p>{`${title} ${number}`}</p>
            {/* <ButtonOrange value={name} onClick={(e) => closeBTN(e)}>
              X
            </ButtonOrange> */}
          </CardHeading>
        </Col>
        <Col sm="12" lg="12" md="12" xl="12" xs="12">
          <ButtonGroup1>
            {mathlist.length > 0
              ? mathlist.map((value, i) => (
                  <Fragment key={i}>
                    <button>{value}</button>
                  </Fragment>
                ))
              : null}
          </ButtonGroup1>
          <ButtonGroup2>
            <button></button>
            <button></button>
            <button></button>
            <button></button>
            <button></button>
          </ButtonGroup2>
          <ButtonGroup3>
            <button></button>
            <button></button>
            <button></button>
            <button></button>
            <button></button>
          </ButtonGroup3>
        </Col>
      </ChildBoxedMake>
    </Col>
  );
};

const StudentCardBox = styled(Card)`
  border-radius: 0.5rem;
  border: none;
`;

const StudentBoxBody = styled(CardBody)`
  padding: 1rem 1.5rem;
`;
const CardHeading = styled.div`
  display: flex;
  justify-content: space-between;
  p {
    font-weight: 500;
  }
`;

const ChildBoxedMake = styled(Card)`
  padding: 10px 12px;

  p {
    font-size: 14px;
  }
`;

const ButtonGroup1 = styled.div`
  display: flex;
  flex: 0 1 20%;

  justify-content: space-between;
  flex-wrap: wrap;
  button {
    height: 25px;
    width: 30px;
    font-size: 12px;
    border: ${(props) => `1px solid ${props.theme.primaryColor}`};
    background-color: #fff;

    border-radius: 4px;
  }
`;
const ButtonGroup2 = styled.div`
  display: flex;
  flex: 0 1 20%;
  /* align-items: center; */
  justify-content: space-between;
  /* margin-left: 2px; */
  /* flex-wrap: wrap; */
  button {
    height: 25px;
    width: 30px;
    font-size: 12px;
    /* padding: 4px 14px; */
    border: ${(props) => `1px solid ${props.theme.primaryColor}`};
    background-color: #fff;
    margin: 7px 0px;
    border-radius: 4px;
  }
`;
const ButtonGroup3 = styled.div`
  display: flex;
  flex: 0 1 20%;
  /* align-items: center; */
  justify-content: space-between;
  /* flex-wrap: wrap; */
  button {
    height: 25px;
    width: 30px;
    font-size: 12px;
    /* padding: 4px 14px; */
    border: ${(props) => `1px solid ${props.theme.primaryColor}`};
    background-color: #fff;

    border-radius: 4px;
  }
`;
