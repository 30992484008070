import React from "react";
import { useState } from "react";

import { Link } from "react-router-dom";
import { Col, Row, Card, Input, Container } from "reactstrap";
import styled from "styled-components";
const MessageCount = (props) => {
  const [stringCount, setStringCount] = useState("");
  const [count, setCount] = useState("");
  const [splitvalue, setSplitValue] = useState("");
  
  const onStringCount = () => {
      let cal = stringCount.length;
      let spl = stringCount.split("l","")
      setCount(cal);
      setSplitValue(spl)
    };
    
    console.log("🚀 ~ file: MessageCount.js:11 ~ MessageCount ~ splitvalue", splitvalue)
  return (
    <Container>
      <Row>
        <Col xl="12" sm="12" lg="12" md="12" className="display-msg">
          <p>
            <strong>Calculate String Length</strong>
          </p>
          <Col xl="8" sm="8" lg="8" md="8">
            <Input
              type="textarea"
              name="stringCount"
              value={stringCount}
              onChange={(e) => setStringCount(e.target.value)}
              placeholder="Enter Your Message"
              rows="8" 
            />
            {count !== "" ? (
              <ShowMessage>
                <p>
                  <strong>Your string is {count} characters length.<br/>
                  {/* {splitvalue}' */}
                  </strong>
                </p>
              </ShowMessage>
            ) : (
              ""
            )}
            <MessageButton>
              <button
                className="btn  orange  px-4 "
                data-color="#00b050"
                onClick={() => onStringCount()}
              >
                Calculate!
              </button>
            </MessageButton>
          </Col>
        </Col>
      </Row>
    </Container>
  );
};

export default MessageCount;

const FontSize = styled.h2`
  /* padding-top: 10px; */
  margin-top: 10px;
  font-weight: normal;
  font-size: 33px;
`;

const TextColor = styled.span`
  color: ${(props) => props.theme.primaryColor};
`;

const Para = styled.p`
  margin: 0px;
  font-size: 18px;
  font-weight: 500;
`;
const MessageButton = styled.div`
  display: flex;
  justify-content: center;
  justify-content: space-around;
  // align-items: baseline;
  margin-top: 35px;
  button {
    border: none;
    margin-bottom: 15px;
    border-radius: 7px;
    padding: 8px 65px !important;
    color: #fff;
    font-weight: 600;
    font-size: 20px;
    // justify-content: space-around;
    // align-items: baseline;
    background-color: ${(props) => props.theme.primaryColor};

    &:hover {
      background-color: ${(props) => props.theme.buttonHover};
      color:#fff;
    }

    &:active {
      background-color: ${(props) => props.theme.buttonHover};
      border-color: ${(props) => props.theme.buttonHover};
      box-shadow: ${(props) => "0 0 0 0.25rem " + props.theme.buttonActive};
    }
    &:focus {
      background-color: ${(props) => props.theme.buttonHover};
      border-color: ${(props) => props.theme.buttonHover};
      box-shadow: ${(props) => "0 0 0 0.25rem " + props.theme.buttonActive};
      color:#fff;
    }
  }
`;
const ShowMessage = styled.div`
  //   display: flex;
  margin-top: 10px;
  //   justify-content: center;
  //   justify-content: space-around;
  p {
    font-size: 13px;
    color: #7c7c7c;
    margin-bottom: 0px;
    font-weight: bolder;
    color: red;
  }
`;
