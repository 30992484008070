import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, FormGroup, Input } from "reactstrap";
import { Fragment } from "react";
import Logo from "../../assets/image/logo2.png";
import Header from "../../assets/image/header.png";
import downArrow from "../../assets/image/down-arrow.png";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { getLanguages } from "../../action/languageAction";
import { connect, useSelector } from "react-redux";
import i18next from "i18next";

const DefaultHeader = (props) => {
  const location = useLocation();

  // const languages = useSelector((state) => state.languages.languages);
  // const [lang, setLang] = useState("en");
  // useEffect(() => {
  //   props.getLanguages()
  // }, [])
  // const changeLanguage = (e) => {
  //   window.location.reload(false);
  //   // props.getLanguages(e.target.value);
  //   // i18next.changeLanguage(e.target.value);
  //   localStorage.setItem("i18nextLng", e.target.value);
  //   setLang(e.target.value);
  // };
  // let ActiveLan = languages.filter((e) => e.flag === 1);
  // const currentLanguageCode = localStorage.getItem("i18nextLng") || "ab";


  return (
    <>
      <Background className="header-shadow">
        <Container>
          <Row>
            <Col
              md="12"
              className={`${
                location.pathname == "/" ? "d-flex justify-content-center" : ""
              }`}
            >
              <Link to="/">
                <DivLog>
                  <img src={Logo} alt="Logo" height="60px" />
                </DivLog>
              </Link>
            </Col>
            {/* <Col md='8'>
              <Menulist>
                <ul> */}
            {/* <li>
                    <img src={Header} alt='Logo' />
                  </li> */}
            {/* <li>
                    <Link to='#'>CONTACT US</Link>
                  </li> */}
            {/* <li> */}
            {/* <Link > */}
            {/* ENGLISH <img src={downArrow} alt='down arrow' /> */}
            {/* <Form >
                      <FormGroup >
                        <Input
                          type="select"
                          className="lang-select"
                          bsSize="sm"
                          onChange={(e) => changeLanguage(e)}
                          value={currentLanguageCode}
                        >
                          {ActiveLan.map((language, i) => (
                            <Fragment key={i}>
                              <option value={language.language_code}>
                                {language.language_name}
                              </option>
                            </Fragment>

                          ))}
                        </Input>

                      </FormGroup>
                    </Form> */}
            {/* </Link> */}
            {/* </li>
                </ul> */}
            {/* </Menulist> */}
            {/* </Col> */}
          </Row>
        </Container>
      </Background>
    </>
  );
};

export default connect(null, {
  getLanguages,
})(DefaultHeader);
//

const Background = styled.div`
  background-color: #fff;
  box-shadow: 0px 0.1rem 0.6rem 0px rgb(0 0 0 / 6%) !important;
  position: relative;
  /*  */
  .shadow-sm {
    position: relative;
  }
  /*  */
  .container {
    background-color: #fff;
  }

  /*  */
`;
const DivLog = styled.div`
  margin: 15px 0px;
`;
const Menulist = styled.div`
  float: left;
  width: 100%;
  ul {
    float: right;

    li {
      float: left;
      a {
        float: left;
        font-weight: 500;
        padding: 42px 30px;
        color: black;
        text-decoration: none;
      }
    }
  }
  .downArrow {
    img {
      padding: 0px 0px 0px 5px;
    }
  }
`;
