import React, { Fragment } from "react";
import { Col, Card } from "reactstrap";
import styled from "styled-components";
// import PropTypes from 'prop-types'
import ReactHtmlParser from "react-html-parser";

const SingleCard = (props) => {
  const { title, mathlist, symbol } = props;
  // console.log('🚀 ~ SingleCard ~ title', title)
  return (
    <Fragment>
      <Col sm="4" lg="4" md="4" xl="4" xs="4">
        <ChildBoxedMake className="mt-2">
          <Col sm="12" lg="12" md="12" xl="12" xs="12">
            <CardHeading>
              <p dangerouslySetInnerHTML={{ __html: `${title}` }}></p>
            </CardHeading>
          </Col>
          <Col sm="12" lg="12" md="12" xl="12" xs="12">
            <ButtonGroup1>
              {mathlist.length > 0
                ? mathlist.map((value, i) => (
                    <Fragment key={i}>
                      <button>
                        {value.q + " "}{" "}
                        {symbol ? ReactHtmlParser(symbol) : null}
                      </button>
                    </Fragment>
                  ))
                : null}
            </ButtonGroup1>
            <ButtonGroup2>
              <button></button>
              <button></button>
              <button></button>
              <button></button>
              <button></button>
            </ButtonGroup2>
            <ButtonGroup3>
              <button></button>
              <button></button>
              <button></button>
              <button></button>
              <button></button>
            </ButtonGroup3>
          </Col>
        </ChildBoxedMake>
      </Col>
    </Fragment>
  );
};

// SingleCard.propTypes = {

// }

export default SingleCard;

//

const CardHeading = styled.div`
  display: flex;
  justify-content: space-between;
  p {
    font-weight: 500;
  }
`;

const ChildBoxedMake = styled(Card)`
  padding: 4px 4px;

  p {
    font-size: 9px;
    margin-bottom: 6px;
  }
`;

const ButtonGroup1 = styled.div`
  display: flex;
  flex: 0 1 20%;
  justify-content: space-between;
  flex-wrap: wrap;
  button {
    height: 28px;
    width: 28px;
    font-size: 9px;
    border: ${(props) => `1px solid ${props.theme.primaryColor}`};
    background-color: #fff;
    /* margin: 4px; */
    border-radius: 4px;
  }
`;
const ButtonGroup2 = styled.div`
  display: flex;
  flex: 0 1 20%;

  justify-content: space-between;

  button {
    height: 28px;
    width: 28px;
    font-size: 12px;
    border: ${(props) => `1px solid ${props.theme.primaryColor}`};
    background-color: #fff;
    margin: 6px 0px;
    border-radius: 4px;
    @media (max-width: 1200px) {
      margin: 5px 0px;
    }
  }
`;
const ButtonGroup3 = styled.div`
  display: flex;
  flex: 0 1 20%;
  /* align-items: center; */
  justify-content: space-between;
  /* flex-wrap: wrap; */
  button {
    height: 28px;
    width: 28px;
    font-size: 12px;
    /* padding: 4px 14px; */
    border: ${(props) => `1px solid ${props.theme.primaryColor}`};
    background-color: #fff;
    /* margin: 5px; */
    border-radius: 4px;
  }
`;
