import React from "react";
import { Route, Switch } from "react-router-dom";
// import PropTypes from 'prop-types'
import DefaultHeader from "../DefaultHeader/DefaultHeader";
import router from "../../api/router";
import styled from "styled-components";
import DefaultFooter from "../DefaultFooter/DefaultFooter";

const TheLayout = (props) => {
  return (
    <React.Fragment>
      <DefaultHeader />
      <BackGroundColor>
        <Switch>
          {router.map((route, idx) => {
            return (
              route.component && (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  render={(props) => <route.component {...props} />}
                />
              )
            );
          })}
        </Switch>
      </BackGroundColor>
      <DefaultFooter />
    </React.Fragment>
  );
};

export default TheLayout;

const BackGroundColor = styled.div`
  background-color: #fbfbfb;
  padding: 20px 0 0 0;
  height: auto;
`;
