import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row, Table } from "reactstrap";
import styled from "styled-components";

const Aboutus = () => {
  const nameArray1 = [
    "Africaans",
    "Albanian shqiptare",
    "Amharic አማርኛ",
    "Armenian հայերեն ",
    "Aymara",
    "Azerbaijani Azərbaycan ",
    "Bambara Bamanankan ",
    "Basque euskara ",
    "Belarusian беларускі ",
    "Bosnian bosanski ",
    "Bulgarian български ",
    "Catalan català ",
    "Cebuano",
    "Chichewa",
    "Chinese (simplified) 简体中文）",
    "Chinese (traditional) 中國傳統的）",
    "Corsican Corsu",
    "Croatian Hrvatski",
    "Czech čeština",
    "Danish dansk",
    "Dutch Nederlands",
    "English ",
    "Esperanto",
    "Estonian eesti keel",
    "Ewe Eʋegbe",
  ];
  const nameArray2 = [
    "Filipino",
    "Finnish Suomalainen",
    "French Français",
    "Frisian Frysk",
    "Galician galego",
    "Georgian ქართული",
    "German Deutsch",
    "Greek Ελληνικά",
    "Guarani",
    "Haitian Creole kreyol ayisyen",
    "Hausa",
    "Hawaiian ʻŌlelo Hawaiʻi",
    "Hmong Hmoob",
    "Hungarian Magyar",
    "Icelandic íslenskur",
    "Igbo ",
    "Ilocano",
    "Indonesian bahasa Indonesia",
    "Irish Gaeilge",
    "Italian Italiana",
    "Japanese 日本",
    "Javanese basa jawa",
    "Kazakh қазақ",
    "Kinyarwanda",
    "Korean 한국인",
  ];
  const nameArray3 = [
    "Krio",
    "Kurdish (kurmanji) Kurdî (kurmancî)",
    "Kyrgyz Кыргызча",
    "Latin Latinus",
    "Latvian latviski",
    "Lingala",
    "Lithuanian lietuvių",
    "Luganda Oluganda",
    "Luxembourgish lëtzebuergesch",
    "Macedonian македонски",
    "Malagasy",
    "Malay Melayu",
    "Maltese Malti",
    "Māori ",
    "Mizo tawng",
    "Mongolian Монгол",
    "Norwegian norsk",
    "Oromo Afaan Oromoo",
    "Polish Polski",
    "Portuguese Português",
    "Quechua Runasimi",
    "Romanian Română",
    "Russian Русский",
    "Samoan",
    "Scots Gaelic Gàidhlig na h-Alba",
  ];
  const nameArray4 = [
    "Sepedi",
    "Serbian Српски",
    "Sesotho",
    "Shona",
    "Slovak slovenský",
    "Slovenian Slovenščina",
    "Somali Soomaali",
    "Spanish Española ",
    "Sundanese basa Sunda",
    "Swahili kiswahili",
    "Swedish svenska",
    "Tajik тоҷикӣ",
    "Tatar Татар",
    "Tigrinya ትግሪኛ",
    "Tsonga ",
    "Turkish Türk",
    "Turkmen Türkmenler",
    "Twi",
    "Ukrainian українська",
    "Uzbek o'zbek",
    "Vietnamese Tiếng Việt",
    "Welsh Cymraeg",
    "Xhosa isiXhosa",
    "Yoruba",
    "Zulu",
  ];
  return (
    <Container>
      <Row>
        <Col lg="12" md="12" sm="12" xl="12" className="mb-3">
          <Col xl="12" sm="12" lg="12" md="12">
            <Title>About Elumat</Title>
            <Decription>
              Elumat was created by Peter Garside, a high school maths teacher
              who lives in Sydney.
              <br />
              <br />
              Elumat is a teacher-resource designed to motivate students to
              practise mathematics, as well as their reading and writing skills.
              <br />
              <br />
              Children enjoy solving puzzles. Elumat provides a simple, yet
              creative way for teachers to combine maths and language into a
              self-checking and unique, A4 student puzzle. Examples can be
              found, linked on the main page. They illustrate how maths can be
              catered to a wide variety of student ability. Print sets of PDFs
              for classes, school events, excursions or homework tasks.
              <br />
              <br />
              <Heading>The Maths:</Heading> Encourage your students to use
              mental-arithmetic or pencil-and-paper methods to complete the
              mathematics.
            </Decription>
            <UnOrderList>
              <ListItem>
                <Heading>Early Primary maths</Heading>
                <br />
                These maths tasks cover elementary basic operations as well as
                some variations on addition and subtraction skills. The PDF for
                Early Primary is designed so students have more room to practise
                their writing skills.
              </ListItem>{" "}
              <ListItem>
                <Heading>Primary whole number maths</Heading>
                <br />
                These tasks cover a range of one- or two-operations and all
                answers are positive integers, or zero. The tasks can be
                presented in three different ways:
                <UnOrderList>
                  <ListSubItem>
                    <i>Described in language</i>, for example: add 86
                  </ListSubItem>
                  <ListSubItem>
                    in <i>Roman numerals</i>, for example: add LXXXVI
                  </ListSubItem>
                  <ListSubItem>
                    <i>abstract</i>, for example: ⏎ means + 86
                  </ListSubItem>
                </UnOrderList>
              </ListItem>
              <ListItem>
                <Heading>Primary rational number maths</Heading>
                <br />
                These tasks cover the conversions of, as well as a range of
                operations on fractions, decimals and percentages.
              </ListItem>
            </UnOrderList>
            <Decription>
              <Heading>The Messages:</Heading> can be written to the class or to
              the individual. They can be for key learning areas, a focus on
              literacy or for fun and well-being. They just need to fit, and it
              is best if they are first written in a spreadsheet or text
              document, along with student names. This Primary message gives an
              example of what fits: <br />
              <i>
                Education shall promote understanding, tolerance and friendship
                among all nations, racial or religious groups.{" "}
              </i>{" "}
              <br />
              <br />
              The Early primary message length is shorter. More room has been
              given for younger students to practise their writing skill.
              <br />
              This message gives an idea of what fits: <br />
              <i> The average text message length is about 7 words</i> <br />
              <br />
              Messages can also be written in a variety of languages. The
              100-message PDF example, linked on the home page, contains the
              message:
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <i>
                {" "}
                Education shall promote understanding, tolerance and friendship
                among all nations, racial or religious groups.
              </i>
              <br /> translated in the following languages:
            </Decription>
          </Col>
        </Col>
      </Row>
      <Row>
        {" "}
        <Col xl="3" sm="3" lg="3" md="3">
          <Table borderless>
            <thead>
              <tr>
                <th>Page</th>
                <th>Language</th>
              </tr>
            </thead>
            <tbody>
              {nameArray1.map((list, i) => (
                <tr>
                  <th scope="row" key={i}>
                    {i + 1}
                  </th>
                  <td>{list}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>{" "}
        <Col xl="3" sm="3" lg="3" md="3">
          <Table borderless>
            <thead>
              <tr>
                <th>Page</th>
                <th>Language</th>
              </tr>
            </thead>
            <tbody>
              {nameArray2.map((list, i) => (
                <tr>
                  <th scope="row" key={i}>
                    {i + 26}
                  </th>
                  <td>{list}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
        <Col xl="3" sm="3" lg="3" md="3">
          <Table borderless>
            <thead>
              <tr>
                <th>Page</th>
                <th>Language</th>
              </tr>
            </thead>
            <tbody>
              {nameArray3.map((list, i) => (
                <tr>
                  <th scope="row" key={i}>
                    {i + 51}
                  </th>
                  <td>{list}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
        <Col xl="3" sm="3" lg="3" md="3">
          <Table borderless>
            <thead>
              <tr>
                <th>Page</th>
                <th>Language</th>
              </tr>
            </thead>
            <tbody>
              {nameArray4.map((list, i) => (
                <tr>
                  <th scope="row" key={i}>
                    {i + 76}
                  </th>
                  <td>{list}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default Aboutus;
const Title = styled.p`
 font-size: 18px;
  font-weight: 700;
  margin: 0;
  color: #000;
`;

const Heading = styled.span`
  font-size: 16px;
  font-weight: 800;
  margin: 0;
  color: #000;
`;

const Decription = styled.p`
  font-size: 16px;
  color: #000;
`;

const UnOrderList = styled.ul`
  a {
    text-decoration: none;
    color: black;
  }
`;

const ListItem = styled.li`
  margin: ${(props) => (props.smallSize ? "5px 0" : " 10px 0")};
  list-style-type: disc p {
    font-size: 13px;
    color: #7c7c7c;
    margin-bottom: 0px;
  }
  .mr-25 {
    margin-right: 25px;
  }
  .mr-30 {
    margin-right: 33px;
  }
  .mr-32 {
    margin-right: 40px;
  }
`;
const ListSubItem = styled.li`
  margin: ${(props) => (props.smallSize ? "5px 0" : " 10px 0")};
  list-style-type: circle p {
    font-size: 13px;
    color: #7c7c7c;
    margin-bottom: 0px;
  }
  .mr-25 {
    margin-right: 25px;
  }
  .mr-30 {
    margin-right: 33px;
  }
  .mr-32 {
    margin-right: 40px;
  }
`;
