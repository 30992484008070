import React, { Fragment } from 'react'
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
} from 'reactstrap'
import styled from 'styled-components'
import ToastMessage from '../../DefaultComponets/ToastMessage/ToastMessage'
// import PropTypes from 'prop-types'
// images
import Right_arrow from '../../assets/image/arrow-right.png'
import { Link } from 'react-router-dom'
import {
  QuantityData,
  SecondaryIntegersAndRationals,
} from '../../api/data/Secondary'

const SecondaryIntegers = (props) => {
  const onClickBTN = (e) => {}
  return (
    <Fragment>
      <Container>
        <Row className='mt-4'>
          <ToastMessage />
          <Col xl='4' sm='4' md='4' lg='4'>
            <Row>
              <Col xl='12' sm='12' md='12' lg='12'>
                <Heading>Secondary</Heading>
              </Col>
              <Col xl='12' sm='12' md='12' lg='12' className='mb-5'>
                <SubHeading>Integers and rationals</SubHeading>
              </Col>
            </Row>
          </Col>
          <Col xl='4' sm='4' md='4' lg='4'>
            <BoxCard>
              <Link to='/secondary/rationals-ratio-surds'>
                <BoxBody>
                  <BoxInLine>
                    <BoxHeading>Secondary</BoxHeading>
                    <BoxPara>
                      Further rationals and ratio, surds and logarithms
                    </BoxPara>
                    <BoxImage>
                      <img src={Right_arrow} alt='right-arrow' />
                    </BoxImage>
                  </BoxInLine>
                </BoxBody>
              </Link>
            </BoxCard>
          </Col>
          <Col xl='4' sm='4' md='4' lg='4'>
            <BoxCard>
              <Link to='/secondary/algebra-trigonometry'>
                <BoxBody boxpadding={1}>
                  <BoxInLine>
                    <BoxHeading>Secondary</BoxHeading>
                    <BoxPara boxpadding={1}>Algebra and trigonometry</BoxPara>
                    <BoxImage>
                      <img src={Right_arrow} alt='right-arrow' />
                    </BoxImage>
                  </BoxInLine>
                </BoxBody>
              </Link>
            </BoxCard>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row>
          <Col xl='6' sm='12' md='6' lg='6' xs='12'>
            <Card>
              <FullBoxHeading>Operations with directed numbers</FullBoxHeading>
              <FullBoxBody>
                <BTNGroup>
                  <button>Add</button>
                  <button>Subtract</button>
                  <button>Multiply</button>
                  <button>Divide</button>
                </BTNGroup>
                <DetectedBoxContent>
                  <p>Directed number mixed operations</p>
                </DetectedBoxContent>
                <Table className='mt-3 mb-0' borderless>
                  <tbody>
                    {SecondaryIntegersAndRationals.map((data, i) => (
                      <TableTR key={i}>
                        <TableTD>
                          <button>{data.value}</button>
                        </TableTD>
                        <TableTD1>
                          <button>Substitute</button>
                        </TableTD1>
                        <TableTD2>
                          <button>Abstract</button>
                        </TableTD2>
                      </TableTR>
                    ))}
                  </tbody>
                </Table>
              </FullBoxBody>
            </Card>
          </Col>
          <Col xl='6' sm='12' md='6' lg='6' xs='12'>
            <Card>
              <FullBoxHeading>
                Fractions, Decimals And Percentages
              </FullBoxHeading>
              <FullBoxBody>
                <FullBoxCard>
                  <p>Add</p>
                  <button>Unit Fraction</button>
                  <button>Proper Fractions</button>
                  <button>Decimal Fractions</button>
                </FullBoxCard>
                <FullBoxCard className='mt-3'>
                  <p>Subtract</p>
                  <button>Unit Fraction</button>
                  <button>Proper Fractions</button>
                  <button>Decimal Fractions</button>
                </FullBoxCard>
                <FullBoxCard className='mt-3' unbutton>
                  <p>Multiply</p>
                  <button>Common Fractions</button>
                  <button>Decimal Fractions</button>
                </FullBoxCard>
                <FullBoxCard className='mt-3' unbutton>
                  <p>Divide</p>
                  <button>Common Fractions</button>
                  <button>Decimal Fractions</button>
                </FullBoxCard>
                <FullBoxCard className='mt-3' unbutton>
                  <p>Square</p>
                  <button>Common Fractions</button>
                  <button>Decimal Fractions</button>
                </FullBoxCard>
                <FullBoxCard className='mt-3' unbutton>
                  <p>Square root</p>
                  <button>Common Fractions</button>
                  <button>Decimal Fractions</button>
                </FullBoxCard>
                {/* Quantity */}
                <QuantityBox className='mt-4'>
                  <FullBoxSubHeading>Of a Quantity, Find</FullBoxSubHeading>
                  <Table borderless className='mb-0'>
                    <TBody>
                      {QuantityData.map((data, i) => (
                        <tr key={i}>
                          <td>
                            <button
                              dangerouslySetInnerHTML={{
                                __html: data.value,
                              }}></button>
                          </td>
                          <td>
                            <button>{data.Ops1}</button>
                          </td>
                          <td>
                            <button
                              dangerouslySetInnerHTML={{
                                __html: data.Ops2,
                              }}></button>
                          </td>
                          <td>
                            <button
                              dangerouslySetInnerHTML={{
                                __html: data.Ops3,
                              }}
                              onClick={(e) => onClickBTN(e)}></button>
                          </td>
                        </tr>
                      ))}
                    </TBody>
                  </Table>
                </QuantityBox>
                {/* Conversion */}
                <QuantityBox className='mt-4'>
                  <FullBoxSubHeading>Conversion</FullBoxSubHeading>
                  <ConversionBTNGroup>
                    <Row>
                      <Col md='6' sm='6' xl='6' xs='6'>
                        <button>Fraction to Decimal</button>
                      </Col>
                      <Col md='6' sm='6' xl='6' xs='6'>
                        <button> Decimal to Fraction</button>
                      </Col>
                      <Col md='6' sm='6' xl='6' xs='6'>
                        <button>Fraction to Percentage</button>
                      </Col>
                      <Col md='6' sm='6' xl='6' xs='6'>
                        <button>Percentage to Fraction</button>
                      </Col>
                      <Col md='6' sm='6' xl='6' xs='6'>
                        <button>Decimal to Percentage</button>
                      </Col>
                      <Col md='6' sm='6' xl='6' xs='6'>
                        <button>Percentage to Decimal</button>
                      </Col>
                    </Row>
                  </ConversionBTNGroup>
                </QuantityBox>
              </FullBoxBody>
            </Card>
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col xl='3' sm='6' md='3' lg='3' xs='6' className='mt-3'>
            <Link to='#!'>
              <SubmitBTN>Enter Names & Messages </SubmitBTN>
            </Link>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}

// SecondaryIntegers.propTypes = {

// }

export default SecondaryIntegers

const Heading = styled.h1`
  font-size: 24px;
`
const BoxCard = styled(Card)`
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  a {
    text-decoration: none;
  }
`
const SubHeading = styled.h5`
  font-size: 19px;
  color: #848484;
`
const BoxBody = styled(CardBody)`
  background-color: #fbfbfb;
  padding: ${(props) => (props.boxpadding ? '1rem 1rem' : ' 9px 1rem')};
  @media (max-width: 1200px) {
    padding: ${(props) => (props.boxpadding ? '1.2rem 1rem' : ' 9px 1rem')};
  }
`
const BoxInLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const BoxHeading = styled.h3`
  font-size: 18px;
  margin-bottom: 0px;
  font-family: 'Saira';
  flex: 1 1 auto;
  color: black;
`
const BoxPara = styled.p`
  font-size: 13px;
  font-weight: 400;
  color: #7c7c7c;
  font-family: 'Roboto';
  margin-bottom: 0px;
  text-decoration: none;
  flex: ${(props) => (props.boxpadding ? '1 1 35%' : '1 1 49%')};
  @media (max-width: 1200px) {
    flex: ${(props) => (props.boxpadding ? '1 1 30%' : '1 1 49%')};
  }
`

const BoxImage = styled.div``

const FullBoxHeading = styled(CardHeader)`
  background-color: #fbfbfb;
  text-align: center;
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 500;
`
const FullBoxBody = styled(CardBody)`
  background-color: #fbfbfb;
`

const BTNGroup = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  button {
    background-color: ${(props) => props.theme.primaryColor};
    border: none;
    color: ${(props) => props.theme.buttonText};
    font-family: ${(props) => props.theme.SeraiFont};
    font-size: 12px;
    border-radius: 27px;
    width: 134px;
    height: 29px;
    @media (max-width: 1200px) {
      width: 101px;
    }
  }
`
const DetectedBoxContent = styled.div`
  margin-top: 20px;
  border: 1px solid #e1e1e1;
  p {
    margin-bottom: 0px;
    text-align: center;
    font-size: 14px;
    font-family: ${(props) => props.theme.SeraiFont};
    color: black;
    font-weight: 500;
    padding: 2px 0px;
  }
`

const TableTR = styled.tr`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`
const TableTD = styled.td`
  button {
    width: 281px;
    border: none;
    text-align: start;
    background: #fff;
    box-shadow: 0px 4px 9px rgb(0 0 0 / 5%);
    border-radius: 5px;
    font-size: 13px;
    padding: 5px 10px;
    font-family: ${(props) => props.theme.RobotoFont};
    font-weight: 500;
    @media (max-width: 1200px) {
      width: 175px;
    }
  }
`

const TableTD1 = styled.td`
  button {
    width: 115px;
    height: 29px;
    border: none;
    border-radius: 27px;
    color: ${(props) => props.theme.buttonText};
    background: ${(props) => props.theme.primaryColor};
    font-family: ${(props) => props.theme.SeraiFont};
    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.05);
    margin-left: 30px;
    font-size: 12px;
    @media (max-width: 1200px) {
      margin-left: 0px;
    }
  }
`
const TableTD2 = styled.td`
  button {
    width: 115px;
    height: 29px;
    border: none;
    border-radius: 27px;
    color: ${(props) => props.theme.buttonText};
    background: ${(props) => props.theme.primaryColor};
    font-family: ${(props) => props.theme.SeraiFont};
    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.05);
    font-size: 12px;
  }
`
const FullBoxCard = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  padding: 0.8rem 1.5rem;
  p {
    margin-bottom: 0rem;
    font-size: 18px;
    font-family: ${(props) => props.theme.SeraiFont};
    margin-right: 50px;
    @media (max-width: 1200px) {
      margin-right: 30px;
    }
  }

  button {
    background: #00b050;
    width: ${(props) => (props.unbutton ? '185px' : '133px')};
    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.05);
    border-radius: 27px;
    border: none;
    font-family: ${(props) => props.theme.SeraiFont};
    font-size: 12px;
    color: ${(props) => props.theme.buttonText};
    @media (max-width: 1200px) {
      width: ${(props) => (props.unbutton ? '140px' : '97px')};
    }
  }
`
const QuantityBox = styled.div`
  padding: 0.8rem 1.5rem;
  border-radius: 5px;
  border: 1px solid #e1e1e1;
`
const FullBoxSubHeading = styled.h4`
  margin-bottom: 0px;
  text-align: center;
  font-size: 18px;
  font-family: ${(props) => props.theme.SeraiFont};
  font-weight: 400;
`

const TBody = styled.tbody`
  tr {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  td {
    &:first-child {
      width: 35%;
      margin-right: 20px;
      @media (max-width: 1200px) {
        width: 30%;
        margin-right: 4px;
      }
      button {
        width: 100%;
        background-color: ${(props) => props.theme.buttonText};
        text-align: left;
        font-size: 13px;
        font-family: ${(props) => props.theme.RobotoFont};
        font-weight: 500;
        padding-left: 10px;
        border: none;
        box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.05);
        border-radius: 5px;
        color: black;
      }
    }
  }
  td {
    button {
      width: 95.58px;
      background-color: ${(props) => props.theme.primaryColor};
      color: ${(props) => props.theme.buttonText};
      border: none;
      border-radius: 27px;
      font-size: 12px;
      box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.05);
      font-family: ${(props) => props.theme.SeraiFont};
      padding: 4px 0px;
      @media (max-width: 1200px) {
        width: 71px;
      }
    }
  }
`
const ConversionBTNGroup = styled.div`
  button {
    width: 100%;
    margin: 5px 0px;
    background-color: ${(props) => props.theme.primaryColor};
    color: ${(props) => props.theme.buttonText};
    border: none;
    border-radius: 27px;
    font-size: 12px;
    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.05);
    font-family: ${(props) => props.theme.SeraiFont};
    padding: 4px 0px;
  }
`
const SubmitBTN = styled.button`
  border: none;
  background-color: ${(props) => props.theme.primaryColor};
  border-radius: 7px;
  color: white;
  margin-left: 20px;
  padding: 12px 35px;
  &:active {
  }
  @media (max-width: 1200px) {
    padding: 12px 14px;
  }
`
