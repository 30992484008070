import axios from "axios";
const PROXY = process.env.REACT_APP_URL + "api/";
const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

export const HomeCount = (type) => async (dispatch) => {
    const res = await axios.get(`${PROXY}graph/primary-count/${type}`);
    dispatch({
      type: "GET_COUNT",
      payload: res.data.result,
    });
  };
  