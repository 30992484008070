import React from "react";

import { Link } from "react-router-dom";
import { Col, Row, Card, CardBody, Container } from "reactstrap";
import styled from "styled-components";
import RightArrow from "../../assets/image/arrow-right.png";
// import PropTypes from 'prop-types'
import { connect, useSelector } from "react-redux";
import ElumatDemoPdf from "../Home/ElumatDemo.pdf";
import ElumatDemoImage from "../Home/frontImage.png";
import { HomeCount } from "../../action/homeCount";

const Home = (props) => {
  // const labelsName = useSelector((state) => state.label.labelsName);
  // // console.log("🚀labelsName", labelsName)

  const CountIncrease = (e, type) => {
    props.HomeCount(type);
  };

  return (
    <Container>
      <Row>
        <Col lg="12" md="12" sm="12" xl="12" className="mb-3">
          <Col xl="12" sm="12" lg="12" md="12">
            <Para>
              A fun way for students to practise their maths and language
              skills:
            </Para>
          </Col>
        </Col>
      </Row>
      <CenterRow>
        <Col xl="7" sm="7" lg="7" md="7">
          <img src={ElumatDemoImage} width="100%" height="370px" />
        </Col>
      </CenterRow>

      <CenterRow className="mt-3">
        <Col xl="10" sm="10" lg="10" md="10">
          <Boxed className="card-shadow">
            <BoxBody>
              <ParaCard>
                <strong>Step1: choose a level of maths</strong>
              </ParaCard>
              <UnOrderList>
                <Link to="/early-primary" onClick={(e) => CountIncrease(e, 1)}>
                  <ListItem>
                    Early Primary
                    {/* <img src={RightArrow} alt="Right arrow" /> */}
                  </ListItem>
                </Link>
                <Link
                  to="/primary-maths/whole-number"
                  onClick={(e) => CountIncrease(e, 2)}
                >
                  <ListItem>
                    Primary Whole Number
                    {/* <img src={RightArrow} alt="Right arrow" /> */}
                  </ListItem>
                </Link>
                <Link
                  to="/primary-maths/rational-number"
                  onClick={(e) => CountIncrease(e, 3)}
                >
                  <BigListItem>
                    Primary Rational Number
                    {/* <img src={RightArrow} alt="Right arrow" /> */}
                  </BigListItem>
                </Link>
              </UnOrderList>
            </BoxBody>
          </Boxed>
        </Col>
      </CenterRow>
    </Container>
  );
};

export default connect(null, {
  HomeCount,
})(Home);

const Para = styled.p`
  display: flex;
  justify-content: center;
  margin: 0px;
  font-size: 20px;
  font-weight: 500;
  color: #00b050;
`;

const ParaCard = styled.p`
  display: flex;
  justify-content: center;
  font-size: 18px;
`;

const Boxed = styled(Card)`
  border-radius: 0.5rem;
  border: none;
`;

const BoxBody = styled(CardBody)`
  /* padding: 2rem; */
`;

const UnOrderList = styled.ul`
  display: flex;
  /* justify-content: space-evenly; */
  justify-content: space-around;
  padding-left: 0px;
  a {
    text-decoration: none;
    color: black;
  }
`;
const CenterRow = styled(Row)`
  display: flex;
  justify-content: center;
`;

const ListItem = styled.li`
  display: flex;
  justify-content: center;
  border: 3px solid #00b050;
  /* border: 3px solid #e1e1e1; */
  border-radius: 5px;
  width: 200px;
  margin: ${(props) => (props.smallSize ? "5px 0" : " 10px 0")};
  /* margin: 10px 0; */
  padding: 10px;
  cursor: pointer;
  align-items: center;

  /*  */
  a {
    text-decoration: none;
    color: black;
  }
  /*  */
  img {
    height: 100%;
  }

  p {
    font-size: 13px;
    color: #7c7c7c;
    margin-bottom: 0px;
  }
  .mr-25 {
    margin-right: 25px;
  }
  .mr-30 {
    margin-right: 33px;
  }
  .mr-32 {
    margin-right: 40px;
  }
`;

const BigListItem = styled.li`
  display: flex;
  justify-content: center;
  border: 3px solid #00b050;
  /* border: 3px solid #e1e1e1; */
  border-radius: 5px;
  width: 220px;
  margin: ${(props) => (props.smallSize ? "5px 0" : " 10px 0")};
  /* margin: 10px 0; */
  padding: 10px;
  cursor: pointer;
  align-items: center;

  /*  */
  a {
    text-decoration: none;
    color: black;
  }
  /*  */
  img {
    height: 100%;
  }

  p {
    font-size: 13px;
    color: #7c7c7c;
    margin-bottom: 0px;
  }
  .mr-25 {
    margin-right: 25px;
  }
  .mr-30 {
    margin-right: 33px;
  }
  .mr-32 {
    margin-right: 40px;
  }
`;
