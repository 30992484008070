
const initialState = {
    labels: [],
    labelsName: [],
    labelsLoading: true,
};

const labelReducer = (state = initialState, action) => {
    const { type, payload } = action

    switch (type) {
        case "GET_LABELS":
            return { ...state, labels: payload, labelsLoading: false };
        case "GET_LABELS_Name":
            return { ...state, labelsName: payload };
        default:
            return state
    }
}
export default labelReducer

