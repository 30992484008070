// Secondary Integers And Rationals
export const SecondaryIntegersAndRationals = [
  {
    value: 'Add Then Multiply',
    operation1: 'Substitute',
    operation2: 'Abstract',
  },
  {
    value: 'Multiply Then Add',
    operation1: 'Substitute',
    operation2: 'Abstract',
  },
  {
    value: 'Subtract Then Multiply',
    operation1: 'Substitute',
    operation2: 'Abstract',
  },
  {
    value: 'Multiply Then Subtract',
    operation1: 'Substitute',
    operation2: 'Abstract',
  },
  {
    value: 'Add Then Divide',
    operation1: 'Substitute',
    operation2: 'Abstract',
  },
  {
    value: 'Divide Then Add',
    operation1: 'Substitute',
    operation2: 'Abstract',
  },
  {
    value: 'Subtract Then Divide',
    operation1: 'Substitute',
    operation2: 'Abstract',
  },
  {
    value: 'Divide The Subtract',
    operation1: 'Substitute',
    operation2: 'Abstract',
  },
  {
    value: 'Divide Then Multiply',
    operation1: 'Substitute',
    operation2: 'Abstract',
  },
  {
    value: 'Square Then Add',
    operation1: 'Substitute',
    operation2: 'Abstract',
  },
  {
    value: 'Square Then Subtract',
    operation1: 'Substitute',
    operation2: 'Abstract',
  },
  {
    value: 'Divide Then Square',
    operation1: 'Substitute',
    operation2: 'Abstract',
  },
  {
    value: 'Square Root Then Add',
    operation1: 'Substitute',
    operation2: 'Abstract',
  },
  {
    value: 'Add Then Square Root',
    operation1: 'Substitute',
    operation2: 'Abstract',
  },
  {
    value: 'Square Root Then Subtract',
    operation1: 'Substitute',
    operation2: 'Abstract',
  },
  {
    value: 'Subtract Then Square Root',
    operation1: 'Substitute',
    operation2: 'Abstract',
  },
  {
    value: 'Square Root Then Multiply',
    operation1: 'Substitute',
    operation2: 'Abstract',
  },
]

export const QuantityData = [
  {
    value: 'A half of',
    Ops1: '1/2 of',
    Ops2: '0.5 of',
    Ops3: '50% of',
  },
  {
    value: 'A quarter of',
    Ops1: '1/4 of',
    Ops2: '0.25 of',
    Ops3: '25% of',
  },
  {
    value: 'three quarters of',
    Ops1: '3/4 of',
    Ops2: '0.75 of',
    Ops3: '75% of',
  },
  {
    value: 'A tenth of',
    Ops1: '1/10 of',
    Ops2: '0.1 of',
    Ops3: '10% of',
  },
  {
    value: 'A fifth of',
    Ops1: '1/5 of',
    Ops2: '0.2 of',
    Ops3: '20% of',
  },
  {
    value: 'A third of',
    Ops1: '1/3 of',
    Ops2: '0.3 of',
    Ops3: '33 1/3% of',
  },
  {
    value: '(For 2 < x < 10)',
    Ops1: 'x/10 of',
    Ops2: 'x <b>X</b> 0.1 of',
    Ops3: 'x <b>X</b> 10% of',
  },
  {
    value: '(For odd x, <br> where 1 <= x < 20)',
    Ops1: 'x/20 of',
    Ops2: 'x <b>X</b> 0.05 of',
    Ops3: 'x <b>X</b> 5% of',
  },
]

// Secondary Further rational and ratio , surds and logarithms
export const rational_data = [
  {
    title: 'Increase by',
    ops1: '1/10 of original',
    ops2: '0.1 of original',
    ops3: '10% of original',
  },
  {
    title: 'Decrease by',
    ops1: '1/10 of original',
    ops2: '0.1 of original',
    ops3: '10% of original',
  },
]

export const rational_data_2 = [
  {
    title: 'Increase by',
    ops1: 'x/10 of original',
    ops2: 'x <b>X</b> 0.1 of original',
    ops3: 'x <b>X</b> 10% of original',
  },
  {
    title: 'Decrease by',
    ops1: 'x/10 of original',
    ops2: 'x <b>X</b> 0.1 of original',
    ops3: 'x <b>X</b> 10% of original',
  },
]

export const rational_data_3 = [
  {
    title: 'Increase by',
    ops1: 'x/20 of original',
    ops2: 'x <b>X</b> 5% of original',
  },
  {
    title: 'Decrease by',
    ops1: 'x/20 of original',
    ops2: 'x <b>X</b> 5% of original',
  },
]

export const Ratio = [
  {
    title: 'Simplify the given ratios',
    ops1: 'X:Y',
    ops2: 'X:Y:Z',
    ops3: 'Mix of Both',
  },
  {
    title: 'Share a quantity q in',
    ops1: 'X : Y Ratio',
    ops2: 'X : Y : Z Ratio',
    ops3: 'Mix of Both',
  },
]

//  Logarithms
export const logarithms = [
  {
    value: 'Base 10',
  },
  {
    value: 'Base 2',
  },
  {
    value: 'Mixed Base',
  },
  {
    value: 'Write equation in index form',
  },
  {
    value: 'Simplify log expression',
  },
]

export const ButtonData = [
  '5 or 20%',
  '25 or 75%',
  '30, 40 or 60%',
  '70,80 or 90%',
  '12 1/2 or 37 1/2 %',
  '33 1/3 or 66 2/3 %',
  '62 1/2 or 87 1/2 %',
]
/*
┌──────────────────────────────────────────────────────────────────────────────┐
│                            Algebra and trigonometry                          │
└──────────────────────────────────────────────────────────────────────────────┘
*/
export const Summing = [
  {
    title: 'Add',
    ops1: '1 Item',
    ops2: '2 Items',
  },
  {
    title: 'Subtract',
    ops1: '1 Item',
    ops2: '2 Items',
  },
]

export const Multiply_Data = ['A Constant', 'An Algebraic term', 'A Binomial']

export const expand = ['Constant by binomial', 'Algebraic term by binomial']

export const trigRation = [
  {
    value: 'Sine',
  },
  {
    value: 'Cosine',
  },
  {
    value: 'Tangent',
  },
  {
    value: 'Random Mix',
  },
]
