import React from "react";
import { Col, Container, Row, Table } from "reactstrap";
import styled from "styled-components";
import HomeButton from "./Image/1.png";
import EarlyPrimary from "./Image/2.png";
import WholePrimary from "./Image/3.png";
import Rational from "./Image/4.png";
import EarlyPdf from "./Image/5.png";
import WholePdf from "./Image/6.png";
import EarlyPdfDemo from "./Image/7.png";
import EarlyPdfDemo2 from "./Image/8.png";
import WholePdfDemo from "./Image/9.png";
import WholePdfDemo2 from "./Image/10.png";
import WholePdfDouble from "./Image/11.png";
import EarlyPdfDouble from "./Image/12.png";
const SomeInstruction = () => {
  return (
    <Container fluid>
      <Row>
        <Col xl="12" sm="12" lg="12" md="12">
          <Title>Elumat Instructions </Title>
          <SubTitle>
            The use of the term PDF indicates the final printable worksheet or
            set of worksheets. These are designed to be printed single-sided for
            students to complete.
            <br />
            The PDFs for both Early Primary and Primary Whole Number are
            automatically generated with a landscape layout and minimised
            margins.
            <br />
            The PDFs for Primary Rational Number are not automatically
            formatted. Print settings will appear when these PDFs are generated
            – for the user to ensure a landscape layout and minimised margins.
          </SubTitle>

          <Table bordered style={{ border: "2px solid black" }}>
            <tbody>
              <tr>
                <td colspan="3">
                  <b>The three steps to generate PDFs:</b>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <b>Step 1: choose the level of maths</b>
                </td>
                <td>
                  <img src={HomeButton} />
                </td>
              </tr>
              <tr>
                <td rowspan="3">
                  <Decription>
                    {" "}
                    <b>Step2: choose the maths tasks</b> <br />
                    When you select a task, an example appears at the bottom of
                    the screen. You can delete chosen tasks and change them for
                    others.
                  </Decription>
                </td>
                <td>
                  For:
                  <br /> <b>Early Primary</b> choose 3 tasks
                </td>
                <td>
                  {" "}
                  <img src={EarlyPrimary} height="649px" />
                </td>
              </tr>
              <tr>
                <td rowspan="2">
                  For: <br />
                  <b>Primary whole number</b>
                  <br />
                  <br />
                  or
                  <br />
                  <br />
                  <b>Primary rational number</b> <br />
                  choose 6 tasks.
                </td>
                <td>
                  {" "}
                  <img src={WholePrimary} height="530px" />
                </td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <img src={Rational} height="530px" />
                </td>
              </tr>
              <tr>
                <td colspan="3">
                  <b>Step 3: Entering student names and messages </b>
                  <br />
                  It is best to have these already typed out in a spreadsheet or
                  text document. <br />
                  Note:
                  <br />
                  <UnOrderList>
                    <ListItem>
                      The PDF allows each name to be as much as 75 characters
                      long (including spaces). This allows for extra
                      instructions to be written with the student name – as the
                      example below illustrates. If a name is too long, a second
                      line of text may cause a worksheet to go onto a second
                      page.
                    </ListItem>{" "}
                    <ListItem>
                      Each message must fit within the message parameters
                      described below. Messages can be written in a variety of
                      languages.
                    </ListItem>
                  </UnOrderList>
                  <br />
                  The spreadsheet option: type names in column A and messages in
                  column B. <br />
                  Copy all the names from column A and paste them all in one
                  move into the Step 3 field called Enter student names. Copy
                  all the messages from column B and paste them all in one move
                  into the Step 3 field called Enter message.
                  <br />
                  The text document option: type names in a column or separated
                  by commas. Type messages in a separate column. Then copy and
                  paste the names and then the messages in the same manner as
                  the spreadsheet option.
                  <br />
                  <br />
                  Examples for Early Primary and Primary Whole Number are shown
                  below.
                  <br />
                  The Early Primary example shows:
                  <br />
                  <UnOrderList>
                    <ListItem>
                      Names (the first column) and messages are automatically
                      numbered to help keep track of the student and their
                      intended message.
                    </ListItem>{" "}
                    <ListItem>
                      Message #1 appears in black which means it fits within the
                      three lines of the message. However, message #2 appears in
                      red, meaning it is too long and must be edited.
                    </ListItem>{" "}
                    <ListItem>
                      Clicking on either name #2 or message #2 allows message #2
                      to appear in the three lines of the message. This feature
                      will help with the editing.
                    </ListItem>
                  </UnOrderList>
                  <br />
                  The text can be edited by clicking on the pencil icon next to
                  the message or deleted. Clicking on Discard Spaces may be a
                  suitable option to help a message fit. If that is chosen and
                  found unsuitable, Keep Spaces returns it back to the original
                  text. <br />
                  <br />
                  <b>
                    When the messages all appear in black the PDF can be
                    generated. It can be printed (single-sided, landscape)
                    directly onto A4. Alternatively, it can be saved as a .pdf
                    file for later printing.
                  </b>
                </td>
              </tr>
              <tr>
                <td rowspan="2">
                  <b>Step 3:</b> Entering student names and messages{" "}
                </td>
                <td>
                  Messages for:
                  <br />
                  <br />
                  <b>Early Primary</b>
                  <br />
                  must fit within the parameters of the three lines. Each line
                  is 16 characters.
                </td>
                <td>
                  <img src={EarlyPdf} height="650px" />

                  <br />
                  <b>
                    The PDFs for these two students are shown further below.
                  </b>
                </td>
              </tr>
              <tr>
                <td>
                  Messages for:
                  <br />
                  <br />
                  <b>Primary whole number</b>
                  <br />
                  or
                  <br />
                  <b>Primary rational number</b>
                  <br />
                  must fit within the parameters of the four lines. Each line is
                  32 characters.
                </td>
                <td>
                  <img src={WholePdf} height="650px" />
                  <br />
                  <b>
                    The PDFs for these two students are shown further below.
                  </b>
                </td>
              </tr>
              <tr>
                <td>
                  <b>The Early Primary PDFs</b>
                  <br />
                  <br />
                  The following two worksheets were generated after the editing
                  of message #2.
                  <br />
                  <br />
                  Note the random numbers and the randomness of the characters
                  associated with each answer.
                  <br />
                  <br />
                  Each page is designed to be printed on A4.
                </td>
                <td colspan="2">
                  <img src={EarlyPdfDemo} height="650px" />
                  <br />
                  <img src={EarlyPdfDemo2} height="650px" />
                </td>
              </tr>
              <tr>
                <td>
                  <b>The Primary whole number PDFs</b>
                  <br />
                  <br />
                  Another set of these PDFs were generated – see further below.
                </td>
                <td colspan="2">
                  <img src={WholePdfDemo} height="750px" />
                  <br />
                  <img src={WholePdfDemo2} height="750px" />
                </td>
              </tr>
              <tr>
                <td>
                  It is possible to return to Step 2 to generate the PDF <i>again</i>,
                  to produce alternative worksheets using the same tasks and
                  messages.<br/> The worksheet shown opposite was generated in this
                  way.
                  <br />
                  <br />
                  Note that combinations of tasks occasionally produce <b>duplicate
                  answers</b> and the first worksheet illustrates this circumstance.
                  <br />
                  <br />
                  The answer 27, occurs in two different maths tasks (shown in
                  red).
                  <br />
                  <br />
                  The bold <b>27s</b> in the message indicate that there is a
                  duplication. The message therefore needs to be more carefully
                  spelt. In this case, using the <i>u</i> or the <i>t</i> where
                  necessary.
                  <br />
                  <br />A final example, showing this in an Early Primary PDF,
                  is shown next.
                </td>
                <td colspan="2">
                  {" "}
                  <img src={WholePdfDouble} height="750px" />
                </td>
              </tr>
              <tr>
                <td>
                  Note that some combinations of Early Primary tasks can also
                  lead to a duplication of numerical answers.
                  <br />
                  <br />
                  The example opposite illustrates this situation, where the
                  number 5 is shown as an answer in two different maths tasks.
                  <br />
                  <br />
                  The bold 5s in the message indicate that there is a
                  duplication. The message therefore needs to be more carefully
                  spelt.
                  <br />
                  <br />
                  In this case, using the<i> a </i>
                  or the <i>f</i> where necessary.
                </td>
                <td colspan="2">
                  <img src={EarlyPdfDouble} height="650px" />
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default SomeInstruction;
const Title = styled.p`
  font-size: 18px;
  font-weight: 700;
  margin: 0;
  color: #000;
`;
const TdCenter = styled.td`
text-align: center;
`;
const SubTitle = styled.p`
  font-size: 16px;
  margin: 0;
  color: #000;
  margin-bottom: 20px;
`;
const Decription = styled.p`
  font-size: 16px;
  margin: 0;
  color: #000;
`;
const UnOrderList = styled.ul`
  a {
    text-decoration: none;
    color: black;
  }
`;

const ListItem = styled.li`
  margin: ${(props) => (props.smallSize ? "5px 0" : " 10px 0")};
  list-style-type: disc p {
    font-size: 13px;
    color: #7c7c7c;
    margin-bottom: 0px;
  }
  .mr-25 {
    margin-right: 25px;
  }
  .mr-30 {
    margin-right: 33px;
  }
  .mr-32 {
    margin-right: 40px;
  }
`;
