import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import rootReducers from './reducers'
import { loadingBarMiddleware } from 'react-redux-loading-bar'
const initialState = {}

const middleware = [thunk]

const store = createStore(
  rootReducers,
  initialState,
  compose(
    applyMiddleware(
      ...middleware,
      loadingBarMiddleware({
        promiseTypeSuffixes: ['REQUEST', 'SUCCESS', 'FAILURE'],
      })
    ),
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()) ||
      compose
  )
)

export default store
