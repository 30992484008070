import React from 'react'

import { useSelector } from 'react-redux'

import { Card, CardBody, Row } from 'reactstrap'
import SingleCard from './SingleCard'
import styled from 'styled-components'

const Index = (props) => {
  const primaryMathAddition = useSelector(
    (state) => state.primaryMaths.primaryMathAddition
  )

  return (
    <StudentCardBox className='card-shadow'>
      <StudentBoxBody>
        <Row>
          {primaryMathAddition.map((data, i) => (
            <SingleCard
              key={i}
              className='my-2'
              title={data.title}
              name={data.type}
              mathlist={data.result}
              //   closeBTN={Close}
              symbol={data.symbol}
            />
          ))}
        </Row>
      </StudentBoxBody>
    </StudentCardBox>
  )
}

Index.propTypes = {}

export default Index
const StudentCardBox = styled(Card)`
  border-radius: 0.5rem;
  border: none;
`
const StudentBoxBody = styled(CardBody)`
  padding: 1rem 1rem;
`
