import React, { Fragment } from "react";
import { Col, Card } from "reactstrap";
import styled from "styled-components";
import { InlineMath } from "react-katex";
import "katex/dist/katex.min.css";
// import PropTypes from 'prop-types'

import ReactHtmlParser from "react-html-parser";
const EarlyPrimaryFooter = (props) => {
  const { title, closeBTN, mathlist, name, symbol } = props;

  // console.log('🚀 ~ EarlyPrimaryFooter ~ doc', doc)
  return (
    <Fragment>
      <Col sm="2" lg="2" md="2" xl="2" xs="2" className="p-0 px-1">
        <ChildBoxedMake className="mt-2">
          <Col sm="12" lg="12" md="12" xl="12" xs="12">
            <CardHeading>
              <p dangerouslySetInnerHTML={{ __html: `${title}` }}></p>
              <ButtonOrange value={name} onClick={(e) => closeBTN(e)}>
                X
              </ButtonOrange>
            </CardHeading>
          </Col>
          <Col sm="12" lg="12" md="12" xl="12" xs="12">
            <ButtonGroup1>
              {mathlist.length > 0
                ? mathlist.map((value, i) => {
                    let a = value.q;
                    let finalstring;
                    if (typeof a === "number") {
                    } else if (typeof a === "string") {
                      if (a.indexOf(" ") > 0) {
                        let b = a.split(" ");
                        let c = b[1].split("/");
                        let f = b[0];
                        let d = c[0];
                        let e = c[1];
                        finalstring = f + `\\frac{${d}}{${e}}`;
                      } else {
                        let b = a.split("/");
                        // console.log(a);
                        let c = b[0];
                        let d = b[1] ? b[1] : null;

                        finalstring = d ? `\\boldsymbol{\\frac{${c}}{${d}}}` : `\\text{${c}}`;
                      }
                    }

                    return (
                      <Fragment key={i}>
                        <button>
                          {typeof a === "string" && a.indexOf("%") === -1 && (
                            <InlineMath>{finalstring}</InlineMath>
                            
                          )}
                          {typeof a === "number" && value.q + " "}{" "}
                          {typeof a === "string" &&
                            a.indexOf("%") >= 0 &&
                            value.q + " "}{" "}
                          {symbol ? ReactHtmlParser(symbol) : null}
                        </button>
                      </Fragment>
                    );
                  })
                : null}
            </ButtonGroup1>
            <ButtonGroup2>
              <button></button>
              <button></button>
              <button></button>
              <button></button>
              <button></button>
            </ButtonGroup2>
            <ButtonGroup3>
              <button></button>
              <button></button>
              <button></button>
              <button></button>
              <button></button>
            </ButtonGroup3>
          </Col>
        </ChildBoxedMake>
      </Col>
    </Fragment>
  );
};

// EarlyPrimaryFooter.propTypes = {

// }

export default EarlyPrimaryFooter;

//

const CardHeading = styled.div`
  display: flex;
  justify-content: space-between;
  p {
    font-weight: 500;
  }
`;

const ButtonOrange = styled.button`
  height: 100%;
  font-size: 10px;
  border: none;
  background: ${(props) => props.theme.primaryColor};
  font-weight: 400;
  padding: 1px 10px;
  color: #fff;
  border-radius: 14px;

  &:hover {
    background-color: ${(props) => props.theme.primaryColor};
  }
  &:hover {
    background-color: ${(props) => props.theme.primaryColor};
  }

  &:active {
    background-color: ${(props) => props.theme.primaryColor};
    border-color: ${(props) => props.theme.primaryColor};
    box-shadow: ${(props) => `0 0 0 0.25rem ${props.theme.buttonActive}`};
  }
  &:focus {
    background-color: ${(props) => props.theme.primaryColor};
    border-color: ${(props) => props.theme.primaryColor};
    box-shadow: ${(props) => `0 0 0 0.25rem ${props.theme.buttonActive}`};
  }
`;

const ChildBoxedMake = styled(Card)`
  padding: 4px 4px;

  p {
    font-size: 9px;
  }
`;

const ButtonGroup1 = styled.div`
  display: flex;
  flex: 0 1 20%;
  justify-content: space-between;
  flex-wrap: wrap;
  button {
    height: 27px;
    width: 29px;
    font-size: 9px;
    border: ${(props) => `1px solid ${props.theme.primaryColor}`};
    background-color: #fff;
    /* margin: 4px; */
    border-radius: 4px;
  }
`;
const ButtonGroup2 = styled.div`
  display: flex;
  flex: 0 1 20%;

  justify-content: space-between;

  button {
    height: 27px;
    width: 29px;
    font-size: 12px;
    border: ${(props) => `1px solid ${props.theme.primaryColor}`};
    background-color: #fff;
    margin: 6px 0px;
    border-radius: 4px;
    @media (max-width: 1200px) {
      margin: 5px 0px;
    }
  }
`;
const ButtonGroup3 = styled.div`
  display: flex;
  flex: 0 1 20%;
  /* align-items: center; */
  justify-content: space-between;
  /* flex-wrap: wrap; */
  button {
    height: 27px;
    width: 29px;
    font-size: 12px;
    /* padding: 4px 14px; */
    border: ${(props) => `1px solid ${props.theme.primaryColor}`};
    background-color: #fff;
    /* margin: 5px; */
    border-radius: 4px;
  }
`;
