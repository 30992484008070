import React from "react";
import { Col, Container, Row } from "reactstrap";
import styled from "styled-components";
import PrimaryWhole from "../FooterComponent/ExamplePdf/PrimaryWholeExample.pdf"
const PrimaryWholeExample = () => {
  return (
    <Container>
      <CenterRow>
        <Col xl="10" sm="10" lg="10" md="10">
        <Title>Primary Example</Title>
          <iframe
          src={`${PrimaryWhole}#toolbar=0`}
          view="fit"
          width="100%"
          height="640px"
          style={{ overflow: 'hidden' }}
          scrolling="no"
        ></iframe>
        </Col>
      </CenterRow>
    </Container>
  );
};

export default PrimaryWholeExample;
const CenterRow = styled(Row)`
  display: flex;
  justify-content: center;
`;
const Title = styled.p`
 font-size: 18px;
  font-weight: 700;
  margin: 0;
  color: #000;
`;