const initialState = {
  add_to_make_10: null,
  primaryMathList: [],
  add_to_make: null,
  addToMark: [],
  rand_add_to_make: null,
  add_value: null,
  add: [],
  rand_add_value: null,
  subtract_value: null,
  subtract: [],
  rand_subtract_value: null,
  subtractFromTEN_value: null,
  subtractFromTEN: [],
  rand_subtractFromTEN_value: null,
  subtractFrom_value: null,
  subtractFrom: [],
  rand_subtractFrom_value: null,
  multiple_value: null,
  multiple: [],
  rand_multiple_value: null,

  continue_add: [],
  continue_add_value: null,
  starting_add_value: null,
  msg: null,

  on_multiple_data: [],
  continue_on_multiple: null,
  starting_on_multiple: null,
  on_multiple_msg: null,

  off_multiple_data: [],
  continue_off_multiple: null,
  starting_off_multiple: null,
  off_multiple_msg: null,

  continue_sub: [],
  continue_sub_value: null,
  starting_sub_value: null,
  continue_sub_msg: null,

  sub_on_multiple_data: [],
  sub_continue_on_multiple: null,
  sub_starting_on_multiple: null,
  sub_on_multiple_msg: null,

  sub_off_multiple_data: [],
  sub_continue_off_multiple: null,
  sub_starting_off_multiple: null,
  sub_off_multiple_msg: null,

  divided_value: null,
  divided: [],
  rand_divided_value: null,
  maxLength: [],
  studentList: [],
  commentList: [],
  loading: true,
};

const PrimaryMathReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "PRIMARY_MATHS_LIST":
      return {
        ...state,
        add_to_make_10: payload.value && payload.value,
        primaryMathList: payload.data,
        loading: false,
      };
    case "ADD_TO_MARK_LIST":
      return {
        ...state,
        add_to_make: payload.value && payload.value,
        addToMark: payload.data,
        rand_add_to_make: payload.randValue && payload.randValue,
        loading: false,
      };

    case "ADD_LIST":
      return {
        ...state,
        add_value: payload.value && payload.value,
        add: payload.data,
        rand_add_value: payload.randValue && payload.randValue,
        loading: false,
      };
    case "SUBTRACT_LIST":
      return {
        ...state,
        subtract_value: payload.value && payload.value,
        rand_subtract_value: payload.randValue && payload.randValue,
        subtract: payload.data,
        loading: false,
      };
    case "SUBTRACT_FROM_10_LIST":
      return {
        ...state,
        subtractFromTEN_value: payload.value && payload.value,
        subtractFromTEN: payload.data,
        rand_subtractFromTEN_value: payload.randValue && payload.randValue,
        loading: false,
      };
    case "SUBTRACT_FROM_LIST":
      return {
        ...state,
        subtractFrom_value: payload.value && payload.value,
        subtractFrom: payload.data,
        rand_subtractFrom_value: payload.randValue && payload.randValue,
        loading: false,
      };
    case "MULTIPLE_LIST":
      return {
        ...state,
        multiple_value: payload.value && payload.value,
        multiple: payload.data,
        rand_multiple_value: payload.randValue && payload.randValue,
        loading: false,
      };
    case "DIVIDED_LIST":
      return {
        ...state,
        divided_value: payload.value,
        divided: payload.data,
        rand_divided_value: payload.randValue,
        loading: false,
      };
    case "ADD_CONTINUE":
      return {
        ...state,
        continue_add_value: payload.value && payload.value,
        starting_add_value: payload.value2 && payload.value2,
        continue_add: payload.data,

        msg: payload.msg && payload.msg,
        loading: false,
      };
    case "ADD_ON_MULTIPLE":
      return {
        ...state,
        on_multiple_data: payload.data,
        continue_on_multiple: payload.value && payload.value,
        starting_on_multiple: payload.value2 && payload.value2,
        on_multiple_msg: payload.msg && payload.msg,
        loading: false,
      };
    case "ADD_OFF_MULTIPLE":
      return {
        ...state,
        off_multiple_data: payload.data,
        continue_off_multiple: payload.value && payload.value,
        starting_off_multiple: payload.value2 && payload.value2,
        off_multiple_msg: payload.msg && payload.msg,
        loading: false,
      };
    case "SUB_ON_MULTIPLE":
      return {
        ...state,
        sub_on_multiple_data: payload.data,
        sub_continue_on_multiple: payload.value && payload.value,
        sub_starting_on_multiple: payload.value2 && payload.value2,
        sub_on_multiple_msg: payload.msg && payload.msg,
        loading: false,
      };
    case "SUB_OFF_MULTIPLE":
      return {
        ...state,
        sub_off_multiple_data: payload.data,
        sub_continue_off_multiple: payload.value && payload.value,
        sub_starting_off_multiple: payload.value2 && payload.value2,
        sub_off_multiple_msg: payload.msg && payload.msg,
        loading: false,
      };
    case "SUB_CONTINUE":
      return {
        ...state,
        continue_sub: payload.data,
        continue_sub_value: payload.value && payload.value,
        starting_sub_value: payload.value2 && payload.value2,
        continue_sub_msg: payload.msg && payload.msg,
        loading: false,
      };
    case "PRIMAY_MATHS_REMOVE":
      return {
        ...state,
        add_to_make_10: null,
        primaryMathList: [],
        loading: false,
      };
    case "MULTIPLE_LIST_REMOVE":
      return {
        ...state,
        multiple_value: null,
        multiple: [],
        rand_multiple_value: null,
        loading: false,
      };

    case "ADD_CONTINUE_REMOVE":
      return {
        ...state,
        continue_add_value: null,
        starting_add_value: null,
        continue_add: [],

        msg: null,
        loading: false,
      };

    case "ADD_ON_MULTIPLE_REMOVE":
      return {
        ...state,
        on_multiple_data: [],
        continue_on_multiple: null,
        starting_on_multiple: null,
        on_multiple_msg: null,
        loading: false,
      };
    case "SUB_ON_MULTIPLE_REMOVE":
      return {
        ...state,
        sub_on_multiple_data: [],
        sub_continue_on_multiple: null,
        sub_starting_on_multiple: null,
        sub_on_multiple_msg: null,
        loading: false,
      };
    case "SUB_OFF_MULTIPLE_REMOVE":
      return {
        ...state,
        sub_off_multiple_data: [],
        sub_continue_off_multiple: null,
        sub_starting_off_multiple: null,
        sub_off_multiple_msg: null,
        loading: false,
      };
    case "ADD_OFF_MULTIPLE_REMOVE":
      return {
        ...state,
        off_multiple_data: [],
        continue_off_multiple: null,
        starting_off_multiple: null,
        off_multiple_msg: null,
        loading: false,
      };
    case "SUB_CONTINUE_REMOVE":
      return {
        ...state,
        continue_sub: [],
        continue_sub_value: null,
        starting_sub_value: null,
        continue_sub_msg: null,
        loading: false,
      };
    case "SUBTRACT_FROM_REMOVE":
      return {
        ...state,
        subtractFrom_value: null,
        subtractFrom: [],
        rand_subtractFrom_value: null,
        loading: false,
      };
    case "SUBTRACT_FROM_10_RAMOVE":
      return {
        ...state,
        subtractFromTEN_value: null,
        subtractFromTEN: [],
        rand_subtractFromTEN_value: null,
        loading: false,
      };
    case "DIVIDED_LIST_REMOVE":
      return {
        ...state,
        divided_value: null,
        divided: [],
        rand_divided_value: null,
        loading: false,
      };
    case "SUBTRACT_REMOVE":
      return {
        ...state,
        subtract_value: null,
        rand_subtract_value: null,
        subtract: [],
        loading: false,
      };
    case "ADD_REMOVE":
      return {
        ...state,
        add_value: null,
        add: [],
        rand_add_value: null,
        loading: false,
      };
    case "ADD_TO_MARK_REMOVE":
      return {
        ...state,
        addToMark: [],
        add_to_make: null,
        rand_add_to_make: null,
        loading: false,
      };
    case "DISPLAY_MESSAGE_LENGTH":
      return {
        ...state,
        maxLength: payload,
      };
    case "EARLY_PRIMARY_STUDENT_ADD":
      return {
        ...state,
        studentList: [...state.studentList, ...payload],
      };
    case "EARLY_PRIMARY_STUDENT_UPDATE":
      return {
        ...state,
        studentList: state.studentList.map((stud) =>
          stud.id === payload.id ? { message: payload.data } : stud
        ),
      };
    case "EARLY_PRIMARY_STUDENT_REMOVE":
      return {
        ...state,
        studentList: state.studentList.filter((stud) => stud.id !== payload),
      };
    case "EARLY_PRIMARY_STUDENT_REMOVE_All":
      return {
        ...state,
        studentList: [],
      };
    case "EARLY_PRIMARY_MESSAGE_ADD":
      return {
        ...state,
        commentList: [...state.commentList, ...payload],
      };
    case "EARLY_PRIMARY_MESSAGE_DISCARD":
      return {
        ...state,
        commentList: payload,
      };
    case "EARLY_PRIMARY_MESSAGE_UPDATE":
      return {
        ...state,
        commentList: state.commentList.map((stud) =>
          stud.id === payload.id
            ? {
                id: payload.id,
                message: payload.data,
                discard: payload.discard,
              }
            : stud
        ),
      };
    case "EARLY_PRIMARY_MESSAGE_REMOVE":
      return {
        ...state,
        commentList: state.commentList.filter((stud) => stud.id !== payload),
      };
    case "EARLY_PRIMARY_MESSAGE_REMOVE_All":
      return {
        ...state,
        commentList: [],
      };
    case "REMOVE_PRIMARY_TABLE":
      return {
        ...state,
        primaryMathList: [],
        addToMark: [],
        add: [],
        subtract: [],
        divided: [],
        subtractFromTEN: [],
        subtractFrom: [],
        multiple: [],
        continue_add: [],
        studentList: [],
        loading: false,
      };
    default:
      return state;
  }
};

export default PrimaryMathReducer;
