export const add_to_make = [20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130];
export const add = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
export const subtract = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
export const subtractFrom = [
  20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130,
];
export const multiply = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
export const divide = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
//continue, adding
export const continue_Number = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
export const starting_Number = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

// use less starting_On_Number, starting_On_Number but dont remove both
export const starting_On_Number = [2, 3, 4, 5, 6, 7, 8, 9, 10];
export const starting_Off_Number = [3, 4, 5, 6, 7, 8, 9, 10];

export const sub_continue_Number = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
export const sub_starting_On_Number = [2, 3, 4, 5, 6, 7, 8, 9, 10];
export const sub_starting_Off_Number = [3, 4, 5, 6, 7, 8, 9, 10];

export const sub_starting_Number = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
