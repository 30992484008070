import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
// import PropTypes from 'prop-types'
import { Row, Col, Card, CardHeader, CardBody, Container } from 'reactstrap';
import styled from 'styled-components';
import { rationalNumber, WholeNumber } from '../../api/data/PrimaryMaths';
import ToastMessage from '../../DefaultComponets/ToastMessage/ToastMessage';
import EarlyPrimaryFooter from '../EarlyPrimary/EarlyPrimaryFooter';

const PrimaryMaths = (props) => {
  return (
    <Fragment>
      <Container>
        <Row>
          <ToastMessage />
          <Col xl='12' sm='12' md='12' lg='12'>
            <Heading>Primary Maths</Heading>
          </Col>
          <Col xl='12' sm='12' md='12' lg='12' className='mb-5'>
            <SubHeading>Choose any six tasks from this set</SubHeading>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row>
          <Col xl='6' sm='12' md='6' lg='6' xs='12'>
            <Card>
              <BoxHeader>Whole number</BoxHeader>
              <BoxBody>
                <TableResponsive>
                  <CustomTable className='card-table table table-borderless '>
                    <thead>
                      <tr>
                        <th>ONE OPERATION</th>
                        <th>DESCRIBED IN LANGUAGE</th>
                        <th>DESCRIBED ABSTRACTLY</th>
                        <th>TWO OPERATION</th>
                        <th>DESCRIBED IN LANGUAGE</th>
                        <th>DESCRIBED ABSTRACTLY</th>
                      </tr>
                    </thead>
                    <tbody>
                      {WholeNumber.map((data, i) => (
                        <tr key={i}>
                          <td>
                            <TableBTN>{data.operationOne}</TableBTN>
                          </td>
                          <td>
                            <TableBTNOrange>
                              {data.languageFirst}
                            </TableBTNOrange>
                          </td>
                          <td>
                            <TableBTNOrange>{data.romanFirst}</TableBTNOrange>
                          </td>
                          <td>
                            <TableBTNOrange>
                              {data.abstractlyFirst}
                            </TableBTNOrange>
                          </td>
                          {data.hasOwnProperty('operationTwo') ? (
                            <React.Fragment>
                              <td>
                                <TableBTN operation={true}>
                                  {data.operationTwo}
                                </TableBTN>
                              </td>
                              <td>
                                <TableBTNOrange>
                                  {data.languageSecond}
                                </TableBTNOrange>
                              </td>
                              <td>
                                <TableBTNOrange>
                                  {data.abstractlySecond}
                                </TableBTNOrange>
                              </td>
                            </React.Fragment>
                          ) : null}
                        </tr>
                      ))}
                    </tbody>
                  </CustomTable>
                </TableResponsive>
              </BoxBody>
            </Card>
          </Col>
          <Col xl='6' sm='12' md='6' lg='6' xs='12'>
            <Card>
              <BoxHeader>Rational number</BoxHeader>
              <BoxBody>
                <TableResponsive>
                  <CustomTable className='card-table table table-borderless '>
                    <thead>
                      <tr>
                        <th>FRACTIONS</th>
                        <th>DECIMALS</th>
                        <th>PERCENTAGES</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rationalNumber.map((data) => (
                        <tr>
                          <td>
                            <TableBTN>{data.text}</TableBTN>
                          </td>
                          <td>
                            <TableBTN>{data.text1} </TableBTN>{' '}
                          </td>
                          {data.text2 ? (
                            <td>
                              <TableBTN>{data.text2}</TableBTN>
                            </td>
                          ) : null}
                        </tr>
                      ))}
                    </tbody>
                  </CustomTable>
                </TableResponsive>
              </BoxBody>
            </Card>
          </Col>
          <Row className='mt-4'>
            <Col xl='3' sm='6' md='3' lg='3' xs='6' className='mt-4'>
              <Link to='/primary-maths/funsheet'>
                <SubmitBTN>Enter Names & Messages </SubmitBTN>
              </Link>
            </Col>
            <Col sm='6' lg='9' md='9' xl='9' xs='6'>
              <Col sm='12' lg='12' md='12' xl='12' xs='12'>
                <Row>
                  <Boxed className='card-shadow'>
                    <Row>
                      <EarlyPrimaryFooter
                        className='my-2'
                        title={'Add'}
                        number={10}
                        // closeBTN={(e) => addMakeClose(e)}
                        name={'Add_make_10'}
                        mathlist={[6, 7, 8, 9, 3]}
                      />
                      <EarlyPrimaryFooter
                        className='my-2'
                        title={'Add'}
                        number={10}
                        // closeBTN={(e) => addMakeClose(e)}
                        name={'Add_make_10'}
                        mathlist={[6, 7, 8, 9, 3]}
                      />
                      <EarlyPrimaryFooter
                        className='my-2'
                        title={'Add'}
                        number={10}
                        // closeBTN={(e) => addMakeClose(e)}
                        name={'Add_make_10'}
                        mathlist={[6, 7, 8, 9, 3]}
                      />
                    </Row>
                  </Boxed>
                </Row>
              </Col>
            </Col>
          </Row>
        </Row>
      </Container>
    </Fragment>
  );
};

// PrimaryMaths.propTypes = {

// }

export default PrimaryMaths;

//
const Heading = styled.h1`
  font-size: 24px;
`;
const BoxBody = styled(CardBody)`
  background-color: #fbfbfb;
`;
const TableResponsive = styled.div`
  @media (max-width: 991px) {
    overflow-x: auto;
  }
`;

const BoxHeader = styled(CardHeader)`
  background-color: #fbfbfb;
  text-align: center;
`;
const SubHeading = styled.h5`
  font-size: 19px;
  color: #848484;
`;

const CustomTable = styled.table`
  thead {
    font-family: Metropolis;
    font-size: 9px;
    font-style: normal;
    font-weight: 600;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    @media (min-width: 1200px) {
      font-size: 10px;
    }
  }
`;
const TableBTN = styled.button`
  background: #ffffff;
  box-shadow: 3px 5px 9px rgb(0 0 0 / 5%);
  border-radius: 5px;
  border: none;
  width: ${(props) => (props.operation ? '100%' : '100%')};
  font-size: 11px;

  text-align: start;
  padding: 5px 0px 5px 2px;
`;
const TableBTNOrange = styled.button`
  background: ${(props) => props.theme.primaryColor};
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.05);
  border-radius: 27px;
  /* width: 95.58px; */
  width: 100%;
  height: 29px;
  border: none;
`;

const SubmitBTN = styled.button`
  border: none;
  background-color: ${(props) => props.theme.primaryColor};
  border-radius: 7px;
  color: white;
  margin-left: 20px;
  padding: 12px 35px;
  &:active {
  }
  @media (max-width: 1200px) {
    padding: 12px 14px;
  }
`;
const Boxed = styled(Card)`
  border-radius: 0.5rem;
  border: none;
  padding: 15px 18px;
`;
