import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
// import PropTypes from 'prop-types'
import { Row, Col, Card, CardHeader, CardBody, Container } from "reactstrap";
import styled from "styled-components";
import { WholeNumberOpt1, WholeNumberOpt2 } from "../../api/data/PrimaryMaths";
import ToastMessage from "../../DefaultComponets/ToastMessage/ToastMessage";
import EarlyPrimaryFooter from "../Commons/Primary/Card/Index";
import Right_arrow from "../../assets/image/arrow-right.png";
import {
  getAllAdditionNumber,
  getAllSubtractNumber,
  getAllMultiplication,
  getAllDivide,
  getAllSquare,
  getAllSquareRoot,
  getAddThenMultiply,
  getSubtractThenMultiply,
  getMultiplyThenAdd,
  getMultiplyThenSubtract,
  getDivideThenAdd,
  getDivideThenSubtract,
  getDivideThenMultiply,
  getSquareRootThenMultiply,
} from "../../action/primaryMathsAction";

// import {  } from "../../action/primaryMathsAction";
import { connect, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

const PrimaryMathWholeNumber = (props) => {
  const dispatch = useDispatch();
  const primaryMaths = useSelector((state) => state.primaryMaths);
  const [showModal, setShowModal] = useState("block");

  // dispatch actions
  const Addition = (data, type) => {
    if (primaryMaths.primaryMathAddition.length > 5) {
      toast.success("You can select maximum 6 activities only");
      return;
    }
    // type = 1 DESCRIBED IN LANGUAGE
    // type = 2 DESCRIBED ABSTRACTLY
    // type = 3 DESCRIBED IN ROMAN
    //
    if (data.operationOne === "Add" && type === 1) {
      props.getAllAdditionNumber(data.languageFirst.key, type);
    }
    if (data.operationOne === "Subtract" && type === 1) {
      props.getAllSubtractNumber(data.languageFirst.key);
    }
    if (data.operationOne === "Multiply" && type === 1) {
      props.getAllMultiplication(data.languageFirst.key);
    }
    if (data.operationOne === "Divide" && type === 1) {
      props.getAllDivide(data.languageFirst.key);
    }
    if (data.operationOne === "Square" && type === 1) {
      props.getAllSquare(data.languageFirst.key);
    }
    if (data.operationOne === "Square root" && type === 1) {
      props.getAllSquareRoot(data.languageFirst.key);
    }

    //
    if (data.operationOne === "Add" && type === 2) {
      props.getAllAdditionNumber(data.abstractlyFirst.key, type);
    }
    if (data.operationOne === "Subtract" && type === 2) {
      props.getAllSubtractNumber(data.abstractlyFirst.key, type);
    }
    if (data.operationOne === "Multiply" && type === 2) {
      props.getAllMultiplication(data.abstractlyFirst.key, type);
    }
    if (data.operationOne === "Divide" && type === 2) {
      props.getAllDivide(data.abstractlyFirst.key, type);
    }
    if (data.operationOne === "Square" && type === 2) {
      props.getAllSquare(data.abstractlyFirst.key, type);
    }
    if (data.operationOne === "Square root" && type === 2) {
      props.getAllSquareRoot(data.abstractlyFirst.key, type);
    }

    //
    if (data.operationOne === "Add" && type === 3) {
      props.getAllAdditionNumber(data.romanFirst.key, type);
    }
    if (data.operationOne === "Subtract" && type === 3) {
      props.getAllSubtractNumber(data.romanFirst.key, type);
    }
    if (data.operationOne === "Multiply" && type === 3) {
      props.getAllMultiplication(data.romanFirst.key, type);
    }
    if (data.operationOne === "Divide" && type === 3) {
      props.getAllDivide(data.romanFirst.key, type);
    }
    if (data.operationOne === "Square" && type === 3) {
      props.getAllSquare(data.romanFirst.key, type);
    }
    if (data.operationOne === "Square root" && type === 3) {
      props.getAllSquareRoot(data.romanFirst.key, type);
    }
    //

    if (data.operationTwo === "Add Then Multiply" && type === 1) {
      props.getAddThenMultiply(data.languageSecond.key, type);
    }
    if (data.operationTwo === "Subtract Then Multiply" && type === 1) {
      props.getSubtractThenMultiply(data.languageSecond.key, type);
    }
    if (data.operationTwo === "Multiply Then Add" && type === 1) {
      props.getMultiplyThenAdd(data.languageSecond.key, type);
    }
    if (data.operationTwo === "Multiply Then subtract" && type === 1) {
      props.getMultiplyThenSubtract(data.languageSecond.key, type);
    }

    if (data.operationTwo === "Divide Then add" && type === 1) {
      props.getDivideThenAdd(data.languageSecond.key, type);
    }
    if (data.operationTwo === "Divide Then subtract" && type === 1) {
      props.getDivideThenSubtract(data.languageSecond.key, type);
    }
    if (data.operationTwo === "Divide Then multiply" && type === 1) {
      props.getDivideThenMultiply(data.languageSecond.key, type);
    }
    if (data.operationTwo === "Square Root Then Multiply" && type === 1) {
      props.getSquareRootThenMultiply(data.languageSecond.key, type);
    }
    //

    if (data.operationTwo === "Add Then Multiply" && type === 2) {
      props.getAddThenMultiply(data.abstractlySecond.key, type);
    }
    if (data.operationTwo === "Subtract Then Multiply" && type === 2) {
      props.getSubtractThenMultiply(data.abstractlySecond.key, type);
    }
    if (data.operationTwo === "Multiply Then Add" && type === 2) {
      props.getMultiplyThenAdd(data.abstractlySecond.key, type);
    }
    if (data.operationTwo === "Multiply Then subtract" && type === 2) {
      props.getMultiplyThenSubtract(data.abstractlySecond.key, type);
    }

    if (data.operationTwo === "Divide Then add" && type === 2) {
      props.getDivideThenAdd(data.abstractlySecond.key, type);
    }
    if (data.operationTwo === "Divide Then subtract" && type === 2) {
      props.getDivideThenSubtract(data.abstractlySecond.key, type);
    }
    if (data.operationTwo === "Divide Then multiply" && type === 2) {
      props.getDivideThenMultiply(data.abstractlySecond.key, type);
    }
    if (data.operationTwo === "Square Root Then Multiply" && type === 2) {
      props.getSquareRootThenMultiply(data.abstractlySecond.key, type);
    }
    //

    if (data.operationTwo === "Add Then Multiply" && type === 3) {
      props.getAddThenMultiply(data.romanSecond.key, type);
    }
    if (data.operationTwo === "Subtract Then Multiply" && type === 3) {
      props.getSubtractThenMultiply(data.romanSecond.key, type);
    }
    if (data.operationTwo === "Multiply Then Add" && type === 3) {
      props.getMultiplyThenAdd(data.romanSecond.key, type);
    }
    if (data.operationTwo === "Multiply Then subtract" && type === 3) {
      props.getMultiplyThenSubtract(data.romanSecond.key, type);
    }
    if (data.operationTwo === "Divide Then add" && type === 3) {
      props.getDivideThenAdd(data.romanSecond.key, type);
    }
    if (data.operationTwo === "Divide Then subtract" && type === 3) {
      props.getDivideThenSubtract(data.romanSecond.key, type);
    }
    if (data.operationTwo === "Divide Then multiply" && type === 3) {
      props.getDivideThenMultiply(data.romanSecond.key, type);
    }
    if (data.operationTwo === "Square Root Then Multiply" && type === 3) {
      props.getSquareRootThenMultiply(data.romanSecond.key, type);
    }
  };
  const Remove = (data, type) => {
    // type = 1 DESCRIBED IN LANGUAGE
    // type = 2 DESCRIBED ABSTRACTLY

    if (data.operationOne && type === 1) {
      dispatch({
        type: "REMOVE_WHOLE_NUMBER",
        payload: data.languageFirst.key,
      });
    }
    if (data.operationTwo && type === 1) {
      dispatch({
        type: "REMOVE_WHOLE_NUMBER",
        payload: data.languageSecond.key,
      });
    }
    if (data.operationOne && type === 2) {
      dispatch({
        type: "REMOVE_WHOLE_NUMBER",
        payload: data.abstractlyFirst.key,
      });
    }
    if (data.operationTwo && type === 2) {
      dispatch({
        type: "REMOVE_WHOLE_NUMBER",
        payload: data.abstractlySecond.key,
      });
    }
    if (data.operationOne && type === 3) {
      dispatch({
        type: "REMOVE_WHOLE_NUMBER",
        payload: data.romanFirst.key,
      });
    }
    if (data.operationTwo && type === 3) {
      dispatch({
        type: "REMOVE_WHOLE_NUMBER",
        payload: data.romanSecond.key,
      });
    }
  };
  const Close = (e) => {
    dispatch({
      type: "REMOVE_WHOLE_NUMBER",
      payload: e.target.value,
    });
  };
  return (
    <Fragment>
      <Container>
        <Row className="mt-4">
          <ToastMessage />
          <Col xl="12" sm="12" md="12" lg="12" className="mb-3">
            <HeadingSecondary>
              Step2: choose any six tasks from this set of Primary whole number maths tasks
              {/* <i className="fa-solid fa-arrow-turn-down"></i> */}
            </HeadingSecondary>
          </Col>

          {/* <Col xl="8" sm="8" md="8" lg="8" className="text-center ">
            <Row className="ml-15">
              <Col xl="12" sm="12" md="12" lg="12">
                <Heading>Primary Maths</Heading>
              </Col>
              <Col xl="12" sm="12" md="12" lg="12" className="mb-5">
                <SubHeading>
                  Choose six tasks from any of the Primary Maths sets
                </SubHeading>
              </Col>
            </Row>
          </Col> */}
          {/* <Col xl="4" sm="4" md="4" lg="4">
            <BoxCard>
              <Link to="/primary-maths/rational-number">
                <BoxBody boxpadding={1}>
                  <BoxInLine>
                    <BoxHeading>Primary</BoxHeading>
         
                    <BoxPara boxpadding={1}>Whole Number</BoxPara>

                    <BoxImage>
                      <img src={Right_arrow} alt="right-arrow" />
                    </BoxImage>
                  </BoxInLine>
                </BoxBody>
              </Link>
            </BoxCard>
          </Col> */}
        </Row>
      </Container>
      <Container>
        <Row style={{ height: "90vh" }}>
          <Col xl="12" sm="12" md="12" lg="12" xs="12">
            <Card>
              <BoxHeader>Whole number</BoxHeader>
              <BoxBody>
                <TableResponsive>
                  <CustomTable className="card-table table table-borderless ">
                    <thead>
                      <tr>
                        <th>ONE OPERATION</th>
                        <th>DESCRIBED IN LANGUAGE</th>
                        <th>ROMAN NUMERALS</th>
                        <th>DESCRIBED ABSTRACTLY</th>
                        <th>TWO OPERATION</th>
                        <th>DESCRIBED IN LANGUAGE</th>
                        <th>ROMAN NUMERALS</th>
                        <th>DESCRIBED ABSTRACTLY</th>
                      </tr>
                    </thead>
                    <tbody>
                      {WholeNumberOpt2.map((data, i) => {
                        let operationDataOne =
                          primaryMaths.primaryMathAddition.find(
                            (s) =>
                              s.type === WholeNumberOpt1[i]?.languageFirst.key
                          );
                        let operationDatatwo =
                          primaryMaths.primaryMathAddition.find(
                            (s) =>
                              s.type === WholeNumberOpt1[i]?.abstractlyFirst.key
                          );
                        let operationOneRoman =
                          primaryMaths.primaryMathAddition.find(
                            (s) => s.type === WholeNumberOpt1[i]?.romanFirst.key
                          );
                        let operationTwoDescribe =
                          primaryMaths.primaryMathAddition.find(
                            (s) => s.type === data.languageSecond?.key
                          );
                        let operationTwoRoman =
                          primaryMaths.primaryMathAddition.find(
                            (s) => s.type === data.romanSecond?.key
                          );

                        let operationTwoAbstractly =
                          primaryMaths.primaryMathAddition.find(
                            (s) => s.type === data.abstractlySecond?.key
                          );

                        return (
                          <tr key={i}>
                            <React.Fragment>
                              {WholeNumberOpt1[i]?.operationOne ? (
                                <td>
                                  <TableBTN>
                                    {WholeNumberOpt1[i]?.operationOne}
                                  </TableBTN>
                                </td>
                              ) : (
                                <td colSpan="4"></td>
                              )}
                              {WholeNumberOpt1[i]?.operationOne && (
                                <>
                                  <td style={{ width: "135px" }}>
                                    <TableBTNOrange
                                      active={operationDataOne ? true : false}
                                      onClick={
                                        () =>
                                          !Boolean(operationDataOne)
                                            ? Addition(WholeNumberOpt1[i], 1)
                                            : Remove(WholeNumberOpt1[i], 1) // 1 , for DESCRIBED IN LANGUAGE
                                      }
                                    >
                                      {WholeNumberOpt1[i].languageFirst.value}
                                    </TableBTNOrange>
                                  </td>
                                  <td style={{ width: "135px" }}>
                                    <TableBTNOrange
                                      active={operationOneRoman ? true : false}
                                      onClick={
                                        () =>
                                          !Boolean(operationOneRoman)
                                            ? Addition(WholeNumberOpt1[i], 3)
                                            : Remove(WholeNumberOpt1[i], 3) // 3 , for DESCRIBED IN ROMAN
                                      }
                                    >
                                      {WholeNumberOpt1[i].romanFirst.value}
                                    </TableBTNOrange>
                                  </td>
                                  <td style={{ width: "135px" }}>
                                    <TableBTNOrange
                                      active={operationDatatwo ? true : false}
                                      onClick={
                                        () =>
                                          !Boolean(operationDatatwo)
                                            ? Addition(WholeNumberOpt1[i], 2)
                                            : Remove(WholeNumberOpt1[i], 2) // 2 , for DESCRIBED ABSTRACTLY
                                      }
                                    >
                                      {WholeNumberOpt1[i].abstractlyFirst.value}
                                    </TableBTNOrange>
                                  </td>
                                </>
                              )}
                              <td>
                                <TableBTN operation={true}>
                                  {data.operationTwo}
                                </TableBTN>
                              </td>
                              <td style={{ width: "135px" }}>
                                <TableBTNOrange
                                  active={Boolean(operationTwoDescribe)}
                                  onClick={(e) =>
                                    !Boolean(operationTwoDescribe)
                                      ? Addition(data, 1)
                                      : Remove(data, 1)
                                  }
                                >
                                  {data.languageSecond.value}
                                </TableBTNOrange>
                              </td>
                              <td style={{ width: "135px" }}>
                                <TableBTNOrange
                                  active={Boolean(operationTwoRoman)}
                                  onClick={(e) =>
                                    !Boolean(operationTwoRoman)
                                      ? Addition(data, 3)
                                      : Remove(data, 3)
                                  }
                                >
                                  {data.languageSecond.value}
                                </TableBTNOrange>
                              </td>
                              <td style={{ width: "135px" }}>
                                <TableBTNOrange
                                  active={Boolean(operationTwoAbstractly)}
                                  onClick={(e) =>
                                    !Boolean(operationTwoAbstractly)
                                      ? Addition(data, 2)
                                      : Remove(data, 2)
                                  }
                                >
                                  {data.abstractlySecond.value}
                                </TableBTNOrange>
                              </td>
                            </React.Fragment>
                          </tr>
                        );
                      })}
                    </tbody>
                  </CustomTable>
                </TableResponsive>
              </BoxBody>
            </Card>
          </Col>
          {primaryMaths.primaryMathAddition.length ??
            primaryMaths.primaryMathAddition ? (
            <Row>
              <div className="fixed-bottom ">
                <Icon
                  onClick={(e) =>
                    setShowModal(showModal === "none" ? "block" : "none")
                  }
                  value={showModal === "none" ? true : false}
                >
                  {showModal === "none" ? (
                    <i className="fa fa-chevron-up fa-md"></i>
                  ) : (
                    <i className="fa fa-chevron-down fa-md"></i>
                  )}
                </Icon>
                <Boxed
                  className="card-around-shadow"
                  style={{ display: showModal }}
                >
                  <Row>
                    <Col lg={10}>
                      <OverFlow>
                        {primaryMaths.primaryMathAddition.map((data, i) => (
                          <EarlyPrimaryFooter
                            key={i}
                            className="my-2"
                            title={data.title}
                            name={data.type}
                            mathlist={data.result}
                            closeBTN={Close}
                            symbol={data.symbol}
                          />
                        ))}
                      </OverFlow>
                    </Col>
                    <Col
                      md={2}
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        alignContent: "center",
                        justifyContent: "center",
                      }}
                    >
                      <ButtonProcess>
                        <Link to="/primary-maths-whole/student">
                          <button>Proceed to Enter Names & Messages</button>
                        </Link>
                      </ButtonProcess>
                    </Col>
                  </Row>
                </Boxed>
              </div>
            </Row>
          ) : null}
        </Row>
      </Container>
    </Fragment>
  );
};

// PrimaryMathWholeNumber.propTypes = {

// }

export default connect(null, {
  getAllAdditionNumber,
  getAllSubtractNumber,
  getAllMultiplication,
  getAllDivide,
  getAllSquare,
  getAllSquareRoot,
  getAddThenMultiply,
  getSubtractThenMultiply,
  getMultiplyThenAdd,
  getMultiplyThenSubtract,
  getDivideThenAdd,
  getDivideThenSubtract,
  getDivideThenMultiply,
  getSquareRootThenMultiply,
})(PrimaryMathWholeNumber);

//
const Heading = styled.h1`
  font-size: 24px;
`;
const HeadingSecondary = styled.h2`
  font-size: 20px;
`;
const BoxBody = styled(CardBody)`
  background-color: #fbfbfb;
`;
const TableResponsive = styled.div`
  @media (max-width: 991px) {
    overflow-x: auto;
  }
`;

const BoxHeader = styled(CardHeader)`
  background-color: #fbfbfb;
  text-align: center;
`;
const SubHeading = styled.h5`
  font-size: 19px;
  color: #848484;
`;

const CustomTable = styled.table`
  thead {
    font-family: Metropolis;
    font-size: 9px;
    font-style: normal;
    font-weight: 600;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    @media (min-width: 1200px) {
      font-size: 10px;
    }
  }
`;
const TableBTN = styled.button`
  background: #ffffff;
  box-shadow: 3px 5px 9px rgb(0 0 0 / 5%);
  border-radius: 5px;
  border: none;
  width: ${(props) => (props.operation ? "100%" : "100%")};
  font-size: 11px;

  text-align: start;
  padding: 5px 0px 5px 6px;
`;
const TableBTNOrange = styled.button`
  background: ${(props) =>
    props.active === true ? `${props.theme.primaryColor}` : "white"};
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.05);
  border-radius: 27px;
  /* width: 95.58px; */
  width: 100%;
  height: 29px;
  border: ${(props) =>
    props.active !== true
      ? ` 1.5px solid ${props.theme.primaryColor}`
      : "white"};

  font-size: 11px;
  color: ${(props) =>
    props.active !== true ? `${props.theme.primaryColor}` : "white"};
`;

const Boxed = styled(Card)`
  border-radius: 0.5rem;
  border: none;
  padding: 15px 18px;
`;
const ButtonProcess = styled.div`
  margin-top: 25px;
  background-color: ${(props) => props.theme.primaryColor};
  position: relative;
  border-radius: 7px;
  text-align: center;
  button {
    background-color: ${(props) => props.theme.primaryColor};
    border-radius: 7px;
    padding: 12px 6px;
    border: none;
    font-weight: 600;
    color: #fff;
  }
  a {
    text-decoration: none;
    color: #fff;
  }
`;

const BoxInLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const BoxHeading = styled.h3`
  font-size: 18px;
  margin-bottom: 0px;
  font-family: "Saira";
  flex: 1 1 auto;
  color: black;
`;
const BoxCard = styled(Card)`
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  a {
    text-decoration: none;
  }
`;

const BoxPara = styled.p`
  font-size: 13px;
  font-weight: 400;
  color: #7c7c7c;
  font-family: "Roboto";
  margin-bottom: 0px;
  text-decoration: none;
  flex: ${(props) => (props.boxpadding ? "1 1 35%" : "1 1 49%")};
  @media (max-width: 1200px) {
    flex: ${(props) => (props.boxpadding ? "1 1 30%" : "1 1 49%")};
  }
`;
const BoxImage = styled.div``;
const Icon = styled.div`
  float: right;
  position: relative;
  top: ${(props) => (props.value === true ? "-6px" : "1px")};
  right: 10px;
  z-index: 10;
  cursor: pointer;
`;
const OverFlow = styled.div`
  display: flex;
  /* overflow-x: auto; */

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* border-radius: 10px; */
  }
  ::-webkit-scrollbar {
    height: 8px; /* height of horizontal scrollbar ← You're missing this */
    width: 8px; /* width of vertical scrollbar */
    border: 1px solid #d5d5d5;
  }
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.primaryColor};
    border-radius: 10px;
    height: 100%;
  }
`;
