import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Logo from "../../assets/image/logo2.png";

const DefaultFooter = (props) => {
  return (
    <FooterSize>
      <footer
        className="text-center text-white"
        style={{ backgroundColor: "#fff" }}
      >
        {/* <!-- Grid container --> */}
        <UnOrderList>
          <Link to={{ pathname: "/about-us" }} target="_blank">
            <ListItem>about Elumat</ListItem>
          </Link>
          <Link to={{ pathname: "/some-instructions" }} target="_blank">
            <ListItem>some instructions </ListItem>
          </Link>
          <Link to={{ pathname: "/early-primary-example" }} target="_blank">
            <ListItem>Early Primary Examples</ListItem>
          </Link>{" "}
          <Link to={{ pathname: "/primary-example" }} target="_blank">
            <ListItem>Primary Examples</ListItem>
          </Link>{" "}
          <Link to={{ pathname: "/100-language-example" }} target="_blank">
            <ListItem>100 language Examples</ListItem>
          </Link>{" "}
          <Link to={{ pathname: "/contact" }} target="_blank">
            <ListItemNone>Contact</ListItemNone>
          </Link>
        </UnOrderList>
        {/* <!-- Grid container --> */}

        {/* <!-- Copyright --> */}
        <FooterTitle>
          <p>© 2023</p>
          <img
            src={Logo}
            alt="Logo"
            height="15px"
            style={{ marginTop: "5px", marginLeft: "10px" }}
          />
        </FooterTitle>
        {/* <!-- Copyright --> */}
      </footer>
    </FooterSize>
  );
};

export default DefaultFooter;

const FooterSize = styled.div`
  margin-top: 40px;
  background-color: gray;
`;
const FooterTitle = styled.div`
  /* margin-top: 2px; */
  text-align: center;
  color: #000;
  display: flex;
  justify-content: center;
`;
const UnOrderList = styled.ul`
  display: flex;
  /* justify-content: space-evenly; */
  justify-content: center;

  padding-left: 0px;
  a {
    text-decoration: none;
    color: black;
  }
`;
const ListItem = styled.li`
  display: flex;
  justify-content: space-between;
  border-right: 1px solid #000;
  margin: ${(props) => (props.smallSize ? "5px 0" : " 10px 0")};

  padding: 5px 10px;
  cursor: pointer;
  align-items: center;

  /*  */
  a {
    text-decoration: none;
    color: black;
  }
  /*  */

  p {
    font-size: 13px;
    color: #7c7c7c;
    margin-bottom: 0px;
  }
  .mr-25 {
    margin-right: 25px;
  }
  .mr-30 {
    margin-right: 33px;
  }
  .mr-32 {
    margin-right: 40px;
  }

`;
const ListItemNone = styled.li`
  display: flex;
  justify-content: space-between;

  margin: ${(props) => (props.smallSize ? "5px 0" : " 10px 0")};

  padding: 5px 10px;
  cursor: pointer;
  align-items: center;

  /*  */
  a {
    text-decoration: none;
    color: black;
  }
  /*  */

  p {
    font-size: 13px;
    color: #7c7c7c;
    margin-bottom: 0px;
  }
  .mr-25 {
    margin-right: 25px;
  }
  .mr-30 {
    margin-right: 33px;
  }
  .mr-32 {
    margin-right: 40px;
  }
  &:last-child {
    /* border-right: none; */
  }
  /* &:last-of-type:not() {
    border-right: none;
  } */
`;
