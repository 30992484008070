import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Row,
  Input,
} from 'reactstrap'

import React from 'react'
import styled from 'styled-components'
// import PropTypes from 'prop-types'

const ModalView = (props) => {
  const { title, modal, value, onCloseModal, onChange, onSubmit, Error, Type } =
    props
  return (
    <div>
      <Modal isOpen={modal} toggle={onCloseModal}>
        <ModalHeaderCustoms toggle={onCloseModal}>{title}</ModalHeaderCustoms>
        <ModalBody>
          <Row>
            <Col md='12'>
              <Label htmlFor='name' className='mb-1'>
                Name
              </Label>
            </Col>
            <Col md='12'>
              <InputCustoms
                type={Type}
                value={value}
                id='name'
                rows="4"
                onChange={(e) => onChange(e)}
              />
              <DangerText className='text-danger'>
                <em>{Error}</em>
              </DangerText>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onSubmit} submit={true}>
            Update
          </Button>
          <Button onClick={onCloseModal}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

ModalView.propTypes = {}

export default ModalView

const ModalHeaderCustoms = styled(ModalHeader)`
  .modal-title {
    font-family: ${(props) => props.theme.SeraiFont};
  }
  button {
    border: none;
    background: transparent;
  }
  span {
    font-size: 20px;
  }
`

const Button = styled.button`
  border: ${(props) =>
    props.submit ? 'none' : `1px solid ${props.theme.primaryColor}`};
  background-color: ${(props) =>
    props.submit ? props.theme.primaryColor : '#fff'};
  font-size: 14px;
  border-radius: 4px;
  font-family: ${(props) => props.theme.SeraiFont};
  padding: 5px 6px;
  /* color: #ffff; */
  color: ${(props) =>
    props.submit ? '#ffff' : ` ${props.theme.primaryColor}`};
`

// const ModalBodyCustoms = styled(ModalBody)`
//   label: {
//     font-family: ${(props) => props.theme.SeraiFont};
//   }
// `
const Label = styled.label`
  font-family: ${(props) => props.theme.SeraiFont};
`
const InputCustoms = styled(Input)`
  font-family: ${(props) => props.theme.SeraiFont};
`

const DangerText = styled.div`
  em {
    font-family: ${(props) => props.theme.SeraiFont};
    font-size: 14px;
  }
`
