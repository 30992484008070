import React from "react";
import { Route, Switch } from "react-router-dom";
import router from "../../api/router";
import DefaultFooter from "../DefaultFooter/DefaultFooter";

const pdfLayout = (props) => {
  return (
    <React.Fragment>
      <Switch>
        {router.map((route, idx) => {
          return (
            route.component && (
              <Route
                key={idx}
                path={route.path}
                exact={route.exact}
                name={route.name}
                render={(props) => <route.component {...props} />}
              />
            )
          );
        })}
      </Switch>
    </React.Fragment>
  );
};

export default pdfLayout;
