import React, { Fragment, useState } from "react";
// import PropTypes from 'prop-types'
import _ from "lodash";
import swal from "sweetalert";
import { toast } from "react-toastify";
import { v4 as uuidv4, v4 } from "uuid";
import { connect, useSelector } from "react-redux";
// import { earlyPrimaryPdfGenerate } from '../../../action/primaryAction'
import Funsheet from "../Commons/Funsheet/defaultFunsheet";
import ModalView from "../Commons/Modal/Modal";

import Card from "./Card";
import { generateWholeNumberPdf } from "../../action/primaryMathsAction";
const PrimaryStudent = (props) => {
  const MathList = useSelector(
    (state) => state.primaryMaths.primaryMathAddition
  );

  const [studentList, setStudentList] = useState([]);
  const [commentList, setCommentList] = useState([]);
  const [studentInput, setStudentInput] = useState(String);
  const [commentInput, setCommentInput] = useState(String);
  const [comments, setComments] = useState([]);
  const [commentSelected, setCommentSelected] = useState(null);
  const [commentError, setCommentError] = useState("");
  const [studentError, setStudentError] = useState("");
  const [loginLoading, setLoginLoading] = useState(false);
  //
  const [showModal, setShowModal] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [name, setName] = useState("");
  const [error, setError] = useState("");
  const [type, setType] = useState(1);
  const onSubmit = (e) => {
    e.preventDefault();
    let validationFlag = true;
    let students = studentInput.trim();

    if (!students.length) {
      validationFlag = false;
      setStudentError("Student field is required");
    }
    if (validationFlag) {
      setStudentError("");

      if (students.match(/\n/g)) {
        let studentArrayData = students.split("\n");
        let studentMap = studentArrayData.map((data) => {
          return {
            id: v4(),
            message: data.trim(),
          };
        });

        // :use -> contact two array
        let finalData = [...studentList, ...studentMap];

        setStudentList(finalData);
      } else {
        let studentArrayData = students.split(",");
        let studentMap = studentArrayData.map((data) => {
          return {
            id: v4(),
            message: data,
          };
        });
        let finalData = [...studentList, ...studentMap];
        setStudentList(finalData);
      }
      setStudentInput("");
    }
  };
  const studentClear = () => {
    setStudentList([]);
  };
  const studentDelete = (data, i) => {
    swal({
      title: "Are you sure?",
      text: `Are you sure that you want to delete ${data.message}?`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        toast.success("Student has been deleted successfully");
        setStudentList(studentList.filter((s) => s.id !== i));
      }
    });
  };
  const onKeyPress = async (e) => {
    if (e.key === "Enter") {
      let students = studentInput.trim();
      let validationFlag = true;
      if (!students.length > 0) {
        validationFlag = false;
        setStudentError("Student field is required");
      }
      if (validationFlag) {
        setStudentError("");

        if (students.match(/\n/g)) {
          let studentArrayData = students.split("\n");
          let studentMap = await studentArrayData.map((data) => {
            return {
              id: v4(),
              message: data,
            };
          });
          // :use -> contact two array
          let finalData = [...studentList, ...studentMap];

          setStudentList(finalData);
        } else {
          let studentArrayData = students.split(",");
          let studentMap = studentArrayData.map((data) => {
            return {
              id: v4(),
              message: data,
            };
          });
          let finalData = [...studentList, ...studentMap];

          setStudentList(finalData);
        }
      }
      setTimeout(() => {
        setStudentInput("");
      }, 1);
      // setStudentInput('')
    }
  };
  const onStudentPaste = (e) => {
    let studentData = e.clipboardData.getData("Text");
    let students = studentData.trim();
    if (students.match(/\n/g)) {
      let studentArrayData = students.split("\n");
      let studentMap = studentArrayData.map((data) => {
        return {
          id: v4(),
          message: data.trim(),
        };
      });

      // :use -> contact two array
      let finalData = [...studentList, ...studentMap];

      setStudentList(finalData);
    } else {
      let studentArrayData = students.split(",");
      let studentMap = studentArrayData.map((data) => {
        return {
          id: v4(),
          message: data,
        };
      });
      let finalData = [...studentList, ...studentMap];

      setStudentList(finalData);
    }
    setTimeout(() => {
      setStudentInput("");
    }, 1);
  };
  //
  const onSubmitComment = (e) => {
    e.preventDefault();
    let validationFlag = true;
    let showAlertMessage = false;
    if (!commentInput.length) {
      validationFlag = false;
      setCommentError("Message field is required");
    }
    if (validationFlag) {
      setCommentError("");
      let comments = commentInput.trim();
      let commentArray = comments.split("\n");

      let newData = commentArray.map((data) => {
        if (data.length > 130) {
          showAlertMessage = true;
          showErrorMessage();
        }
        return {
          id: uuidv4(),
          message: data,
          discard: true,
        };
      });

      let finalData = [...commentList, ...newData];
      setCommentList(finalData);
      setTimeout(() => {
        setCommentInput("");
      }, 1);

      if (showAlertMessage) {
        showErrorMessage();
      }
    }
  };

  const DeleteComment = (data, i) => {
    swal({
      title: "Are you sure?",
      text: `Are you sure that you want to delete comment?`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        toast.success("Comment has been deleted successfully");
        setCommentList(commentList.filter((s) => s.id !== i));
      }
    });
  };
  const onCommentPaste = (e) => {
    let studentData = e.clipboardData.getData("Text");

    let showAlertMessage = false;
    let comments = studentData.trim();
    let commentArray = comments.split("\n");

    let newData = commentArray.map((data) => {
      if (data.length > 130) {
        showAlertMessage = true;
        showErrorMessage();
      }
      return {
        id: uuidv4(),
        message: data,
        discard: true,
      };
    });

    let finalData = [...commentList, ...newData];
    setCommentList(finalData);

    setTimeout(() => {
      setCommentInput("");
    }, 1);
    if (showAlertMessage) {
      showErrorMessage();
    }
  };
  const commentClear = () => {
    setCommentList([]);
    setCommentSelected(null);
  };

  const onKeyPressComment = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      let validationFlag = true;
      let showAlertMessage = false;
      if (!commentInput.length) {
        validationFlag = false;
        setCommentError("Message field is required");
      }
      if (validationFlag) {
        setCommentError("");
        let comments = commentInput.trim();
        let commentArray = comments.split("\n");

        let newData = commentArray.map((data) => {
          if (data.length > 130) {
            // setShowAlert(true)
            showErrorMessage();
            showAlertMessage = true;
          }
          return {
            id: uuidv4(),
            message: data,
            discard: true,
          };
        });

        let finalData = [...commentList, ...newData];
        setCommentList(finalData);
        setTimeout(() => {
          setCommentInput("");
        }, 1);
        if (showAlertMessage) {
          showErrorMessage();
        }
      }
    }
  };
  const DiscardSpace = (data, i) => {
    // set comment space data
    let commentDataExist = comments.find((e) => e.id === data.id);

    if (!commentDataExist) {
      let commentObj = {
        id: data.id,
        comment: data.message,
      };
      setComments([...comments, commentObj]);
    }
    // remove comment space
    let finalData = [...commentList];
    let data1 = finalData[i].message.split(/\s/).join("");
    finalData[i].message = data1;
    finalData[i].discard = false;
    setCommentList(finalData);
  };
  const KeepSpace = (data, i) => {
    let finalData = [...commentList];
    for (let j = 0; j < comments.length; j++) {
      const element = comments[j];
      if (element.id === data.id) {
        finalData[i].message = element.comment;
        finalData[i].discard = true;
      }
    }

    setCommentList(finalData);
  };

  const onListClick = (i) => {
    if (commentSelected === i) {
      setCommentSelected(null);
    } else if (!commentSelected || commentSelected !== i) {
      setCommentSelected(i);
    }
  };

  const showErrorMessage = () => {
    let message = "";

    swal({
      text: "Some of the message is longer than allowed limit, please check all messages",
    });
    return message;
  };

  // on Submit Click
  const onPDFGenerate = () => {
    let validationFlag = true;
    if (MathList.length < 6) {
      validationFlag = false;
      toast.success("Please select six operation in whole number");
    }
    if (!studentList.length) {
      validationFlag = false;
      toast.success("Please insert students");
    } else if (!commentList.length) {
      validationFlag = false;
      toast.success("Please insert comments");
    }

    // let newObj = {};
    // newObj.student = MathList?.studentList?.map((data) => {
    //   return data.message;
    // });
    // newObj.messages = MathList?.commentList?.map((data) => {
    //   if (data.message.length > 130) {
    //     showErrorMessage();
    //     validationFlag = false;
    //   } else {
    //     return data.message;
    //   }
    // });
    // newObj.studentFunsheet = MathList?.map((type) => {
    //   return type.type;
    // });
    if (validationFlag) {
      let newObj = {
        student: studentList.map((data) => {
          return data.message
        }),
        messages: commentList.map((data) => {
          if (data.message.length > 130) {
            showErrorMessage();
            validationFlag = false;
          } else {
            return data.message;
          }
        }),
        studentFunsheet: MathList.map((type) => {
          return type.type
        }),
      }
      
      console.log("🚀 validationFlag", validationFlag)
      if (validationFlag===true) {
        setLoginLoading(true);
        props.generateWholeNumberPdf(newObj).then((res) => {
          setLoginLoading(false);
        });
      }
    }
  };
  const onEdit = (data) => {
    setName(data.message);
    setSelectedId(data.id);
    setShowModal(true);
    setType(1);
  };
  const onMessageEdit = (data) => {
    setName(data.message);
    setSelectedId(data.id);
    setShowModal(true);
    setType(2);
  };
  const onCloseModal = () => {
    setShowModal(false);
  };
  const onChange = (e) => {
    setName(e.target.value);
  };
  const StudentModalSubmit = (e) => {
    if (type === 1) {
      let validationFlag = true;
      if (!name.length || name === null || name === undefined) {
        setError("Name field is required");
        validationFlag = false;
      } else {
        setError("");
      }
      if (validationFlag) {
        const GetIndex = _.findIndex(studentList, function (o) {
          return o.id === selectedId;
        });
        const values = [...studentList];
        values[GetIndex].message = name;
        setStudentList(values);
        setShowModal(false);
        toast.success("Student has been successfully updated");
      }
    } else {
      let validationFlag = true;
      if (!name.length || name === null || name === undefined) {
        setError("Message field is required");
        validationFlag = false;
      } else {
        setError("");
      }
      if (validationFlag) {
        const GetIndex = _.findIndex(commentList, function (o) {
          return o.id === selectedId;
        });
        const values = [...commentList];
        values[GetIndex].message = name;
        setCommentList(values);
        setShowModal(false);
        toast.success("Message has been successfully updated");
      }
    }
  };

  return (
    <>
      <Funsheet
        studentClear={studentClear}
        studentList={studentList}
        onSubmit={onSubmit}
        setStudentInput={setStudentInput}
        studentInput={studentInput}
        studentDelete={studentDelete}
        onListClick={onListClick}
        commentSelected={commentSelected}
        onKeyPress={onKeyPress}
        studentError={studentError}
        onStudentPaste={onStudentPaste}
        commentClear={commentClear}
        commentList={commentList}
        commentInput={commentInput}
        onSubmitComment={onSubmitComment}
        setCommentInput={setCommentInput}
        DeleteComment={DeleteComment}
        DiscardSpace={DiscardSpace}
        KeepSpace={KeepSpace}
        onKeyPressComment={onKeyPressComment}
        commentError={commentError}
        onCommentPaste={onCommentPaste}
        onPDFGenerate={onPDFGenerate}
        onEdit={onEdit}
        onMessageEdit={onMessageEdit}
        loginLoading={loginLoading}
      >
        <Card />
      </Funsheet>
      {type === 1 ? (
        <ModalView
          modal={showModal}
          onCloseModal={onCloseModal}
          value={name}
          title="Edit Student"
          onChange={onChange}
          Type="text"
          onSubmit={StudentModalSubmit}
          Error={error}
        />
      ) : (
        <ModalView
          modal={showModal}
          onCloseModal={onCloseModal}
          value={name}
          title="Edit message"
          onChange={onChange}
          Type="textarea"
          onSubmit={StudentModalSubmit}
          Error={error}
        />
      )}
    </>
  );
};

export default connect(null, { generateWholeNumberPdf })(PrimaryStudent);
