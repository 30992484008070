const theme = {
  // primaryColor: '#f48b08',
  primaryColor: '#00b050',

  buttonActive: '#f78f0e80',
  buttonHover: '#00b050',
  buttonText: '#fff',
  textError: '#ff0000d9',
  //
  primaryHeading: {
    'font-family': 'Roboto',
    'font-weight': '500',
    'font-size': '24px',
  },
  subHeading: {
    'font-size': '16px',
    color: '#848484',
    'font-weight': '400',
    'font-family': 'Roboto',
  },
  heading1FontSize: '24px',
  heading1FontWeight: '500',
  SeraiFont: 'Saira',
  RobotoFont: 'Roboto',
}
export default theme
