import React, { Fragment, useState } from "react";
// import PropTypes from 'prop-types'
import swal from "sweetalert";
import { toast } from "react-toastify";
import { v4 as uuidv4, v4 } from "uuid";
import { connect, useDispatch, useSelector } from "react-redux";
import { earlyPrimaryPdfGenerate } from "../../../action/primaryAction";
import Funsheet from "../../Commons/Funsheet/defaultFunsheet";
import ModalView from "../../Commons/Modal/Modal";
import { threePartStringFitMessage } from "../../../Helper";

const StudentPrimary = (props) => {
  const MathList = useSelector((state) => state.primaryMath);
  const dispatch = useDispatch();
  const [studentList, setStudentList] = useState([]);

  const [studentInput, setStudentInput] = useState(String);
  const [commentInput, setCommentInput] = useState(String);
  const [comments, setComments] = useState([]);
  const [commentSelected, setCommentSelected] = useState(null);
  const [commentError, setCommentError] = useState("");
  const [studentError, setStudentError] = useState("");

  //
  const [showModal, setShowModal] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [name, setName] = useState("");
  const [error, setError] = useState("");
  const [type, setType] = useState(1);
  const [loginLoading, setLoginLoading] = useState(false);
  //
  const onSubmit = (e) => {
    // console.log(true);
    e.preventDefault();
    let validationFlag = true;
    let students = studentInput.trim();

    if (!students.length) {
      validationFlag = false;
      setStudentError("Student field is required");
    }
    if (validationFlag) {
      setStudentError("");

      if (students.match(/\n/g)) {
        let studentArrayData = students.split("\n");
        let studentMap = studentArrayData.map((data) => {
          return {
            id: v4(),
            message: data.trim(),
          };
        });

        // :use -> contact two array

        // let finalData = [...studentList, ...studentMap]
        dispatch({
          type: "EARLY_PRIMARY_STUDENT_ADD",
          payload: studentMap,
        });
        // setStudentList(finalData)
      } else {
        let studentArrayData = students.split(",");
        let studentMap = studentArrayData.map((data) => {
          return {
            id: v4(),
            message: data.trim(),
          };
        });
        // let finalData = [...studentList, ...studentMap]
        dispatch({
          type: "EARLY_PRIMARY_STUDENT_ADD",
          payload: studentMap,
        });
        // setStudentList(finalData)
      }
      setStudentInput("");
    }
  };
  const studentClear = () => {
    setStudentList([]);
    swal({
      title: "Are you sure?",
      text: `Are you sure that you want to delete all students?`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        toast.success("Students has been deleted successfully");
        dispatch({
          type: "EARLY_PRIMARY_STUDENT_REMOVE_All",
        });
      }
    });
  };
  const studentDelete = (data, i) => {
    swal({
      title: "Are you sure?",
      text: `Are you sure that you want to delete ${data.message}?`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        toast.success("Student has been deleted successfully");
        dispatch({
          type: "EARLY_PRIMARY_STUDENT_REMOVE",
          payload: data.id,
        });
      }
    });
  };
  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      let students = studentInput.trim();
      let validationFlag = true;
      if (!students.length > 0) {
        validationFlag = false;
        setStudentError("Student field is required");
      }
      if (validationFlag) {
        setStudentError("");

        if (students.match(/\n/g)) {
          let studentArrayData = students.split("\n");
          let studentMap = studentArrayData.map((data) => {
            return {
              id: v4(),
              message: data.trim(),
            };
          });

          // :use -> contact two array
          // let finalData = [...studentList, ...studentMap]
          if (studentMap.length) {
            dispatch({
              type: "EARLY_PRIMARY_STUDENT_ADD",
              payload: studentMap,
            });
          }
          // setStudentList(finalData)
        } else {
          let studentArrayData = students.split(",");
          let studentMap = studentArrayData.map((data) => {
            return {
              id: v4(),
              message: data.trim(),
            };
          });

          // let finalData = [...studentList, ...studentMap]
          if (studentMap.length) {
            dispatch({
              type: "EARLY_PRIMARY_STUDENT_ADD",
              payload: studentMap,
            });
          }

          // setStudentList(finalData)
        }
      }
      setTimeout(() => {
        setStudentInput("");
      }, 1);
      // setStudentInput('')
    }
  };
  const onStudentPaste = (e) => {
    let studentData = e.clipboardData.getData("Text");
    let students = studentData.trim();
    if (students.match(/\n/g)) {
      let studentArrayData = students.split("\n");
      let studentMap = studentArrayData.map((data) => {
        return {
          id: v4(),
          message: data.trim(),
        };
      });

      // :use -> contact two array
      // let finalData = [...studentList, ...studentMap]
      dispatch({
        type: "EARLY_PRIMARY_STUDENT_ADD",
        payload: studentMap,
      });
      // setStudentList(finalData)
    } else {
      let studentArrayData = students.split(",");
      let studentMap = studentArrayData.map((data) => {
        return {
          id: v4(),
          message: data.trim(),
        };
      });
      // let finalData = [...studentList, ...studentMap]
      dispatch({
        type: "EARLY_PRIMARY_STUDENT_ADD",
        payload: studentMap,
      });
      // setStudentList(finalData)
    }
    setTimeout(() => {
      setStudentInput("");
    }, 1);
  };
  //
  const onSubmitComment = (e) => {
    e.preventDefault();
    let validationFlag = true;
    let showAlertMessage = false;
    if (!commentInput.length) {
      validationFlag = false;
      setCommentError("Message field is required");
    }
    if (validationFlag) {
      setCommentError("");
      let comments = commentInput.trim();
      let commentArray = comments.split("\n");

      let newData = commentArray.map((data) => {
        if (data.length > 50) {
          toast.error(
            "Some of the message is longer than allowed limit, please check all messages"
          );
          showAlertMessage = true;
          return {
            id: uuidv4(),
            message: data.trim(),
            discard: true,
          };
        }
      });

      // let finalData = [...commentList, ...newData]
      if (newData.length) {
        dispatch({
          type: "EARLY_PRIMARY_MESSAGE_ADD",
          payload: newData,
        });
      }
      // setCommentList(finalData)
      setTimeout(() => {
        setCommentInput("");
      }, 1);

      if (showAlertMessage) {
        showErrorMessage();
      }
    }
  };

  const DeleteComment = (data, i) => {
    swal({
      title: "Are you sure?",
      text: `Are you sure that you want to delete comment?`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch({
          type: "EARLY_PRIMARY_MESSAGE_REMOVE",
          payload: data.id,
        });
        toast.success("Comment has been deleted successfully");
      }
    });
  };
  const onCommentPaste = (e) => {
    let studentData = e.clipboardData.getData("Text");

    let showAlertMessage = false;
    let comments = studentData.trim();

    // console.log("🚀 comments", comments);
    let commentArray = comments.split("\n");

    let newData = commentArray.map((data) => {
      if (data.length > 50) {
        toast.error(
          "Some of the message is longer than allowed limit, please check all messages"
        );
        showAlertMessage = true;
      }
      return {
        id: uuidv4(),
        message: data.trim(),
        discard: true,
      };
    });

    // let finalData = [...commentList, ...newData]
    if (newData.length) {
      dispatch({
        type: "EARLY_PRIMARY_MESSAGE_ADD",
        payload: newData,
      });
    }
    // setCommentList(finalData)

    setTimeout(() => {
      setCommentInput("");
    }, 1);
    if (showAlertMessage) {
      showErrorMessage();
    }
  };
  const commentClear = () => {
    // setCommentList([])
    // setStudentList([])
    swal({
      title: "Are you sure?",
      text: `Are you sure that you want to delete all comments?`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setCommentSelected(null);
        toast.success("Comments has been deleted successfully");
        dispatch({
          type: "EARLY_PRIMARY_MESSAGE_REMOVE_All",
        });
      }
    });
  };

  const onKeyPressComment = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      let validationFlag = true;
      let showAlertMessage = false;
      if (!commentInput.length) {
        validationFlag = false;
        setCommentError("Message field is required");
      }
      if (validationFlag) {
        setCommentError("");
        let comments = commentInput.trim();
        let commentArray = comments.split("\n");

        let newData = commentArray.map((data) => {
          if (data.length > 50) {
            // setShowAlert(true)
            toast.error(
              "Some of the message is longer than allowed limit, please check all messages"
            );
            showAlertMessage = true;
          }
          return {
            id: uuidv4(),
            message: data.trim(),
            discard: true,
          };
          // EARLY_PRIMARY_MESSAGE_ADD
        });

        // let finalData = [...commentList, ...newData]
        // setCommentList(finalData)
        if (newData.length) {
          dispatch({
            type: "EARLY_PRIMARY_MESSAGE_ADD",
            payload: newData,
          });
        }
        setTimeout(() => {
          setCommentInput("");
        }, 1);
        if (showAlertMessage) {
          showErrorMessage();
        }
      }
    }
  };
  const DiscardSpace = (data, i) => {
    // set comment space data
    let commentDataExist = comments.find((e) => e.id === data.id);

    if (!commentDataExist) {
      // console.log(true);
      let commentObj = {
        id: data.id,
        comment: data.message,
      };
      // console.log("🚀 ~ DiscardSpace ~ commentObj", commentObj);
      setComments([...comments, commentObj]);

      // setComments()
    }
    // remove comment space
    let finalData = [...MathList.commentList];
    let data1 = finalData[i].message.split(/\s/).join("");
    finalData[i].message = data1;
    finalData[i].discard = false;
    dispatch({
      type: "EARLY_PRIMARY_MESSAGE_DISCARD",
      payload: finalData,
    });
    // setCommentList(finalData)
  };
  const KeepSpace = (data, i) => {
    let finalData = [...MathList.commentList];

    for (let j = 0; j < comments.length; j++) {
      const element = comments[j];

      if (element.id === data.id) {
        finalData[i].message = element.comment;
        finalData[i].discard = true;
      }
    }
    dispatch({
      type: "EARLY_PRIMARY_MESSAGE_DISCARD",
      payload: finalData,
    });
    // setCommentList(finalData)
  };

  const onListClick = (i) => {
    if (commentSelected === i) {
      setCommentSelected(null);
    } else if (!commentSelected || commentSelected !== i) {
      setCommentSelected(i);
    }
  };

  const showErrorMessage = () => {
    let message = "";

    swal({
      text: "Some of the message is longer than allowed limit, please check all messages",
      // text: "Please ensure this message only appears when the allowed limit is exceeded",
    });
    return message;
  };

  /* Using For Message Validation purpose */
  /* Fit Messages Validation */
  const FitMessageFunction = (data) => {
    var newDString;
    let String1, String2, String3;
    if (/\s/g.test(data)) {
      let StringData = threePartStringFitMessage(data.trimStart());
      String1 = StringData.String1 !== "" ? StringData.String1 : "";
      String2 = StringData.String2 !== "" ? StringData.String2 : "";
      String3 = StringData.String3 !== "" ? StringData.String3 : "";

      newDString = `${String1} ${String2} ${String3}`;
      newDString = newDString.trim();
    }
    return newDString;
  };

  // on Submit Click
  const onPDFGenerate = () => {
    //
    let validation = true;
    let dataArray = [];

    dataArray.push(
      { make_to_add_10: MathList.add_to_make_10 },
      {
        make_to_add: MathList.add_to_make || MathList.rand_add_to_make,
      },
      {
        add: MathList.add_value || MathList.rand_add_value,
      },
      {
        subtract: MathList.subtract_value || MathList.rand_subtract_value,
      },
      {
        subtract_from_10:
          MathList.subtractFromTEN_value || MathList.rand_subtractFromTEN_value,
      },
      {
        subtract_from:
          MathList.subtractFrom_value || MathList.rand_subtractFrom_value,
      },
      {
        multiply: MathList.multiple_value || MathList.rand_multiple_value,
      },
      {
        divide: MathList.divided_value || MathList.rand_divided_value,
      },
      {
        continue_adding_manually: {
          continue_adding_manually:
            MathList.continue_add === [] ? null : MathList.continue_add,
          CNumber: MathList.continue_add_value,
          SNumber: MathList.starting_add_value,
          msg: MathList.msg,
        },
      },
      {
        continue_add_on_multiple: {
          continue_add_on_multiple: MathList.on_multiple_data,
          CNumber: MathList.continue_on_multiple,
          SNumber: MathList.starting_on_multiple,
          msg: MathList.on_multiple_msg,
        },
      },
      {
        continue_add_off_multiple: {
          continue_add_off_multiple: MathList.off_multiple_data,
          CNumber: MathList.continue_off_multiple,
          SNumber: MathList.starting_off_multiple,
          msg: MathList.off_multiple_msg,
        },
      },
      // {
      //   continue_subtract_manually: {
      //     continue_subtract_manually: MathList.continue_sub,
      //     CNumber: MathList.continue_sub_value,
      //     SNumber: MathList.starting_sub_value,
      //     msg: MathList.continue_sub_msg,
      //   },
      // },
      {
        subtract_on_multiple: {
          subtract_on_multiple: MathList.sub_on_multiple_data,
          CNumber: MathList.sub_continue_on_multiple,
          SNumber: MathList.sub_starting_on_multiple,
          msg: MathList.sub_on_multiple_msg,
        },
      },
      {
        subtract_off_multiple: {
          subtract_off_multiple: MathList.sub_off_multiple_data,
          CNumber: MathList.sub_continue_off_multiple,
          SNumber: MathList.sub_starting_off_multiple,
          msg: MathList.sub_off_multiple_msg,
        },
      }
    );

    // console.log("dataArray", dataArray);
    let newObj = {};
    newObj.student = MathList.studentList.map((data) => {
      return data.message;
    });
    newObj.messages = MathList.commentList.map((data) => {
      let FitCheckMessage = FitMessageFunction(data.message);

      let MessageFit =
        FitCheckMessage?.length === undefined
          ? false
          : FitCheckMessage?.length !== data.message.length;
      if (data.message.length > 50) {
        // toast.error("Some of the message is longer than allowed limit, please check all messages");
        validation = false;
        showErrorMessage();
      } else if (MessageFit) {
        validation = false;
        showErrorMessage();
      } else {
        // console.log(data)
        return data.message;
      }
    });

    if (MathList.maxLength.length < 3) {
      validation = false;
      toast.error("Please select three operation");
    } else if (
      MathList.studentList.length === 0 ||
      MathList.commentList.length === 0
    ) {
      validation = false;
      toast.error(
        "Please add at least one student and message to generate PDF"
      );
    } else if (MathList.studentList.length !== MathList.commentList.length) {
      validation = false;
      toast.error("Name and messages both should be same ");
    }

    if (validation) {
      newObj.studentFunsheet = dataArray;
      // console.log("🚀newObj", newObj);
      setLoginLoading(true);

      props.earlyPrimaryPdfGenerate(newObj).then((res) => {
        setLoginLoading(false);
      });
    }
  };

  const onEdit = (data) => {
    setName(data.message);
    setSelectedId(data.id);
    setShowModal(true);
    setType(1);
  };

  const onMessageEdit = (data) => {
    setName(data.message);
    setSelectedId(data.id);
    setShowModal(true);
    setType(2);
  };

  const onCloseModal = () => {
    setShowModal(false);
  };

  const onChange = (e) => {
    setName(e.target.value);
  };

  const StudentModalSubmit = (e) => {
    if (type === 1) {
      let validationFlag = true;
      if (!name.length || name === null || name === undefined) {
        setError("Name field is required");
        validationFlag = false;
      } else {
        setError("");
      }
      if (validationFlag) {
        // const GetIndex = _.findIndex(studentList, function (o) {
        //   return o.id === selectedId
        // })
        // const values = [...studentList]
        // values[GetIndex].message = name
        // setStudentList(values)
        dispatch({
          type: "EARLY_PRIMARY_STUDENT_UPDATE",
          payload: {
            id: selectedId,
            data: name,
          },
        });
        // console.log("🚀selectedId", selectedId)

        setShowModal(false);
        toast.success("Student has been successfully updated");
      }
    } else {
      let validationFlag = true;
      if (!name.length || name === null || name === undefined) {
        setError("Message field is required");
        validationFlag = false;
      } else {
        setError("");
      }
      if (validationFlag) {
        dispatch({
          type: "EARLY_PRIMARY_MESSAGE_UPDATE",
          payload: {
            id: selectedId,
            data: name.trim(),
            discard: true,
          },
        });

        setShowModal(false);
        toast.success("Message has been successfully updated");
      }
    }
  };

  return (
    <>
      <Funsheet
        studentClear={studentClear}
        studentList={MathList.studentList}
        onSubmit={onSubmit}
        setStudentInput={setStudentInput}
        studentInput={studentInput}
        studentDelete={studentDelete}
        onListClick={onListClick}
        commentSelected={commentSelected}
        onKeyPress={onKeyPress}
        studentError={studentError}
        onStudentPaste={onStudentPaste}
        commentClear={commentClear}
        commentList={MathList.commentList}
        commentInput={commentInput}
        onSubmitComment={onSubmitComment}
        setCommentInput={setCommentInput}
        DeleteComment={DeleteComment}
        DiscardSpace={DiscardSpace}
        KeepSpace={KeepSpace}
        onKeyPressComment={onKeyPressComment}
        commentError={commentError}
        onCommentPaste={onCommentPaste}
        onPDFGenerate={onPDFGenerate}
        onEdit={onEdit}
        onMessageEdit={onMessageEdit}
        loginLoading={loginLoading}
      />
      {type === 1 ? (
        <ModalView
          modal={showModal}
          onCloseModal={onCloseModal}
          value={name}
          title="Edit Student"
          onChange={onChange}
          Type="text"
          onSubmit={StudentModalSubmit}
          Error={error}
        />
      ) : (
        <ModalView
          modal={showModal}
          onCloseModal={onCloseModal}
          value={name}
          title="Edit message"
          onChange={onChange}
          Type="textarea"
          onSubmit={StudentModalSubmit}
          Error={error}
        />
      )}
    </>
  );
};

export default connect(null, { earlyPrimaryPdfGenerate })(StudentPrimary);
