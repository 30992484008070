import axios from "axios";
import { toast } from "react-toastify";
const PROXY = process.env.REACT_APP_URL + "api/";
//
const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

export const primaryMathslist =
  (number, type = 1, rand = false) =>
  async (dispach) => {
    try {
      const res = await axios.get(
        `${PROXY}primary/maths?number=${number}&type=${type}`
      );
      if (!rand) {
        let data = {
          data: res.data.result,
          value: number,
          randValue: null,
        };
        if (type === 1) {
          dispach({
            type: "PRIMARY_MATHS_LIST",
            payload: data,
          });
        } else {
          dispach({
            type: "ADD_TO_MARK_LIST",
            payload: data,
          });
        }
      } else {
        let data = {
          data: res.data.result,
          randValue: number,
          value: null,
        };
        if (type === 1) {
          dispach({
            type: "PRIMARY_MATHS_LIST",
            payload: data,
          });
        } else {
          dispach({
            type: "ADD_TO_MARK_LIST",
            payload: data,
          });
        }
      }
    } catch (err) {
      toast.error(err.response.data.message);
      // console.log(err.response)
    }
  };

export const getContinueadditionList =
  (Cnumber, Snumber, rand = false) =>
  async (dispach) => {
    try {
      const res = await axios.get(
        `${PROXY}primary/continue-adding?Cnumber=${Cnumber}&Snumber=${Snumber}`
      );
      let data = {
        data: res.data.result.result,
        value: Cnumber,
        value2: Snumber,
        msg: res.data.result.msg,
      };
      dispach({
        type: "ADD_CONTINUE",
        payload: data,
      });
    } catch (err) {
      console.log(err);
      toast.error(err.response.data.message);
    }
  };
export const getOnMultipleList =
  (Cnumber, Snumber, rand = false) =>
  async (dispach) => {
    try {
      const res = await axios.get(
        `${PROXY}primary/continue-adding/on-multiple?Cnumber=${Cnumber}&Snumber=${Snumber}`
      );
      let data = {
        data: res.data.result.result,
        value: Cnumber,
        value2: Snumber,
        msg: res.data.result.msg,
      };
      dispach({
        type: "ADD_ON_MULTIPLE",
        payload: data,
      });
    } catch (err) {
      console.log(err);
      toast.error(err.response.data.message);
    }
  };
export const getOffMultipleList =
  (Cnumber, Snumber, rand = false) =>
  async (dispach) => {
    try {
      const res = await axios.get(
        `${PROXY}primary/continue-adding/off-multiple?Cnumber=${Cnumber}&Snumber=${Snumber}`
      );
      let data = {
        data: res.data.result.result,
        value: Cnumber,
        value2: Snumber,
        msg: res.data.result.msg,
      };
      dispach({
        type: "ADD_OFF_MULTIPLE",
        payload: data,
      });
    } catch (err) {
      console.log(err);
      toast.error(err.response.data.message);
    }
  };

export const getContinueSubtract =
  (Cnumber, Snumber, rand = false) =>
  async (dispach) => {
    try {
      const res = await axios.get(
        `${PROXY}primary/continue-subtract?Cnumber=${Cnumber}&Snumber=${Snumber}`
      );
      let data = {
        data: res.data.result.result,
        value: Cnumber,
        value2: Snumber,
        msg: res.data.result.msg,
      };
      dispach({
        type: "SUB_CONTINUE",
        payload: data,
      });
    } catch (err) {
      console.log(err);
      toast.error(err.response.data.message);
    }
  };

export const getOnMultipleListSubtract =
  (Cnumber, Snumber, rand = false) =>
  async (dispach) => {
    try {
      const res = await axios.get(
        `${PROXY}primary/continue-subtract/on-multiple?Cnumber=${Cnumber}&Snumber=${Snumber}`
      );
      let data = {
        data: res.data.result.result,
        value: Cnumber,
        value2: Snumber,
        msg: res.data.result.msg,
      };
      dispach({
        type: "SUB_ON_MULTIPLE",
        payload: data,
      });
    } catch (err) {
      console.log(err);
      toast.error(err.response.data.message);
    }
  };
export const getOffMultipleListSubtract =
  (Cnumber, Snumber, rand = false) =>
  async (dispach) => {
    try {
      const res = await axios.get(
        `${PROXY}primary/continue-subtract/off-multiple?Cnumber=${Cnumber}&Snumber=${Snumber}`
      );
      let data = {
        data: res.data.result.result,
        value: Cnumber,
        value2: Snumber,
        msg: res.data.result.msg,
      };
      dispach({
        type: "SUB_OFF_MULTIPLE",
        payload: data,
      });
    } catch (err) {
      console.log(err);
      toast.error(err.response.data.message);
    }
  };

export const getadditionList =
  (number, rand = false) =>
  async (dispach) => {
    try {
      const res = await axios.get(`${PROXY}primary/add?number=${number}`);
      if (!rand) {
        let data = {
          data: res.data.result,
          value: number,
          randValue: null,
        };
        dispach({
          type: "ADD_LIST",
          payload: data,
        });
      } else {
        let data = {
          data: res.data.result,
          value: null,
          randValue: number,
        };
        dispach({
          type: "ADD_LIST",
          payload: data,
        });
      }
    } catch (err) {
      console.log(err);
      toast.error(err.response.data.message);
    }
  };

export const getSubtractionList =
  (number, rand = false) =>
  async (dispach) => {
    try {
      const res = await axios.get(`${PROXY}primary/subtract?number=${number}`);
      if (!rand) {
        let data = {
          data: res.data.result,
          value: number,
          randValue: null,
        };
        dispach({
          type: "SUBTRACT_LIST",
          payload: data,
        });
      } else {
        let data = {
          data: res.data.result,
          value: null,
          randValue: number,
        };
        dispach({
          type: "SUBTRACT_LIST",
          payload: data,
        });
      }
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

export const getSubtractionFromList =
  (number, type = 2, rand = false) =>
  async (dispach) => {
    try {
      const res = await axios.get(
        `${PROXY}primary/subtract-from?number=${number}&type=${type}`
      );
      if (!rand) {
        let data = {
          value: number,
          data: res.data.result,
          randValue: null,
        };
        if (type === 1) {
          dispach({
            type: "SUBTRACT_FROM_10_LIST",
            payload: data,
          });
        } else {
          dispach({
            type: "SUBTRACT_FROM_LIST",
            payload: data,
          });
        }
      } else {
        let data = {
          value: null,
          data: res.data.result,
          randValue: number,
        };
        if (type === 1) {
          dispach({
            type: "SUBTRACT_FROM_10_LIST",
            payload: data,
          });
        } else {
          dispach({
            type: "SUBTRACT_FROM_LIST",
            payload: data,
          });
        }
      }
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

//
export const getMultiplyList =
  (number, rand = false) =>
  async (dispach) => {
    try {
      const res = await axios.get(`${PROXY}primary/multiple?number=${number}`);
      if (!rand) {
        let data = {
          value: number,
          data: res.data.result,
          randValue: null,
        };
        dispach({
          type: "MULTIPLE_LIST",
          payload: data,
        });
      } else {
        let data = {
          value: null,
          data: res.data.result,
          randValue: number,
        };
        dispach({
          type: "MULTIPLE_LIST",
          payload: data,
        });
      }
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

export const getDividedList =
  (number, rand = false) =>
  async (dispach) => {
    try {
      const res = await axios.get(`${PROXY}primary/divided?number=${number}`);
      if (!rand) {
        let data = {
          value: number,
          data: res.data.result,
          randValue: null,
        };
        dispach({
          type: "DIVIDED_LIST",
          payload: data,
        });
      } else {
        let data = {
          value: null,
          data: res.data.result,
          randValue: number,
        };
        dispach({
          type: "DIVIDED_LIST",
          payload: data,
        });
      }
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

export const earlyPrimaryPdfGenerate = (data) => async (dispach) => {
  const body = JSON.stringify(data);
  try {
    const res = await axios.post(`${PROXY}primary/pdf-generate`, body, config);
    toast.success(res.data.message);
    setTimeout(() => {
      const link = document.createElement("a");
      link.href = res.data.result;
      link.target = "_blank";
      link.setAttribute("download", res.data.result);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    }, 1500);
  } catch (err) {
    toast.error(err.response.data.message);
  }
};

//
export const dummyFunsheet = (data) => async (dispach) => {
  const body = JSON.stringify(data);
  const res = await axios.post(`${PROXY}primary/dummy`, body, config);
  return res;
};
