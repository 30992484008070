// Components
// const Home = React.lazy(() => import('../view/Home/Home'));
import Home from "../view/Home/Home";
//
import EarlyPrimary from "../view/EarlyPrimary/EarlyPrimary";
import StudentPrimary from "../view/EarlyPrimary/Student/StudentPrimary";
import PrimaryMaths from "../view/PrimaryMaths/PrimaryMaths";
import PrimaryMathFunsheet from "../view/PrimaryMaths/PrimaryMathFunsheet";
import SecondaryIntegersAndRationals from "../view/SecondaryIntegers/SecondaryIntegers";
import SecondaryLogarithms from "../view/SecondaryLogarithms/SecondaryLogarithms";
import SecondaryAlgebra from "../view/SecondaryAlgebra/SecondaryAlgebra";
import PrimaryMathWhole from "../view/PrimaryMaths/PrimaryMathWhole";
import PrimaryMathRational from "../view/PrimaryMaths/PrimaryMathRational";
import PrimaryStudent from "../view/PrimaryMaths/PrimaryStudent";
import PrimaryStudentRational from "../view/PrimaryMaths/PrimaryStudentRational";
import Pdf from "../view/PrimaryMaths/pdf";
import MessageCount from "../view/CountMessage/MessageCount";
import About from "../view/FooterComponent/Aboutus";
import EarlyPrimaryExample from "../view/FooterComponent/EarlyPrimaryExample";
import MultipleExample from "../view/FooterComponent/MultipleExample";
import PrimaryWholeExample from "../view/FooterComponent/PrimaryWholeExample";
import Contact from "../view/FooterComponent/Contact";
import SomeInstruction from "../view/FooterComponent/SomeInstruction";
// import Pdf from "../view/PrimaryMaths/PdfAnswerShow";

// import DummyComponent from '../view/DummyComponet'

//
const router = [
  { path: "/", exact: true, name: "Home", component: Home },
  {
    path: "/pdf",
    exact: true,
    name: "Pdf",
    component: Pdf,
  },
  {
    path: "/message",
    exact: true,
    name: "MessageCount",
    component: MessageCount,
  },
  {
    path: "/about-us",
    exact: true,
    name: "About",
    component: About,
  },
  {
    path: "/early-primary-example",
    exact: true,
    name: "EarlyPrimaryExample",
    component: EarlyPrimaryExample,
  },
  {
    path: "/100-language-example",
    exact: true,
    name: "MultipleExample",
    component: MultipleExample,
  }, 
  {
    path: "/some-instructions",
    exact: true,
    name: "SomeInstruction",
    component: SomeInstruction,
  },
  {
    path: "/contact",
    exact: true,
    name: "Contact",
    component: Contact,
  },
  {
    path: "/primary-example",
    exact: true,
    name: "PrimaryWholeExample",
    component: PrimaryWholeExample,
  },
  {
    path: "/early-primary",
    exact: true,
    name: "Early Primary",
    component: EarlyPrimary,
  },
  {
    path: "/early-primary/student",
    exact: true,
    name: "Primary Student",
    component: StudentPrimary,
  },
  {
    path: "/primary-maths-whole/student",
    exact: true,
    name: "Primary Student",
    component: PrimaryStudent,
  },
  {
    path: "/primary-maths/rational-number/student",
    exact: true,
    name: "Primary Student",
    component: PrimaryStudentRational,
  },
  {
    path: "/primary-maths",
    exact: true,
    name: "Primary Student",
    component: PrimaryMaths,
  },
  {
    path: "/primary-maths/whole-number",
    exact: true,
    name: "Primary Student",
    component: PrimaryMathWhole,
  },
  {
    path: "/primary-maths/rational-number",
    exact: true,
    name: "Primary Student",
    component: PrimaryMathRational,
  },
  {
    path: "/primary-maths/funsheet",
    exact: true,
    name: "Primary Student",
    component: PrimaryMathFunsheet,
  },
  {
    path: "/secondary/integers-rationals",
    exact: true,
    component: SecondaryIntegersAndRationals,
  },
  {
    path: "/secondary/rationals-ratio-surds",
    exact: true,
    component: SecondaryLogarithms,
  },
  {
    path: "/secondary/algebra-trigonometry",
    exact: true,
    component: SecondaryAlgebra,
  },
  // {
  //   path: '/early-primary/funsheet',
  //   exact: true,
  //   component: DummyComponent,
  // },
];

export default router;
