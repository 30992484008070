import React, { Fragment } from "react";
import styled from "styled-components";
import { Row, Col, Container, Card, CardBody } from "reactstrap";
// import StudentList from 'StudentCommonList'
import StudentList from "../../EarlyPrimary/Student/StudentCommonList";
import FillFunsheet from "../../EarlyPrimary/Student/FillFunsheet";
import FillFunsheetWhole from "../FillFunsheet/FillFunsheetWhole";

import StudentCard from "../../EarlyPrimary/Student/StudentCard";
import ToastMessage from "../../../DefaultComponets/ToastMessage/ToastMessage";
import { Link, useLocation } from "react-router-dom";
import LaddaButton, { ZOOM_OUT } from "react-ladda";

const DefaultFunsheet = (props) => {
  const { pathname } = useLocation();
  // console.log("🚀pathname", pathname);
  const {
    studentClear,
    studentList,
    onSubmit,
    setStudentInput,
    studentInput,
    studentDelete,
    onListClick,
    commentSelected,
    onKeyPress,
    studentError,
    onStudentPaste,
    commentClear,
    commentList,
    commentInput,
    onSubmitComment,
    setCommentInput,
    DeleteComment,
    DiscardSpace,
    KeepSpace,
    onKeyPressComment,
    commentError,
    onCommentPaste,
    onPDFGenerate,
    onEdit,
    onMessageEdit,
    loginLoading,
  } = props;
  // console.log("🚀loginLoading", loginLoading);

  return (
    <Container>
      <Fragment>
        <ToastMessage />
        <Row>
          <Col sm="12" lg="12" md="12" xs="12">
            <StudentHeading>
              Step3: Enter student names and individual messages for each student
            </StudentHeading>
            <SubHeading>
              Multiple names can be cut and pasted into the field below.
            </SubHeading>
            <SubHeading>
              Multiple messages can similarly be entered. Both can be edited
              on-screen if required.
            </SubHeading>
            {/* <SubHeading>
              <Link to="/message" style={{ textDecoration: "none" }}>
                Message String Calculate
              </Link>
            </SubHeading> */}
          </Col>
        </Row>
        <Row className="mt-5">
          <Col sm="6" lg="3" md="3" xs="6">
            <ClearBTN>
              <button onClick={studentClear}>Clear All</button>
            </ClearBTN>
            <StudentList
              list={studentList}
              Placeholder={"Enter student names"}
              onSubmitBTN={onSubmit}
              InputChange={(e) => setStudentInput(e.target.value)}
              InputValue={studentInput}
              DeleteBTN={studentDelete}
              onListClick={onListClick}
              selectedCAndS={commentSelected} // selected comment and student
              onKeyPress={onKeyPress}
              errorMessage={studentError}
              onPaste={onStudentPaste}
              onEdit={onEdit}
            />
          </Col>
          <Col sm="6" lg="3" md="3" xs="6">
            <ClearBTN>
              <button onClick={commentClear}>Clear All</button>
            </ClearBTN>
            <StudentList
              list={commentList}
              Placeholder={"Enter message"}
              InputValue={commentInput}
              onSubmitBTN={onSubmitComment}
              InputChange={(e) => setCommentInput(e.target.value)}
              DeleteBTN={DeleteComment}
              DiscardSpace={DiscardSpace}
              KeepSpace={KeepSpace}
              onListClick={onListClick}
              comment={true}
              selectedCAndS={commentSelected}
              onKeyPress={onKeyPressComment}
              errorMessage={commentError}
              onPaste={onCommentPaste}
              onEdit={onMessageEdit}
            />
          </Col>

          <Col sm="12" xl="6" lg="6" md="6" xs="12" className="mt-2">
            {/* {showErrorMessage(commentList)} */}
            {pathname === "/early-primary/student" ? (
              <FillFunsheet
                selectedComment={
                  commentSelected === 0 || commentSelected
                    ? commentList[commentSelected] &&
                      commentList[commentSelected].message
                    : null
                }
              />
            ) : (
              <FillFunsheetWhole
                selectedComment={
                  commentSelected === 0 || commentSelected
                    ? commentList[commentSelected] &&
                      commentList[commentSelected].message
                    : null
                }
              />
            )}

            {/* Message */}
            <DisplayPara>
              The numbers shown illustrate a typical task. Each student will
              receive different combinations of numbers on their fun sheet
            </DisplayPara>
            {/* <DisplayPara>
              tasks include finding, a ½, a third, ½, fifth, tenth or ½ of a
              quantity as well as percentage changes including ±10% or ±15%.
            </DisplayPara> */}
            {pathname === "/early-primary/student" ? (
              <StudentCard />
            ) : (
              props.children
            )}

            {pathname === "/primary-maths/rational-number/student" ? (
              <>
                <Col>
                  <NoteCard>
                    <CustomsParagraph>
                      The Rational Number pdf will require you to{" "}
                      <b>minimise the margins</b> when you go to print.
                    </CustomsParagraph>
                  </NoteCard>
                </Col>
                <StudentSubmitBtnRational>
                  {/* <button>Generate PDF</button> */}
                  <LaddaButton
                    onClick={onPDFGenerate}
                    className="btn ladda-button orange zoom-out px-4 btn-ladda"
                    loading={loginLoading}
                    data-color="#00b050"
                    data-style={ZOOM_OUT}
                  >
                    Generate PDF
                  </LaddaButton>
                  {loginLoading === true ? (
                    <ProcessingMessage>
                      Certain combinations of tasks require longer PDF
                      generation time. Please wait for PDF to be ready.
                    </ProcessingMessage>
                  ) : (
                    ""
                  )}
                </StudentSubmitBtnRational>
              </>
            ) : (
              <StudentSubmitBTN>
                <LaddaButton
                  onClick={onPDFGenerate}
                  className="btn ladda-button orange zoom-out px-4 btn-ladda"
                  loading={loginLoading}
                  data-color="#00b050"
                  data-style={ZOOM_OUT}
                >
                  Generate PDF
                </LaddaButton>
              </StudentSubmitBTN>
            )}
          </Col>
        </Row>
      </Fragment>
    </Container>
  );
};

DefaultFunsheet.propTypes = {};

export default DefaultFunsheet;
const NoteCard = styled(Card)`
border: 1px solid #000;
    cursor: auto;
    background-color: #fff;
    color: black;
    padding: 15px;
    margin:25px 0 25px 280px;
`;
const CustomsParagraph = styled.p`
  text-align: justify;
  font-size: 16px;
  margin: 0px;
`;

const StudentHeading = styled.h2`
  font-size: 22px;
  // line-height: 44px;
  letter-spacing: 0em;
`;
const SubHeading = styled.h5`
  font-size: 19px;
  color: #848484;
`;
const ProcessingMessage = styled.span`
  color: red;
  width: 50%;
  text-align: justify;
  padding-left: 5px;
`;
const StudentSubmitBTN = styled.div`
  display: flex;
  justify-content: center;
  button {
    border: none;
    margin-top: 45px;
    border-radius: 7px;
    padding: 8px 65px !important;
    color: white;
    font-weight: 600;
    font-size: 20px;

    background-color: ${(props) => props.theme.primaryColor};

    &:hover {
      background-color: ${(props) => props.theme.buttonHover};
    }

    &:active {
      background-color: ${(props) => props.theme.buttonHover};
      border-color: ${(props) => props.theme.buttonHover};
      box-shadow: ${(props) => "0 0 0 0.25rem " + props.theme.buttonActive};
    }
    &:focus {
      background-color: ${(props) => props.theme.buttonHover};
      border-color: ${(props) => props.theme.buttonHover};
      box-shadow: ${(props) => "0 0 0 0.25rem " + props.theme.buttonActive};
    }
  }
`;

const StudentSubmitBtnRational = styled.div`
  display: flex;
  justify-content: center;
  justify-content: space-around;
  // align-items: baseline;
  button {
    border: none;
    margin-bottom: 15px;
    border-radius: 7px;
    padding: 8px 65px !important;
    color: #fff;
    font-weight: 600;
    font-size: 20px;
    // justify-content: space-around;
    // align-items: baseline;
    background-color: ${(props) => props.theme.primaryColor};

    &:hover {
      background-color: ${(props) => props.theme.buttonHover};
    }

    &:active {
      background-color: ${(props) => props.theme.buttonHover};
      border-color: ${(props) => props.theme.buttonHover};
      box-shadow: ${(props) => "0 0 0 0.25rem " + props.theme.buttonActive};
    }
    &:focus {
      background-color: ${(props) => props.theme.buttonHover};
      border-color: ${(props) => props.theme.buttonHover};
      box-shadow: ${(props) => "0 0 0 0.25rem " + props.theme.buttonActive};
    }
  }
`;

const ClearBTN = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 7px;

  button {
    /* font-size: 10px; */
    border: none;
    background-color: ${(props) => props.theme.primaryColor};
    border-radius: 4px;
    color: ${(props) => props.theme.buttonText};

    font-size: 14px;
    padding: 2px 15px;
    margin-bottom: 3px;
    &:hover {
      background-color: ${(props) => props.theme.buttonHover};
    }

    &:active {
      background-color: ${(props) => props.theme.buttonHover};
      border-color: ${(props) => props.theme.buttonHover};
      box-shadow: ${(props) => "0 0 0 0.25rem " + props.theme.buttonActive};
    }
    &:focus {
      background-color: ${(props) => props.theme.buttonHover};
      border-color: ${(props) => props.theme.buttonHover};
      box-shadow: ${(props) => "0 0 0 0.25rem " + props.theme.buttonActive};
    }
  }
`;

//
const DisplayPara = styled.p`
  margin: 25px 0px;
  font-size: 14px;
  text-align: justify;
`;
