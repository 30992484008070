import React from "react";
import { Col, Row } from "reactstrap";
import { InlineMath } from "react-katex";
import "katex/dist/katex.min.css";

const PdfView = (props) => {
  const { state } = props.location;
  // console.log("🚀state", state);
  setTimeout(() => {
    if (state !== undefined) {
      window.print();
    }
  }, 4000);

  return (
    <div className="container-fluid" style={{ height: "100vh" }}>
      {state?.length > 0 &&
        state?.map((first) => {
          //   console.log(first);
          return (
            <div
              style={{
                width: "100%",
                margin: "0px",
              }}
            >
              {/* name and icon */}
              <Row>
                <div style={{ float: "left", width: "30%" }}>
                  {/* <img src={first.logo} alt="" width="200px" /> */}
                  <h2>
                    <strong>Elumat</strong>
                  </h2>
                </div>
                <div
                  style={{ float: "left", width: "70%", textAlign: "right" }}
                >
                  <h2 style={{ fontSize: "18px" }}>{first.studentName}</h2>
                </div>
              </Row>
              {/* api table */}
              <Row>
                <Col>
                  <Row>
                    {first.studentFunsheetData.length > 0 &&
                      first.studentFunsheetData.map((apiData, i) => (
                        <div className="col-md-4" key={i}>
                          <p
                            style={{
                              fontSize: "15px",
                              fontFamily: "Arial, sans-serif",
                              whiteSpace: "nowrap",
                              marginTop: "5px",
                              marginBottom: "5px",
                            }}
                          >
                            {apiData.title === "Find 0.3 of these numbers" ? (
                              <InlineMath math="{\text{Find }} 0.\dot{3} {\text{  of these numbers}}" />
                            ) : (
                              apiData.title
                            )}
                          </p>
                          <table
                            style={{
                              width: "100%",
                              border: "1px solid black",
                            }}
                          >
                            <tr
                              style={{
                                border: "1px solid black",
                              }}
                            >
                              {apiData?.result?.map((final) => {
                                // console.log(final);
                                let a = final.q;
                                let finalstring;
                                if (typeof a === "number") {
                                } else if (typeof a === "string") {
                                  if (a.indexOf(" ") > 0) {
                                    let b = a.split(" ");
                                    let c = b[1].split("/");
                                    let f = b[0];
                                    let d = c[0];
                                    let e = c[1];
                                    finalstring = f + `\\dfrac{${d}}{${e}}`;
                                  } else {
                                    let b = a.split("/");
                                    // console.log(a);
                                    let c = b[0];
                                    let d = b[1] ? b[1] : null;

                                    finalstring = d
                                      ? `\\dfrac{${c}}{${d}}`
                                      : `\\text{${c}}`;
                                  }
                                }
                                return (
                                  <td
                                    style={{
                                      border: "1px solid black",
                                      textAlign: "center",
                                      width: "35px",
                                      height: "40px",
                                      fontSize: "14px",
                                      padding: "0 4px",
                                      verticalAlign: "middle",
                                      fontFamily: "Arial, sans-serif",
                                    }}
                                  >
                                    {/* {final.q} */}
                                    {typeof a === "string" &&
                                      a.indexOf("%") === -1 && (
                                        <InlineMath>{finalstring}</InlineMath>
                                      )}
                                    {typeof a === "number" && final.q + " "}{" "}
                                    {typeof a === "string" &&
                                      a.indexOf("%") >= 0 &&
                                      final.q + " "}{" "}
                                    {/* {symbol ? ReactHtmlParser(symbol) : null} */}
                                  </td>
                                );
                              })}
                            </tr>
                            <tr
                              style={{
                                border: "1px solid black",
                              }}
                            >
                              <td
                                style={{
                                  height: "40px",
                                  width: "35px",
                                  border: "1px solid black",
                                }}
                              >
                                {" "}
                              </td>
                              <td
                                style={{
                                  height: "40px",
                                  width: "35px",
                                  border: "1px solid black",
                                }}
                              >
                                {" "}
                              </td>
                              <td
                                style={{
                                  height: "40px",
                                  width: "35px",
                                  border: "1px solid black",
                                }}
                              ></td>
                              <td
                                style={{
                                  height: "40px",
                                  width: "35px",
                                  border: "1px solid black",
                                }}
                              ></td>
                              <td
                                style={{
                                  height: "40px",
                                  width: "35px",
                                  border: "1px solid black",
                                }}
                              ></td>
                            </tr>
                            <tr
                              style={{
                                border: "1px solid black",
                              }}
                            >
                              {apiData?.message1.map((final) => {
                                // console.log(final);
                                return (
                                  <td
                                    style={{
                                      border: "1px solid black",
                                      textAlign: "center",
                                      width: "35px",
                                      height: "40px",
                                      fontSize: "18px",
                                      padding: "0 4px",
                                      verticalAlign: "middle",
                                      fontFamily: "Arial, sans-serif",
                                    }}
                                  >
                                    {final}
                                  </td>
                                );
                              })}
                            </tr>
                          </table>
                        </div>
                      ))}
                  </Row>
                </Col>
              </Row>
              {/* funsheet msg 1 */}
              <Row>
                <Col>
                  <Row>
                    <div
                      // className="col-md-12"
                      style={{
                        width: "100%",
                        float: "left",
                        marginTop: "15px",
                        marginBottom: "10px",
                      }}
                      //   key={i}
                    >
                      <table>
                        <tr
                          style={{
                            border: "1px solid black",
                            textAlign: "center",
                            width: "35px",
                            height: "40px",
                            fontSize: "14px",
                            padding: "0 4px",
                            verticalAlign: "middle",
                            fontFamily: "Arial, sans-serif",
                          }}
                        >
                          {first.message.funSheetMessage1.length > 0 &&
                            first.message.funSheetMessage1.map((msg, i) => {
                              let a = msg?.number?.a;
                              let finalstring;
                              if (typeof a === "number") {
                              } else if (typeof a === "string") {
                                if (a.indexOf(" ") > 0) {
                                  let b = a.split(" ");
                                  let c = b[1].split("/");
                                  let f = b[0];
                                  let d = c[0];
                                  let e = c[1];
                                  finalstring = f + `\\dfrac{${d}}{${e}}`;
                                } else {
                                  let b = a.split("/");
                                  // console.log(a);
                                  let c = b[0];
                                  let d = b[1] ? b[1] : null;

                                  finalstring = d
                                    ? `\\dfrac{${c}}{${d}}`
                                    : `\\text{${c}}`;
                                }
                              }
                              return (
                                <>
                                  {msg?.number?.a && msg?.number?.has===false ? (
                                    <td
                                      style={{
                                        border: "1px solid black",
                                        textAlign: "center",
                                        width: "35px",
                                        height: "40px",
                                        fontSize: "14px",
                                        padding: "0 5px",
                                        verticalAlign: "middle",
                                        fontFamily: "Arial, sans-serif",
                                      }}
                                    >
                                      {typeof a === "string" &&
                                        a.indexOf("%") === -1 && (
                                          <InlineMath>{finalstring}</InlineMath>
                                        )}
                                      {typeof a === "number" &&
                                        msg?.number?.a + " "}{" "}
                                      {typeof a === "string" &&
                                        a.indexOf("%") >= 0 &&
                                        msg?.number?.a + " "}{" "}
                                    </td>
                                  ) : (
                                    <th
                                      style={{
                                        border: "1px solid black",
                                        textAlign: "center",
                                        width: "100px",
                                        height: "40px",
                                        fontSize: "14px",
                                        padding: "0 4px",
                                        verticalAlign: "middle",
                                        fontFamily: "Arial, sans-serif",
                                        fontWeight: "900"
                                      }}
                                    >
                                        {typeof a === "string" &&
                                        a.indexOf("%") === -1 && (
                                          <InlineMath>{`\\boldsymbol{${finalstring}}`}</InlineMath>
                                        )}
                                      {typeof a === "number" &&
                                        msg?.number?.a + " "}{" "}
                                      {typeof a === "string" &&
                                        a.indexOf("%") >= 0 &&
                                        msg?.number?.a + " "}{" "}
                                    </th>
                                  )}
                                </>
                              );
                            })}
                        </tr>
                        <tr
                          style={{
                            border: "1px solid black",
                            textAlign: "center",
                            width: "35px",
                            height: "40px",
                            fontSize: "14px",
                            padding: "0 4px",
                            verticalAlign: "middle",
                            fontFamily: "Arial, sans-serif",
                          }}
                        >
                          {first?.message?.funSheetMessage1?.length > 0 &&
                            first?.message?.funSheetMessage1?.map((msg, i) => {
                              return (
                                <>
                                  {msg?.value ? (
                                    <td
                                      key={i}
                                      style={{
                                        border: "1px solid black",
                                        textAlign: "center",
                                        width: "35px",
                                        height: "40px",
                                        fontSize: "14px",
                                        padding: "0 5px",
                                        verticalAlign: "middle",
                                        fontFamily: "Arial, sans-serif",
                                      }}
                                    >
                                      {msg?.value}
                                    </td>
                                  ) : (
                                    <td
                                      style={{
                                        border: "1px solid black",
                                        textAlign: "center",
                                        width: "100px",
                                        height: "40px",
                                        fontSize: "14px",
                                        padding: "0 4px",
                                        verticalAlign: "middle",
                                        fontFamily: "Arial, sans-serif",
                                      }}
                                    >
                                      {" "}
                                    </td>
                                  )}
                                </>
                              );
                            })}
                        </tr>{" "}
                      </table>
                    </div>
                  </Row>
                </Col>
              </Row>
              {/* funsheet msg 2 */}
              <Row>
                <Col>
                  <Row>
                    <div
                      style={{
                        width: "100%",
                        float: "left",
                        marginTop: "10px",

                        marginBottom: "10px",
                      }}
                      //   key={i}
                    >
                      <table>
                        <tr
                          style={{
                            border: "1px solid black",
                            textAlign: "center",
                            width: "35px",
                            height: "40px",
                            fontSize: "14px",
                            padding: "0 4px",
                            verticalAlign: "middle",
                            fontFamily: "Arial, sans-serif",
                          }}
                        >
                          {first.message.funSheetMessage2.length > 0 &&
                            first.message.funSheetMessage2.map((msg, i) => {
                              let a = msg?.number?.a;
                              let finalstring;
                              if (typeof a === "number") {
                              } else if (typeof a === "string") {
                                if (a.indexOf(" ") > 0) {
                                  let b = a.split(" ");
                                  let c = b[1].split("/");
                                  let f = b[0];
                                  let d = c[0];
                                  let e = c[1];
                                  finalstring = f + `\\dfrac{${d}}{${e}}`;
                                } else {
                                  let b = a.split("/");
                                  // console.log(a);
                                  let c = b[0];
                                  let d = b[1] ? b[1] : null;

                                  finalstring = d
                                    ? `\\dfrac{${c}}{${d}}`
                                    : `\\text{${c}}`;
                                }
                              }
                              return (
                                <>
                                  {msg?.number?.a && msg?.number?.has===false ? (
                                    <td
                                      style={{
                                        border: "1px solid black",
                                        textAlign: "center",
                                        width: "35px",
                                        height: "40px",
                                        fontSize: "14px",
                                        padding: "0 5px",
                                        verticalAlign: "middle",
                                        fontFamily: "Arial, sans-serif",
                                      }}
                                    >
                                      {typeof a === "string" &&
                                        a.indexOf("%") === -1 && (
                                          <InlineMath>{finalstring}</InlineMath>
                                        )}
                                      {typeof a === "number" &&
                                        msg?.number?.a + " "}{" "}
                                      {typeof a === "string" &&
                                        a.indexOf("%") >= 0 &&
                                        msg?.number?.a + " "}{" "}
                                    </td>
                                  ) : (
                                    <th
                                    style={{
                                      border: "1px solid black",
                                      textAlign: "center",
                                      width: "100px",
                                      height: "40px",
                                      fontSize: "14px",
                                      padding: "0 4px",
                                      verticalAlign: "middle",
                                      fontFamily: "Arial, sans-serif",
                                      fontWeight: "900"
                                    }}
                                  >
                                      {typeof a === "string" &&
                                      a.indexOf("%") === -1 && (
                                        <InlineMath>{`\\boldsymbol{${finalstring}}`}</InlineMath>
                                      )}
                                    {typeof a === "number" &&
                                      msg?.number?.a + " "}{" "}
                                    {typeof a === "string" &&
                                      a.indexOf("%") >= 0 &&
                                      msg?.number?.a + " "}{" "}
                                  </th>
                                  )}
                                </>
                              );
                            })}
                        </tr>
                        <tr
                          style={{
                            border: "1px solid black",
                            textAlign: "center",
                            width: "35px",
                            height: "40px",
                            fontSize: "14px",
                            padding: "0 4px",
                            verticalAlign: "middle",
                            fontFamily: "Arial, sans-serif",
                          }}
                        >
                          {first?.message?.funSheetMessage2?.length > 0 &&
                            first?.message?.funSheetMessage2?.map((msg, i) => {
                              return (
                                <>
                                  {msg?.value ? (
                                    <td
                                      key={i}
                                      style={{
                                        border: "1px solid black",
                                        textAlign: "center",
                                        width: "35px",
                                        height: "40px",
                                        fontSize: "14px",
                                        padding: "0 5px",
                                        verticalAlign: "middle",
                                        fontFamily: "Arial, sans-serif",
                                      }}
                                    >
                                      {msg?.value}
                                    </td>
                                  ) : (
                                    <td
                                      style={{
                                        border: "1px solid black",
                                        textAlign: "center",
                                        width: "100px",
                                        height: "40px",
                                        fontSize: "14px",
                                        padding: "0 4px",
                                        verticalAlign: "middle",
                                        fontFamily: "Arial, sans-serif",
                                      }}
                                    >
                                      {" "}
                                    </td>
                                  )}
                                </>
                              );
                            })}
                        </tr>{" "}
                      </table>
                    </div>
                  </Row>
                </Col>
              </Row>
              {/* funsheet msg 3 */}
              <Row>
                <Col>
                  <Row>
                    <div
                      style={{
                        width: "100%",
                        float: "left",
                        marginTop: "10px",

                        marginBottom: "10px",
                      }}
                      //   key={i}
                    >
                      <table>
                        <tr
                          style={{
                            border: "1px solid black",
                            textAlign: "center",
                            width: "35px",
                            height: "40px",
                            fontSize: "14px",
                            padding: "0 4px",
                            verticalAlign: "middle",
                            fontFamily: "Arial, sans-serif",
                          }}
                        >
                          {first.message.funSheetMessage3.length > 0 &&
                            first.message.funSheetMessage3.map((msg, i) => {
                              let a = msg?.number?.a;
                              let finalstring;
                              if (typeof a === "number") {
                              } else if (typeof a === "string") {
                                if (a.indexOf(" ") > 0) {
                                  let b = a.split(" ");
                                  let c = b[1].split("/");
                                  let f = b[0];
                                  let d = c[0];
                                  let e = c[1];
                                  finalstring = f + `\\dfrac{${d}}{${e}}`;
                                } else {
                                  let b = a.split("/");
                                  // console.log(a);
                                  let c = b[0];
                                  let d = b[1] ? b[1] : null;

                                  finalstring = d
                                    ? `\\dfrac{${c}}{${d}}`
                                    : `\\text{${c}}`;
                                }
                              }
                              return (
                                <>
                              {msg?.number?.a && msg?.number?.has===false ? (
                                    <td
                                      style={{
                                        border: "1px solid black",
                                        textAlign: "center",
                                        width: "35px",
                                        height: "40px",
                                        fontSize: "14px",
                                        padding: "0 5px",
                                        verticalAlign: "middle",
                                        fontFamily: "Arial, sans-serif",
                                      }}
                                    >
                                      {typeof a === "string" &&
                                        a.indexOf("%") === -1 && (
                                          <InlineMath>{finalstring}</InlineMath>
                                        )}
                                      {typeof a === "number" &&
                                        msg?.number?.a + " "}{" "}
                                      {typeof a === "string" &&
                                        a.indexOf("%") >= 0 &&
                                        msg?.number?.a + " "}{" "}
                                    </td>
                                  ) : (
                                    <th
                                    style={{
                                      border: "1px solid black",
                                      textAlign: "center",
                                      width: "100px",
                                      height: "40px",
                                      fontSize: "14px",
                                      padding: "0 4px",
                                      verticalAlign: "middle",
                                      fontFamily: "Arial, sans-serif",
                                      fontWeight: "900"
                                    }}
                                  >
                                      {typeof a === "string" &&
                                      a.indexOf("%") === -1 && (
                                        <InlineMath>{`\\boldsymbol{${finalstring}}`}</InlineMath>
                                      )}
                                    {typeof a === "number" &&
                                      msg?.number?.a + " "}{" "}
                                    {typeof a === "string" &&
                                      a.indexOf("%") >= 0 &&
                                      msg?.number?.a + " "}{" "}
                                  </th>
                                  )}
                                </>
                              );
                            })}
                        </tr>
                        <tr
                          style={{
                            border: "1px solid black",
                            textAlign: "center",
                            width: "35px",
                            height: "40px",
                            fontSize: "14px",
                            padding: "0 4px",
                            verticalAlign: "middle",
                            fontFamily: "Arial, sans-serif",
                          }}
                        >
                           {first?.message?.funSheetMessage3?.length > 0 &&
                            first?.message?.funSheetMessage3?.map((msg, i) => {
                              return (
                                <>
                                  {msg?.value ? (
                                    <td
                                      key={i}
                                      style={{
                                        border: "1px solid black",
                                        textAlign: "center",
                                        width: "35px",
                                        height: "40px",
                                        fontSize: "14px",
                                        padding: "0 5px",
                                        verticalAlign: "middle",
                                        fontFamily: "Arial, sans-serif",
                                      }}
                                    >
                                      {msg?.value}
                                    </td>
                                  ) : (
                                    <td
                                      style={{
                                        border: "1px solid black",
                                        textAlign: "center",
                                        width: "100px",
                                        height: "40px",
                                        fontSize: "14px",
                                        padding: "0 4px",
                                        verticalAlign: "middle",
                                        fontFamily: "Arial, sans-serif",
                                      }}
                                    >
                                      {" "}
                                    </td>
                                  )}
                                </>
                              );
                            })}
                        </tr>{" "}
                      </table>
                    </div>
                  </Row>
                </Col>
              </Row>
              {/* funsheet msg 4 */}
              <Row>
                <Col>
                  <Row>
                    <div
                      style={{
                        width: "100%",
                        float: "left",
                        marginTop: "10px",
                      }}
                      //   key={i}
                    >
                      <table>
                        <tr
                          style={{
                            border: "1px solid black",
                            textAlign: "center",
                            width: "35px",
                            height: "40px",
                            fontSize: "14px",
                            padding: "0 4px",
                            verticalAlign: "middle",
                            fontFamily: "Arial, sans-serif",
                          }}
                        >
                          {first.message.funSheetMessage4.length > 0 &&
                            first.message.funSheetMessage4.map((msg, i) => {
                              let a = msg?.number?.a;
                              let finalstring;
                              if (typeof a === "number") {
                              } else if (typeof a === "string") {
                                if (a.indexOf(" ") > 0) {
                                  let b = a.split(" ");
                                  let c = b[1].split("/");
                                  let f = b[0];
                                  let d = c[0];
                                  let e = c[1];
                                  finalstring = f + `\\dfrac{${d}}{${e}}`;
                                } else {
                                  let b = a.split("/");
                                  // console.log(a);
                                  let c = b[0];
                                  let d = b[1] ? b[1] : null;

                                  finalstring = d
                                    ? `\\dfrac{${c}}{${d}}`
                                    : `\\text{${c}}`;
                                }
                              }
                              return (
                                <>
                                {msg?.number?.a && msg?.number?.has===false ? (
                                    <td
                                      style={{
                                        border: "1px solid black",
                                        textAlign: "center",
                                        width: "35px",
                                        height: "40px",
                                        fontSize: "14px",
                                        padding: "0 5px",
                                        verticalAlign: "middle",
                                        fontFamily: "Arial, sans-serif",
                                      }}
                                    >
                                      {typeof a === "string" &&
                                        a.indexOf("%") === -1 && (
                                          <InlineMath>{finalstring}</InlineMath>
                                        )}
                                      {typeof a === "number" &&
                                        msg?.number?.a + " "}{" "}
                                      {typeof a === "string" &&
                                        a.indexOf("%") >= 0 &&
                                        msg?.number?.a + " "}{" "}
                                    </td>
                                  ) : (
                                    <th
                                    style={{
                                      border: "1px solid black",
                                      textAlign: "center",
                                      width: "100px",
                                      height: "40px",
                                      fontSize: "14px",
                                      padding: "0 4px",
                                      verticalAlign: "middle",
                                      fontFamily: "Arial, sans-serif",
                                      fontWeight: "900"
                                    }}
                                  >
                                      {typeof a === "string" &&
                                      a.indexOf("%") === -1 && (
                                        <InlineMath>{`\\boldsymbol{${finalstring}}`}</InlineMath>
                                      )}
                                    {typeof a === "number" &&
                                      msg?.number?.a + " "}{" "}
                                    {typeof a === "string" &&
                                      a.indexOf("%") >= 0 &&
                                      msg?.number?.a + " "}{" "}
                                  </th>
                                  )}
                                </>
                              );
                            })}
                        </tr>
                        <tr
                          style={{
                            border: "1px solid black",
                            textAlign: "center",
                            width: "35px",
                            height: "40px",
                            fontSize: "14px",
                            padding: "0 4px",
                            verticalAlign: "middle",
                            fontFamily: "Arial, sans-serif",
                          }}
                        >
                          {first?.message?.funSheetMessage4?.length > 0 &&
                            first?.message?.funSheetMessage4?.map((msg, i) => {
                              // console.log(msg);
                              return (
                                <>
                                  {msg?.value ? (
                                    <td
                                      key={i}
                                      style={{
                                        border: "1px solid black",
                                        textAlign: "center",
                                        width: "35px",
                                        height: "40px",
                                        fontSize: "14px",
                                        padding: "0 5px",
                                        verticalAlign: "middle",
                                        fontFamily: "Arial, sans-serif",
                                      }}
                                    >
                                      {msg?.value}
                                    </td>
                                  ) : (
                                    <td
                                      style={{
                                        border: "1px solid black",
                                        textAlign: "center",
                                        width: "100px",
                                        height: "40px",
                                        fontSize: "14px",
                                        padding: "0 4px",
                                        verticalAlign: "middle",
                                        fontFamily: "Arial, sans-serif",
                                      }}
                                    >
                                      {" "}
                                    </td>
                                  )}
                                </>
                              );
                            })}
                        </tr>{" "}
                      </table>
                    </div>
                  </Row>
                </Col>
              </Row>
              <div style={{ pageBreakBefore: "always" }}>&nbsp;</div>
            </div>
          );
        })}
    </div>
  );
};

export default PdfView;
