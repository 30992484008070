import React, { Fragment } from "react";
// import PropTypes from 'prop-types'
import RightArrow from "../../../assets/image/right-arrow.png";
import Close from "../../../assets/image/close 324.png";
import styled from "styled-components";
import { Card, CardBody, CardHeader } from "reactstrap";
import { useLocation } from "react-router-dom";
import { threePartStringFitMessage } from "../../../Helper";

const StudentCommonList = (props) => {
  const { pathname } = useLocation();

  const {
    list,
    Placeholder,
    comment,
    onSubmitBTN,
    InputChange,
    InputValue,
    DeleteBTN,
    DiscardSpace,
    KeepSpace,
    onListClick,
    selectedCAndS,
    onKeyPress,
    errorMessage,
    onPaste,
    onEdit,
  } = props;

  /* Using For Message Validation purpose */
  /* Fit Messages Validation */
  const FitMessageFunction = (data) => {
    var newDString;
    let String1, String2, String3;
    if (/\s/g.test(data)) {
      let StringData = threePartStringFitMessage(data.trimStart());
      String1 = StringData.String1 !== "" ? StringData.String1 : "";
      String2 = StringData.String2 !== "" ? StringData.String2 : "";
      String3 = StringData.String3 !== "" ? StringData.String3 : "";

      newDString = `${String1} ${String2} ${String3}`;
      newDString = newDString.trim();
    }
    return newDString;
  };
  return (
    <StudentCardBox>
      <StudentBoxHeader>
        <textarea
          type="text"
          placeholder={Placeholder}
          onChange={InputChange}
          value={InputValue}
          onKeyPress={onKeyPress}
          onPaste={(e) => onPaste(e)}
        />

        <img src={RightArrow} alt="right-arrow" onClick={onSubmitBTN} />
        <ErrorMessage>{errorMessage}</ErrorMessage>
      </StudentBoxHeader>

      <StudentBox>
        <ul>
          {list.length > 0
            ? list.map((student, i) => {
                let FitCheckMessage = FitMessageFunction(student.message);

                /* message Fit In Ui boxes */
                let MessageFit =
                  FitCheckMessage?.length === undefined
                    ? false
                    : FitCheckMessage?.length !== student.message.length;
                // console.log("🚀FitCheckMessage", MessageFit);
                // console.log("🚀Student", student.message)
                return (
                  <Fragment key={i}>
                    <ListItem button={comment} selected={i === selectedCAndS}>
                      {pathname === "/early-primary/student" ? (
                        <ListItemChild
                          comment={comment}
                          commentlength={
                            student.message.length > 48 || MessageFit
                              ? true
                              : false
                          }
                        >
                          <List
                            comment={comment}
                            commentlength={
                              student.message.length > 48 || MessageFit
                                ? true
                                : false
                            }
                            onClick={(e) => onListClick(i)}
                          >
                            {i + 1} {student.message}{" "}
                          </List>

                          <EditButton onClick={() => onEdit(student)}>
                            <i className="fa fa-pencil"></i>
                          </EditButton>

                          <img
                            src={Close}
                            alt=""
                            onClick={(e) => DeleteBTN(student, student.id)}
                          />
                        </ListItemChild>
                      ) : (
                        <ListItemChild
                          comment={comment}
                          commentlength={
                            student.message.length > 130 ? true : false
                          }
                        >
                          <List
                            comment={comment}
                            commentlength={
                              student.message.length > 130 ? true : false
                            }
                            onClick={(e) => onListClick(i)}
                          >
                            {i + 1} {student.message}{" "}
                          </List>

                          <EditButton onClick={() => onEdit(student)}>
                            <i className="fa fa-pencil"></i>
                          </EditButton>

                          <img
                            src={Close}
                            alt=""
                            onClick={(e) => DeleteBTN(student, student.id)}
                          />
                        </ListItemChild>
                      )}

                      {pathname === "/early-primary/student" ? (
                        (comment && student.message.length > 48) ||
                        (comment && !student.discard) ? (
                          <CommentBTN>
                            {student.discard ? (
                              <button onClick={(e) => DiscardSpace(student, i)}>
                                Discard spaces
                              </button>
                            ) : (
                              <button onClick={(e) => KeepSpace(student, i)}>
                                Keep spaces
                              </button>
                            )}
                          </CommentBTN>
                        ) : null
                      ) : (comment && student.message.length > 130) ||
                        (comment && !student.discard) ? (
                        <CommentBTN>
                          {student.discard ? (
                            <button onClick={(e) => DiscardSpace(student, i)}>
                              Discard spaces
                            </button>
                          ) : (
                            <button onClick={(e) => KeepSpace(student, i)}>
                              Keep spaces
                            </button>
                          )}
                        </CommentBTN>
                      ) : null}
                    </ListItem>
                  </Fragment>
                );
              })
            : !comment
            ? "No student found"
            : "No comment found"}
        </ul>
      </StudentBox>
    </StudentCardBox>
  );
};

// StudentCommonList.propTypes = {

// }

export default StudentCommonList;

//

const StudentCardBox = styled(Card)`
  border: 1px solid #e1e1e1;
  background-color: #fbfbfb;
`;
const StudentBoxHeader = styled(CardHeader)`
  height: 55px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  background-color: #fbfbfb;

  textarea {
    outline: none;
    border: none;
    width: 95%;
    font-size: 14px;
    background-color: #fbfbfb;
    height: 22px;
    resize: none;
    &::-webkit-scrollbar {
      width: 0px;
    }
  }
  img {
    cursor: pointer;
  }
`;

const StudentBox = styled(CardBody)`
  height: 75vh;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* border-radius: 10px; */
  }
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.primaryColor};
    border-radius: 10px;
    height: 20px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.primaryColor};
  }
  padding: 0.75rem 0.85rem;
  ul {
    padding-left: 0px;
    cursor: pointer;
  }
`;
const ListItem = styled.li`
  color: ${(props) => (props.commentId ? "red" : "#000")};
  background: #ffffff;
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  margin: 4px 0px;
  font-size: 14px;
  padding: 8px 10px;
  font-weight: 500;
  border: ${(props) =>
    props.selected && `1px solid ${props.theme.primaryColor}`};

  img {
    cursor: pointer;
    margin-left: 4px;
    position: ${(props) => props.comment && "absolute"};
    right: 0px;
  }
`;
const ListItemChild = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-between;
  align-items: ${(props) =>
    !props.comment
      ? props.commentlength < 28
        ? "center"
        : "flex-start"
      : props.commentlength < 34
      ? "center"
      : "flex-start"};

  position: relative;
`;
const List = styled.div`
  width: 81%;
  color: ${(props) =>
    props.commentlength === true && props.comment ? "red" : "black"};
  font-size: ${(props) => props.comment && "11px"};
  font-weight: ${(props) => props.comment && "400"};
  overflow: auto;
`;

const CommentBTN = styled.div`
  display: flex;
  align-content: center;
  justify-content: space-between;
  align-items: baseline;
  /* margin-bottom: 5px; */
  button {
    margin-top: 10px;
    border: none;
    background-color: ${(props) => props.theme.primaryColor};
    font-size: 11px;
    border-radius: 4px;
    padding: 2px 12px;
    color: #ffff;
    &:nth-child(2) {
      background-color: ${(props) => props.theme.buttonText};
      color: #000;
      border: ${(props) => `1px solid ${props.theme.primaryColor}`};
    }
  }
`;
const ErrorMessage = styled.div`
  font-size: 11px;
  color: ${(props) => props.theme.textError};
  font-style: italic;
`;
const EditButton = styled.div`
  .fa {
    color: ${(props) => props.theme.primaryColor};
  }
`;
