import axios from "axios";
import { toast } from "react-toastify";
const PROXY = process.env.REACT_APP_URL + "api/";
//
const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

export const ContactPost = (data) => async (dispatch) => {
  const body = JSON.stringify(data);
  try {
    const res = await axios.post(`${PROXY}contact/add`, body, config);
    
    return res;
  } catch (err) {
    toast.error(err.response.data.message);
  }
};
