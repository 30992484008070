import React, { Fragment } from 'react';
import { Container, Row, Col, Card, CardBody, CardHeader } from 'reactstrap';
import styled from 'styled-components';
import ToastMessage from '../../DefaultComponets/ToastMessage/ToastMessage';
import { Link } from 'react-router-dom';
import Right_arrow from '../../assets/image/arrow-right.png';
import {
  ButtonGroupData,
  InlineButtonGroup,
  SecondaryTables,
} from '../SecondaryCommons/SecondaryTables';
import {
  expand,
  Multiply_Data,
  Summing,
  trigRation,
} from '../../api/data/Secondary';

const SecondaryAlgebra = (props) => {
  return (
    <Fragment>
      <Container>
        <ToastMessage />
        <Row className='mt-4'>
          <Col xl='4' sm='4' md='4' lg='4'>
            <Row>
              <Col xl='12' sm='12' md='12' lg='12'>
                <Heading>Secondary</Heading>
              </Col>
              <Col xl='12' sm='12' md='12' lg='12' className='mb-5'>
                <SubHeading>Algebra and trigonometry</SubHeading>
              </Col>
            </Row>
          </Col>
          <Col xl='4' sm='4' md='4' lg='4'>
            <BoxCard>
              <Link to='/secondary/integers-rationals'>
                <BoxBody>
                  <BoxInLine>
                    <BoxHeading>Secondary</BoxHeading>
                    <BoxPara>Integers and rationals</BoxPara>
                    <BoxImage>
                      <img src={Right_arrow} alt='right-arrow' />
                    </BoxImage>
                  </BoxInLine>
                </BoxBody>
              </Link>
            </BoxCard>
          </Col>
          <Col xl='4' sm='4' md='4' lg='4'>
            <BoxCard>
              <Link to='/secondary/rationals-ratio-surds'>
                <BoxBody>
                  <BoxInLine>
                    <BoxHeading>Secondary</BoxHeading>
                    <BoxPara>
                      Further rationals and ratio, surds and logarithms
                    </BoxPara>
                    <BoxImage>
                      <img src={Right_arrow} alt='right-arrow' />
                    </BoxImage>
                  </BoxInLine>
                </BoxBody>
              </Link>
            </BoxCard>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row>
          <Col xl='6' sm='12' md='6' lg='6' xs='12'>
            <Card>
              <FullBoxHeading>Algebra</FullBoxHeading>
              <FullBoxBody>
                <SecondaryTables
                  title={'Summing like terms'}
                  List={Summing}
                  displayFlex={1}
                  className='mt-3'
                />
                <ButtonGroupData
                  title='Multiply by:'
                  List={Multiply_Data}
                  displayFlex={1}
                />
                <ButtonGroupData
                  title='Dividing by:'
                  List={Multiply_Data}
                  displayFlex={1}
                  className={'mt-5'}
                />
                <ButtonGroupData
                  title='Expanding a:'
                  List={expand}
                  displayFlex={1}
                  className={'mt-5'}
                  largeButton={true}
                />
                <ButtonGroupData
                  title='Factorising a:'
                  List={['A Binomial']}
                  displayFlex={1}
                  className={'mt-5'}
                  largeButton={true}
                />
                <ButtonGroupData
                  title='Solve Equations'
                  List={['1 - Step', '2 - Step', 'Involving brackets one side']}
                  displayFlex={1}
                  className={'mt-5'}
                  smallBTN={true}
                />
              </FullBoxBody>
            </Card>
          </Col>
          <Col xl='6' sm='12' md='6' lg='6' xs='12'>
            <Card>
              <FullBoxHeading>
                Trigonometry
                <Row className='mt-3'>
                  <Col md='10' sm='10' xs='10' xl='10' lg='10'>
                    <p>Print Quadrant/s and unlabelled vITs on back</p>
                  </Col>
                  <Col md='2' sm='2' xs='2' xl='2' lg='2'>
                    <FillBTN buttoncolor={1}></FillBTN>
                  </Col>
                  <Col md='10' sm='10' xs='10' xl='10' lg='10'>
                    <p>Print Quadrant/s and labelled vITs on back</p>
                  </Col>
                  <Col md='2' sm='2' xs='2' xl='2' lg='2'>
                    <FillBTN></FillBTN>
                  </Col>
                  <Col md='12' sm='12' xs='12' xl='12' lg='12'>
                    <p>
                      If either is chosen, each funsheet must be printed
                      double-sided
                    </p>
                  </Col>
                </Row>
              </FullBoxHeading>
              <FullBoxBody>
                <ButtonGroupData
                  title='1st Quadrant'
                  List={['Exact Ratios']}
                  displayFlex={1}
                  className={'mt-5'}
                  smallBTN={true}
                />
                <ButtonGroupData
                  title='1st Quadrant'
                  List={['Exact Ratios']}
                  displayFlex={1}
                  className={'mt-5'}
                  smallBTN={true}
                />
                <InlineButtonGroup
                  title={'Find trig ratios of angles:'}
                  List={trigRation}
                  smallBTN={1}
                  displayFlex={1}
                />
                <InlineButtonGroup
                  title={'Find the angle given the trig ratios'}
                  List={trigRation}
                  smallBTN={1}
                  displayFlex={1}
                />
                <ButtonGroupData
                  title='1st and 2nd Quadrants'
                  List={['Exact Ratios']}
                  displayFlex={1}
                  className={'mt-5'}
                  smallBTN={true}
                />
                <InlineButtonGroup
                  title={'Find trig ratios of angles:'}
                  List={trigRation}
                  smallBTN={1}
                  displayFlex={1}
                />
                <InlineButtonGroup
                  title={'Find the angle given the trig ratios'}
                  List={trigRation}
                  smallBTN={1}
                  displayFlex={1}
                />
                <ButtonGroupData
                  title='Any Quadrant'
                  List={['Exact Ratios']}
                  displayFlex={1}
                  className={'mt-5'}
                  smallBTN={true}
                />
                <InlineButtonGroup
                  title={'Find trig ratios of angles:'}
                  List={trigRation}
                  smallBTN={1}
                  displayFlex={1}
                />
                <InlineButtonGroup
                  title={'Find the angle given the trig ratios'}
                  List={trigRation}
                  smallBTN={1}
                  displayFlex={1}
                />
              </FullBoxBody>
            </Card>
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col xl='3' sm='6' md='3' lg='3' xs='6' className='mt-3'>
            <Link to='#!'>
              <SubmitBTN>Enter Names & Messages </SubmitBTN>
            </Link>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

SecondaryAlgebra.propTypes = {};

export default SecondaryAlgebra;

const Heading = styled.h1`
  ${(props) => props.theme.primaryHeading}
`;
const SubHeading = styled.h5`
  ${(props) => props.theme.subHeading}
`;

const BoxBody = styled(CardBody)`
  background-color: #fbfbfb;
  padding: ' 9px 1rem';
`;
const BoxCard = styled(Card)`
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  a {
    text-decoration: none;
  }
`;
const BoxInLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const BoxHeading = styled.h3`
  font-size: 18px;
  margin-bottom: 0px;
  font-family: 'Saira';
  flex: 1 1 auto;
  color: black;
`;
const BoxPara = styled.p`
  font-size: 13px;
  font-weight: 400;
  color: #7c7c7c;
  font-family: 'Roboto', sans-serif;
  margin-bottom: 0px;
  text-decoration: none;
  flex: 1 1 49%;
  @media (max-width: 1200px) {
    flex: 1 1 25%;
  }
`;

const BoxImage = styled.div``;

const FullBoxHeading = styled(CardHeader)`
  background-color: #fbfbfb;
  text-align: center;
  font-family: 'Roboto';
  font-size: 24px;
  font-weight: 500;
  text-transform: capitalize;
  p {
    font-size: 18px;
    margin-bottom: 0px;
    text-align: left;
    font-weight: 400;
    font-family: ${(props) => props.theme.RobotoFont};
    font-style: normal;
    @media (max-width: 1200px) {
      font-size: 15px;
    }
  }
`;
const FullBoxBody = styled(CardBody)`
  background-color: #fbfbfb;
`;

const FillBTN = styled.button`
  width: 72px;
  height: 32px;
  background-color: ${(props) => (props.buttoncolor ? '#4472c4' : '#ffff')};
  border: ${(props) => (!props.buttoncolor ? '1px solid #4472c4' : 'none')};
  border-radius: 5px;
`;
const SubmitBTN = styled.button`
  border: none;
  background-color: ${(props) => props.theme.primaryColor};
  border-radius: 7px;
  color: white;
  margin-left: 20px;
  padding: 12px 35px;
  &:active {
  }
  @media (max-width: 1200px) {
    padding: 12px 14px;
  }
`;
