import React from 'react'
import { ToastContainer } from 'react-toastify'
import styled from 'styled-components'

const ToastMessage = () => {
  return <CustomToast />
}

export default ToastMessage

//

const CustomToast = styled(ToastContainer)`
  .Toastify__toast-container {
    width: 320px;
  }
  .Toastify__toast--default {
    background: #fff;
    color: #aaa;
  }
  .Toastify__toast--info {
    background: #3498db;
  }
  .Toastify__toast--success {
    background: ${(props) => props.theme.primaryColor};
  }
  .Toastify__toast--warning {
    background: #f1c40f;
  }
  .Toastify__toast--error {
    background: #e74c3c;
  }
`
