import React, { Fragment } from "react";

import styled from "styled-components";
import { Col } from "reactstrap";
import { forPartString } from "../../../Helper";

const FillFunsheet = (props) => {
  let { selectedComment } = props;
  selectedComment = selectedComment !== null ? selectedComment.trim() : null;
  let String1, String2, String3, String4;
  if (selectedComment) {
    if (/\s/g.test(selectedComment)) {
      let StringData = forPartString(selectedComment.trimStart());
      String1 = StringData.String1;
      String2 = StringData.String2;
      String3 = StringData.String3;
      String4 = StringData.String4;
    } else {
      String1 = selectedComment.slice(0, 32);
      String2 = selectedComment.slice(32, 64);
      String3 = selectedComment.slice(64, 96);
      String4 = selectedComment.slice(96);
    }
  }

  const InputField1 = () => {
    let content = [];
    for (let i = 0; i < 32; i++) {
      content.push(
        <FieldButton key={i}>{String1 ? String1[i] : ""}</FieldButton>
      );
    }
    return content;
  };
  const InputField2 = () => {
    let content = [];
    for (let i = 0; i < 32; i++) {
      content.push(
        <FieldButton key={i}>{String2 ? String2[i] : ""}</FieldButton>
      );
    }
    return content;
  };
  const InputField3 = () => {
    let content = [];
    for (let i = 0; i < 32; i++) {
      content.push(
        <FieldButton key={i}>{String3 ? String3[i] : ""}</FieldButton>
      );
    }
    return content;
  };
  const InputField4 = () => {
    let content = [];
    for (let i = 0; i < 32; i++) {
      content.push(
        <FieldButton key={i}>{String4 ? String4[i] : ""}</FieldButton>
      );
    }
    return content;
  };
  return (
    <Fragment>
      <Col sm="12" md="12">
        <FieldDISPLAY>{InputField1()}</FieldDISPLAY>
        <FieldDISPLAY className="my-3">{InputField2()}</FieldDISPLAY>
        <FieldDISPLAY className="my-3">{InputField3()}</FieldDISPLAY>
        <FieldDISPLAY>{InputField4()}</FieldDISPLAY>
      </Col>
    </Fragment>
  );
};

FillFunsheet.propTypes = {};

export default FillFunsheet;

const FieldDISPLAY = styled.div`
  display: flex;
`;
const FieldButton = styled.button`
  height: 15px;
  width: 15px;
  border-radius: 2px;
  background: #ffffff;
  border: ${(props) => `1px solid ${props.theme.primaryColor}`};
  margin: 0px 1px;
  font-size: 9px;
`;
