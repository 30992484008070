import axios from "axios";
import { toast } from "react-toastify";
const PROXY = process.env.REACT_APP_URL + "api/";
//
const config = {
    headers: {
        "Content-Type": "application/json",
    },
};


export const getLanguages = () => async (dispatch) => {
    const res = await axios.get(PROXY + "label/get-language");
    dispatch({ type: "GET_LANGUAGES", payload: res.data.result });
};

