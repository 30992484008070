/* Using For Message Validation purpose */
export const threePartStringFitMessage = (string) => {
  string = string.trim();
  let splitString1 = string.split(" ");

  // Row Line 1
  let str1 = partFun(splitString1);
  let spliceString = string.slice(str1.length);

  // removing speace before after and select the message using slice
  spliceString = spliceString.trim();
  let splitString2 = spliceString.split(" ");

  // Row Line 2
  let str2 = partFun(splitString2);
  str2 = str2.trim();
  let spliceString2 = string.slice(str1.length);
  // removing speace before after and select the message using slice
  spliceString2 = spliceString2.trim();
  spliceString2 = spliceString2.slice(str2.length);
  spliceString2 = spliceString2.trim();

  // Row Line 3
  let splitString3 = spliceString2.split(" ");
  let str3 = partFun(splitString3);

  return {
    String1: str1.trimStart(),
    String2: str2.trimStart(),
    String3: str3.trimStart(),
  };
};

export const threePartString = (string) => {
  string = string.trim();
  let splitString1 = string.split(" ");

  // Row Line 1
  let str1 = partFun(splitString1);
  // console.log("🚀line=1", str1);
  let spliceString = string.slice(str1.length);

  // removing speace before after and select the message using slice
  spliceString = spliceString.trim();
  let splitString2 = spliceString.split(" ");

  // Row Line 2
  let str2 = partFun(splitString2);
  // console.log("🚀Line=2", str2);
  str2 = str2.trim();
  let spliceString2 = string.slice(str1.length);
  // removing speace before after and select the message using slice
  spliceString2 = spliceString2.trim();
  spliceString2 = spliceString2.slice(str2.length);
  spliceString2 = spliceString2.trim();
  // console.log("🚀spliceString2 =>", spliceString2);

  // Row Line 3
  let splitString3 = spliceString2.split(" ");
  let str3 = partFun(splitString3, true);

  return {
    String1: str1.trimStart(),
    String2: str2.trimStart(),
    String3: str3.trimStart(),
  };
};

export const forPartString = (string) => {
  string = string.trim();
  let splitString1 = string.split(" ");

  // Row Line 1
  let str1 = partFun2(splitString1);
  // console.log("🚀line=1", str1);
  let spliceString = string.slice(str1.length);

  // removing speace before after and select the message using slice
  spliceString = spliceString.trim();
  let splitString2 = spliceString.split(" ");

  // Row Line 2
  let str2 = partFun2(splitString2);
  // console.log("🚀Line=2", str2);
  str2 = str2.trim();
  let spliceString2 = string.slice(str1.length);
  // removing speace before after and select the message using slice
  spliceString2 = spliceString2.trim();
  spliceString2 = spliceString2.slice(str2.length);
  spliceString2 = spliceString2.trim();
  // console.log("🚀spliceString2 =>", spliceString2);

  // Row Line 3
  let splitString3 = spliceString2.split(" ");
  let str3 = partFun2(splitString3);
  str3 = str3.trim();
  // console.log("🚀Line=3", str3);
  let spliceString4 = string.slice(str1.length + str2.length + str3.length);
  spliceString4 = string.slice(str1.length);
  // removing speace before after and select the message using slice
  spliceString4 = spliceString4.trim();
  spliceString4 = spliceString4.slice(str2.length);
  spliceString4 = spliceString4.trim();
  spliceString4 = spliceString4.slice(str3.length);
  spliceString4 = spliceString4.trim();
  let splitString4 = spliceString4.split(" ");
  // console.log("🚀Line=3 final output", splitString4);

  // Row Line 4
  let str4 = partFun2(splitString4, true);
  // console.log("🚀Line 4", str4);

  return {
    String1: str1.trimStart(),
    String2: str2.trimStart(),
    String3: str3.trimStart(),
    String4: str4.trimStart(),
  };
};

const partFun = (str, last = false) => {
  let result = [];
  let stringLength = 16;
  let i = 0;
  let arrLength = str.length;

  while (stringLength > 0 && arrLength > 0) {
    stringLength = stringLength - (str[i].length + 1);
    result.push(str[i]);
    i++;
    arrLength--;
  }

  if (
    result.join("||X||").replaceAll("||X||", " ").trim().length > 16 &&
    !last
  ) {
    result.pop();
  }

  return result.join("||X||").replaceAll("||X||", " ");
};

const partFun2 = (str, last = false) => {
  let result = [];
  let stringLength = 32;
  let i = 0;
  let arrLength = str.length;

  while (stringLength > 0 && arrLength > 0) {
    stringLength = stringLength - (str[i].length + 1);
    result.push(str[i]);
    i++;
    arrLength--;
  }
  if (
    result.join("||X||").replaceAll("||X||", " ").trim().length > 32 &&
    !last
  ) {
    result.pop();
  }

  return result.join("||X||").replaceAll("||X||", " ");
};

/*  Previous For row string converting */
// export const forPartString = (string) => {
//   let splitString1 = string.split(' ')
//   //
//   let str1 = partFun2(splitString1)
//   let spliceString = string.slice(str1.length)
//   let splitString2 = spliceString.split(' ')
//   //
//   let str2 = partFun2(splitString2)
//   let spliceString2 = string.slice(str1.length + str2.length)
//   let splitString3 = spliceString2.split(' ')
//   //
//   let str3 = partFun2(splitString3)

//   //

//   let spliceString4 = string.slice(str1.length + str2.length + str3.length)
//   let splitString4 = spliceString4.split(' ')
//   let str4 = partFun2(splitString4, true)

//   return {
//     String1: str1.trimStart(),
//     String2: str2.trimStart(),
//     String3: str3.trimStart(),
//     String4: str4.trimStart(),
//   }
// }

/* Previous three row string converting */
// export const threePartString = (string) => {
//   let splitString1 = string.split(" ");

//   let str1 = partFun(splitString1);
//   let spliceString = string.slice(str1.length);
//   let splitString2 = spliceString.split(" ");
//   //
//   let str2 = partFun(splitString2);
//   let spliceString2 = string.slice(str1.length + str2.length);
//   let splitString3 = spliceString2.split(" ");
//   //
//   let str3 = partFun(splitString3, true);

//   return {
//     String1: str1.trimStart(),
//     String2: str2.trimStart(),
//     String3: str3.trimStart(),
//   };
// };
