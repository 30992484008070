import React, { Fragment } from 'react'
import { Col, Card, Row } from 'reactstrap'
import styled from 'styled-components'
// import PropTypes from 'prop-types'

const EarlyPrimaryFooter = (props) => {
  const { title, number, closeBTN, mathlist, name } = props
  return (
    <Fragment>
      <Col sm='4' lg='4' md='4' xl='4' xs='4' className='p-0 px-1 py-2'>
        <Row>
          <Col sm='11' lg='11' md='11' xl='11' xs='11'>
            <ChildBoxedMake className='mx-2'>
              <Col sm='12' lg='12' md='12' xl='12' xs='12'>
                <CardHeading>
                  <p>{number ? `${title} ${number}` : `${title}`}</p>
                  <ButtonOrange value={name} onClick={(e) => closeBTN(e)}>
                    X
                  </ButtonOrange>
                </CardHeading>
              </Col>
              <Col sm='12' lg='12' md='12' xl='12' xs='12'>
                <ButtonGroup1>
                  {mathlist.length > 0
                    ? mathlist.map((value, i) => (
                        <Fragment key={i}>
                          <button>{value}</button>
                        </Fragment>
                      ))
                    : null}
                </ButtonGroup1>
                <ButtonGroup2>
                  <button></button>
                  <button></button>
                  <button></button>
                  <button></button>
                  <button></button>
                </ButtonGroup2>
                <ButtonGroup3>
                  <button></button>
                  <button></button>
                  <button></button>
                  <button></button>
                  <button></button>
                </ButtonGroup3>
              </Col>
            </ChildBoxedMake>
          </Col>
        </Row>
      </Col>
    </Fragment>
  )
}

// EarlyPrimaryFooter.propTypes = {

// }

export default EarlyPrimaryFooter

//

const CardHeading = styled.div`
  display: flex;
  justify-content: space-between;
  p {
    font-weight: 500;
  }
`

const ButtonOrange = styled.button`
  height: 100%;
  font-size: 10px;
  border: none;
  background: ${(props) => props.theme.primaryColor};
  font-weight: 400;
  padding: 2px 12px;
  color: #fff;
  border-radius: 14px;

  &:hover {
    background-color: ${(props) => props.theme.primaryColor};
  }
  &:hover {
    background-color: ${(props) => props.theme.primaryColor};
  }

  &:active {
    background-color: ${(props) => props.theme.primaryColor};
    border-color: ${(props) => props.theme.primaryColor};
    box-shadow: ${(props) => `0 0 0 0.25rem ${props.theme.buttonActive}`};
  }
  &:focus {
    background-color: ${(props) => props.theme.primaryColor};
    border-color: ${(props) => props.theme.primaryColor};
    box-shadow: ${(props) => `0 0 0 0.25rem ${props.theme.buttonActive}`};
  }
`

const ChildBoxedMake = styled(Card)`
  padding: 10px 12px;

  p {
    font-size: 12px;
    margin-bottom: 0.5rem;
  }
`

const ButtonGroup1 = styled.div`
  display: flex;
  flex: 0 1 20%;
  justify-content: space-between;
  flex-wrap: wrap;
  button {
    height: 22px;
    width: 27px;
    font-size: 11px;
    border: ${(props) => `1px solid ${props.theme.primaryColor}`};
    background-color: #fff;
    /* margin: 4px; */
    border-radius: 4px;
  }
`
const ButtonGroup2 = styled.div`
  display: flex;
  flex: 0 1 20%;

  justify-content: space-between;

  button {
    height: 22px;
    width: 27px;
    font-size: 11px;
    border: ${(props) => `1px solid ${props.theme.primaryColor}`};
    background-color: #fff;
    margin: 6px 0px;
    border-radius: 4px;
    @media (max-width: 1200px) {
      margin: 5px 0px;
    }
  }
`
const ButtonGroup3 = styled.div`
  display: flex;
  flex: 0 1 20%;
  /* align-items: center; */
  justify-content: space-between;
  /* flex-wrap: wrap; */
  button {
    height: 22px;
    width: 27px;
    font-size: 11px;
    /* padding: 4px 14px; */
    border: ${(props) => `1px solid ${props.theme.primaryColor}`};
    background-color: #fff;
    /* margin: 5px; */
    border-radius: 4px;
  }
`
