export const WholeNumber = [
  {
    operationOne: "Add",
    languageFirst: "Select",
    abstractlyFirst: "Select",
    operationTwo: "Add Then Multiply",
    languageSecond: "Select",
    abstractlySecond: "Select",
  },
  {
    operationOne: "Subtract",
    languageFirst: "Select",
    abstractlyFirst: "Select",
    operationTwo: "Subtract Then Multiply",
    languageSecond: "Select",
    abstractlySecond: "Select",
  },
  {
    operationOne: "Multiply",
    languageFirst: "Select",
    abstractlyFirst: "Select",
    operationTwo: "Multiply Then Add",
    languageSecond: "Select",
    abstractlySecond: "Select",
  },
  {
    operationOne: "Divide",
    languageFirst: "Select",
    abstractlyFirst: "Select",
    operationTwo: "Divide Then add",
    languageSecond: "Select",
    abstractlySecond: "Select",
  },
  {
    operationOne: "",
    languageFirst: "",
    abstractlyFirst: "",
    operationTwo: "Divide Then subtract",
    languageSecond: "Select",
    abstractlySecond: "Select",
  },
  {
    operationOne: "Square",
    languageFirst: "Select",
    abstractlyFirst: "Select",
    operationTwo: "Square Root Then Multiply",
    languageSecond: "Select",
    abstractlySecond: "Select",
  },
  {
    operationOne: "Square root",
    languageFirst: "Select",
    abstractlyFirst: "Select",
  },
];

// whole number

export const WholeNumberOpt1 = [
  {
    operationOne: "Add",
    languageFirst: {
      key: "add_languageFirst",
      value: "Select",
    },
    romanFirst: {
      key: "add_romanFirst",
      value: "Select",
    },
    abstractlyFirst: {
      key: "add_abstractlyFirst",
      value: "Select",
    },
  },
  {
    operationOne: "Subtract",
    languageFirst: {
      key: "subtract_languageFirst",
      value: "Select",
    },
    romanFirst: {
      key: "subtract_romanFirst",
      value: "Select",
    },
    abstractlyFirst: {
      key: "subtract_abstractlyFirst",
      value: "Select",
    },
  },
  {
    operationOne: "Multiply",
    languageFirst: {
      key: "multiply_languageFirst",
      value: "Select",
    },
    romanFirst: {
      key: "multiply_romanFirst",
      value: "Select",
    },
    abstractlyFirst: {
      key: "multiply_abstractlyFirst",
      value: "Select",
    },
  },
  {
    operationOne: "Divide",
    languageFirst: {
      key: "divide_languageFirst",
      value: "Select",
    },
    romanFirst: {
      key: "divide_romanFirst",
      value: "Select",
    },
    abstractlyFirst: {
      key: "divide_abstractlyFirst",
      value: "Select",
    },
  },

  {
    operationOne: "Square",
    languageFirst: {
      key: "square_languageFirst",
      value: "Select",
    },
    romanFirst: {
      key: "square_romanFirst",
      value: "Select",
    },
    abstractlyFirst: {
      key: "square_abstractlyFirst",
      value: "Select",
    },
  },
  {
    operationOne: "Square root",
    languageFirst: {
      key: "square_root_languageFirst",
      value: "Select",
    },
    romanFirst: {
      key: "square_root_romanFirst",
      value: "Select",
    },
    abstractlyFirst: {
      key: "square_root_abstractlyFirst",
      value: "Select",
    },
  },
];

export const WholeNumberOpt2 = [
  {
    operationTwo: "Add Then Multiply",
    languageSecond: {
      key: "add_languageSecond",
      value: "Select",
    },
    romanSecond: {
      key: "add_romanSecond",
      value: "Select",
    },
    abstractlySecond: {
      key: "add_abstractSecond",
      value: "Select",
    },
  },
  {
    operationTwo: "Subtract Then Multiply",
    languageSecond: {
      key: "subtract_then_multiply_languageSecond",
      value: "Select",
    },
    romanSecond: {
      key: "subtract_then_multiply_romanSecond",
      value: "Select",
    },
    abstractlySecond: {
      key: "subtract_then_multiply_abstractSecond",
      value: "Select",
    },
  },
  {
    operationTwo: "Multiply Then Add",
    languageSecond: {
      key: "multiply_then_add_languageSecond",
      value: "Select",
    },
    romanSecond: {
      key: "multiply_then_add_romanSecond",
      value: "Select",
    },
    abstractlySecond: {
      key: "multiply_then_add_abstractSecond",
      value: "Select",
    },
  },
  {
    operationTwo: "Multiply Then subtract",
    languageSecond: {
      key: "multiply_then_subtract_languageSecond",
      value: "Select",
    },
    romanSecond: {
      key: "multiply_then_subtract_romanSecond",
      value: "Select",
    },
    abstractlySecond: {
      key: "multiply_then_subtract_abstractSecond",
      value: "Select",
    },
  },
  {
    operationTwo: "Divide Then add",
    languageSecond: {
      key: "divide_then_add_languageSecond",
      value: "Select",
    },
    romanSecond: {
      key: "divide_then_add_romanSecond",
      value: "Select",
    },
    abstractlySecond: {
      key: "divide_then_add_abstractSecond",
      value: "Select",
    },
  },
  {
    operationTwo: "Divide Then subtract",
    languageSecond: {
      key: "divide_then_subtract_languageSecond",
      value: "Select",
    },
    romanSecond: {
      key: "divide_then_subtract_romanSecond",
      value: "Select",
    },
    abstractlySecond: {
      key: "divide_then_subtract_abstractSecond",
      value: "Select",
    },
  },
  {
    operationTwo: "Divide Then multiply",
    languageSecond: {
      key: "divide_then_multiply_languageSecond",
      value: "Select",
    },
    romanSecond: {
      key: "divide_then_multiply_romanSecond",
      value: "Select",
    },
    abstractlySecond: {
      key: "divide_then_multiply_abstractSecond",
      value: "Select",
    },
  },
  {
    operationTwo: "Square Root Then Multiply",
    languageSecond: {
      key: "square_root_then_multiply_languageSecond",
      value: "Select",
    },
    romanSecond: {
      key: "square_root_then_multiply_romanSecond",
      value: "Select",
    },
    abstractlySecond: {
      key: "square_root_then_multiply_abstractSecond",
      value: "Select",
    },
  },
];
export const rationalNumber = [
  {
    text: "Add unit fractions",
    text1: "Adding decimal fractions",
    text2: "Find 50% of quantity",
    text3: "Simplify fractions",
  },
  {
    text: "Add proper fractions",
    text1: "Subtracting decimal fractions",
    text2: "Find 25% of quantity",
    text3: "Convert to improper fractions",
  },
  {
    text: "Subtract unit fractions",
    text1: "Multiplying decimal fractions",
    text2: "Find 75% of quantity",
    text3: "Convert to mixed numbers",
  },
  {
    text: "subtract proper fractions",
    text1: "Divide decimal fractions",
    text2: "Find 10% of quantity",
    text3: "Convert fractions to decimals",
  },
  {
    text: "Multiply fractions",
    text1: "Squaring decimal fractions",
    text2: "Find 20% of quantity",
    text3: "Convert fractions to percentages",
  },
  {
    text: "Divide fractions",
    text1: "Square rooting decimal fractions",
    text2: "Find 33 1/3% of quantity",
    text3: "Convert decimals to fractions",
  },
  {
    text: "Find 1/2 of ",
    text1: "Find 0.5 of ",
    text2: "Increase by 10%",
    text3: "Convert decimals to percentages",
    showTwoRow: true,
  },
  {
    text: "Find 1/4 of ",
    text1: "Find 0.25 of ",
    text2: "Decrease by 10%",
    text3: "Convert percentages to fractions",
    showTwoRow: true,
  },
  {
    text: "Find 3/4 of ",
    text1: "Find 0.75 of ",
    text2: "Increase by 15%",

    text3: "Convert percentages to decimals ",
    showTwoRow: true,
  },
  {
    text: "Find 1/10 of",
    text1: "Find 0.1 of",
    text2: "Decrease by 15%",
    showTwoRow: true,
  },
  {
    text: "Find 1/5 of",
    text1: "Find 0.2 of",
    showTwoRow: true,
  },
  {
    text: "Find 1/3 of",
    text1: `Find 0.3 of`,
    recurring: true,
    showTwoRow: true,
  },
];

export const Conversions = [
  {
    opt0: {
      value: "Simplify fractions",
      key: "simplify_mid_fractions",
      col: 1,
    },
    opt1: {
      value: "Simplify fractions",
      key: "simplify_fractions",
      col: 1,
    },

    opt2: {
      value: "Add unit fractions",
      key: "add_unit_fractions",
      col: 2,
    },
    opt3: {
      value: "Adding decimal fractions",
      key: "adding_decimal_fractions",
      col: 2,
    },
    opt4: {
      value: "Find 50% of quantity",
      key: "find_50%_of_quantity",
      col: 1,
    },
  },
  {
    opt0: {
      value: "Convert to improper fractions",
      key: "convert_to_improper_mid_fractions",
      col: 1,
    },
    opt1: {
      value: "Convert to improper fractions",
      key: "convert_to_improper_fractions",
      col: 1,
    },
    opt2: {
      value: "Add proper fractions",
      key: "add_proper_fractions",
      col: 2,
    },
    opt3: {
      value: "Subtracting decimal fractions",
      key: "subtracting_decimal_fractions",
      col: 2,
    },
    opt4: {
      value: "Find 25% of quantity",
      key: "find_25%_of_quantity",
      col: 1,
    },
  },
  {
    opt0: {
      value: "Convert to mixed numbers",
      key: "convert_to_mixed_mid_numbers",
      col: 1,
    },
    opt1: {
      value: "Convert to mixed numbers",
      key: "convert_to_mixed_numbers",
      col: 1,
    },
    opt2: {
      value: "Subtract unit fractions",
      key: "subtract_unit_fractions",
      col: 2,
    },
    opt3: {
      value: "Multiplying decimal fractions",
      key: "multiplying_decimal_fractions",
      col: 2,
    },
    opt4: {
      value: "Find 75% of quantity",
      key: "find_75%_of_quantity",
      col: 1,
    },
  },
  {
    opt0: {
      value: "Convert fractions to decimals",
      key: "convert_mid_fractions_to_decimals",
      col: 1,
    },
    opt1: {
      value: "Convert fractions to decimals",
      key: "convert_fractions_to_decimals",
      col: 1,
    },
    opt2: {
      value: "Subtract proper fractions",
      key: "subtract_proper_fractions",
      col: 2,
    },
    opt3: {
      value: "Divide decimal fractions",
      key: "divide_decimal_fractions",
      col: 2,
    },
    opt4: {
      value: "Find 10% of quantity",
      key: "find_10%_of_quantity",
      col: 1,
    },
  },
  {
    opt0: {
      value: "Convert fractions to percentages",
      key: "convert_mid_fractions_to_percentages",
      col: 1,
    },
    opt1: {
      value: "Convert fractions to percentages",
      key: "convert_fractions_to_percentages",
      col: 1,
    },
    opt2: {
      value: "Multiply fractions",
      key: "multiply_fractions",
      col: 2,
    },
    opt3: {
      value: "Squaring decimal fractions",
      key: "squaring_decimal_fractions",
      col: 2,
    },
    opt4: {
      value: "Find 20% of quantity",
      key: "find_20%_of_quantity",
      col: 1,
    },
  },
  {
    opt0: {
      value: "Convert these decimals to improper or proper fractions",
      key: "convert_mid_these_decimals_to_improper_or_proper_fractions",
      col: 1,
    },
    opt1: {
      value: "Convert these decimals to improper or proper fractions",
      key: "convert_these_decimals_to_improper_or_proper_fractions",
      col: 1,
    },
    opt2: {
      value: "Divide fractions",
      key: "divide_fractions",
      col: 2,
    },
    opt3: {
      value: "Square rooting decimal fractions",
      key: "square_rooting_decimal_fractions",
      col: 2,
    },
    opt4: {
      value: "Find 33 1/3% of quantity",
      key: "find_33_1/3%_of_quantity",
      col: 1,
    },
  },
  {
    opt0: {
      value: "Convert these decimals to percentages",
      key: "convert_mid_these_decimals_to_percentages",
      col: 1,
    },
    opt1: {
      value: "Convert these decimals to percentages",
      key: "convert_these_decimals_to_percentages",
      col: 1,
    },

    opt2: {
      value: "Square these fractions",
      key: "square_these_fractions",
      col: 2,
    },
    opt3: {
      value: "Find 0.5 of",
      key: "find_0.5_of",
      col: 1,
    },
    opt3_2: {
      value: "Find 0.25 of",
      key: "find_0.25_of",
      col: 1,
    },

    opt4: {
      value: "Increase by 10%",
      key: "increase_by_10%",
      col: 1,
    },
  },
  {
    opt0: {
      value: "Convert these percentages to improper or proper fractions",
      key: "convert_mid_these_percentages_to_improper_or_proper_fractions",
      col: 1,
    },
    opt1: {
      value: "Convert these percentages to improper or proper fractions",
      key: "convert_these_percentages_to_improper_or_proper_fractions",
      col: 1,
    },
    // opt1: {
    //   value: 'Convert decimals to fractions',
    //   key: 'convert_decimals_to_fractions',
    //   col: 1,
    // },
    opt2: {
      value: "Square root these fractions",
      key: "square_root_these_fractions",
      col: 2,
    },
    opt3: {
      value: "Find 0.75 of",
      key: "find_0.75_of",
      col: 1,
    },
    opt3_2: {
      value: "Find 0.1 of",
      key: "find_0.1_of",
      col: 1,
    },
    opt4: {
      value: "Decrease by 10%",
      key: "decrease_by_10%",
      col: 1,
    },

    // opt3: {
    //   value: 'Square rooting decimal fractions',
    //   key: 'square_rooting_decimal_fractions',
    //   col: 2,
    // },
    // opt4: {
    //   value: 'Find 33 1/3% of quantity',
    //   key: 'find_33_1/3%_of_quantity',
    //   col: 1,
    // },
  },

  {
    opt0: {
      value: "Convert these percentages to decimals",
      key: "convert_mid_these_percentages_to_decimals",
      col: 1,
    },
    opt1: {
      value: "Convert these percentages to decimals",
      key: "convert_these_percentages_to_decimals",
      col: 1,
    },
    opt2: {
      value: "Find 1/2 of",
      key: "find_1/2_of",
      col: 1,
    },
    opt2_2: {
      value: "Find 1/4 of",
      key: "find_1/4_of",
      col: 1,
    },
    opt3: {
      value: "Find 0.2 of",
      key: "find_0.2_of",
      col: 1,
    },
    opt3_2: {
      value: "Find 0.3 of",
      key: "find_0.3_of",
      col: 1,
      math: true,
    },
    opt4: {
      value: "Increase by 15%",
      key: "increase_by_15%",
      col: 1,
    },
  },

  {
    opt2: {
      value: "Find 3/4 of",
      key: "find_3/4_of",
      col: 1,
    },
    opt2_2: {
      value: "Find 1/10 of",
      key: "find_1/10_of",
      col: 1,
    },
    opt4: {
      value: "Decrease by 15%",
      key: "decrease_by_15%",
      col: 1,
    },
  },
  {
    opt2: {
      value: "Find 1/5 of",
      key: "find_1/5_of",
      col: 1,
    },
    opt2_2: {
      value: "Find 1/3 of",
      key: "find_1/3_of",
      col: 1,
    },
  },
];
