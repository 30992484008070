import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Table,
} from 'reactstrap'
import ToastMessage from '../../DefaultComponets/ToastMessage/ToastMessage'
import { Link } from 'react-router-dom'
import Right_arrow from '../../assets/image/arrow-right.png'
import {
  ButtonData,
  logarithms,
  Ratio,
  rational_data,
  rational_data_2,
  rational_data_3,
} from '../../api/data/Secondary'
import { InlineMath, BlockMath } from 'react-katex'
import 'katex/dist/katex.min.css'
import {
  ButtonGroupData,
  ButtonsGroups,
  SecondaryTables,
} from '../SecondaryCommons/SecondaryTables'
const SecondaryLogarithms = (props) => {
  return (
    <Fragment>
      <Container>
        <ToastMessage />
        <Row className='mt-4'>
          <Col xl='4' sm='4' md='4' lg='4'>
            <Row>
              <Col xl='12' sm='12' md='12' lg='12'>
                <Heading>Secondary</Heading>
              </Col>
              <Col xl='12' sm='12' md='12' lg='12' className='mb-5'>
                <SubHeading>
                  Further rationals and ratio, surds and logarithms
                </SubHeading>
              </Col>
            </Row>
          </Col>
          <Col xl='4' sm='4' md='4' lg='4'>
            <BoxCard>
              <Link to='/secondary/integers-rationals'>
                <BoxBody>
                  <BoxInLine>
                    <BoxHeading>Secondary</BoxHeading>
                    <BoxPara>Integers and rationals</BoxPara>
                    <BoxImage>
                      <img src={Right_arrow} alt='right-arrow' />
                    </BoxImage>
                  </BoxInLine>
                </BoxBody>
              </Link>
            </BoxCard>
          </Col>
          <Col xl='4' sm='4' md='4' lg='4'>
            <BoxCard>
              <Link to='/secondary/algebra-trigonometry'>
                <BoxBody>
                  <BoxInLine>
                    <BoxHeading>Secondary</BoxHeading>
                    <BoxPara>Algebra and trigonometry</BoxPara>
                    <BoxImage>
                      <img src={Right_arrow} alt='right-arrow' />
                    </BoxImage>
                  </BoxInLine>
                </BoxBody>
              </Link>
            </BoxCard>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row>
          <Col xl='6' sm='12' md='6' lg='6' xs='12'>
            <Card>
              <FullBoxHeading>Further rational and ratio</FullBoxHeading>
              <FullBoxBody>
                {/* Table O.N.E */}
                <SecondaryTables List={rational_data} />
                {/*  */}
                <SecondaryTables
                  title={'For 2 < x < 10:'}
                  List={rational_data_2}
                />

                <PlainBox>
                  <Row>
                    <Col
                      sm='12'
                      md='12'
                      lg='12'
                      xs='12'
                      className='text-center mt-2'>
                      <p className='mb-0'>
                        For{'  '} <InlineMath math='2  \le x < 10:' />
                      </p>
                    </Col>
                    <Col sm='10' md='10' lg='10' xs='10'>
                      <TableCustoms1 borderless>
                        <tbody>
                          {rational_data_3.map((data, i) => (
                            <tr key={i}>
                              <th>
                                <button>{data.title}</button>
                              </th>
                              <td>
                                <button
                                  dangerouslySetInnerHTML={{
                                    __html: data.ops1,
                                  }}></button>
                              </td>
                              <td>
                                <button
                                  dangerouslySetInnerHTML={{
                                    __html: data.ops2,
                                  }}></button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </TableCustoms1>
                    </Col>
                  </Row>
                </PlainBox>
                <ButtonGroupData
                  title={'What is 100% given'}
                  List={ButtonData}
                />
                <SecondaryTables title={'Ratio'} List={Ratio} />
                <PlainBox className='mb-4'>
                  <Col
                    sm='12'
                    md='12'
                    lg='12'
                    xs='12'
                    className='text-center mt-2'>
                    <p className='mb-0'>Abstract fraction operations</p>
                  </Col>
                  <Col sm='12' md='12' lg='12' xs='12'>
                    <BTNGROUP1>
                      <button>
                        <BlockMath math='x * y  = \frac{1}{x} \pm  \frac{1}{y}' />
                      </button>
                      <button>
                        <BlockMath math='x * y  = \frac{x}{y} \pm  \frac{1}{x}' />
                      </button>
                      <button>
                        <BlockMath math='x * y  = \frac{x}{y} \pm  \frac{x \pm 1}{y \pm 1}' />
                      </button>
                      <button>
                        <BlockMath math='x * y  = 1 - \frac{x}{y}' />
                      </button>
                      <button>
                        <BlockMath math='x * y  = \frac{x}{y} \times \frac{x \pm 1}{y \pm 1}' />
                      </button>
                      <button>
                        <BlockMath math='x * y  = \frac{x}{y} \div \frac{x \pm 1}{y \pm 1}' />
                      </button>
                    </BTNGROUP1>
                  </Col>
                </PlainBox>
              </FullBoxBody>
            </Card>
          </Col>
          <Col xl='6' sm='12' md='6' lg='6' xs='12'>
            <Card>
              <FullBoxHeading>Surds and logs</FullBoxHeading>
              <FullBoxBody>
                <PlainBox>
                  <Col
                    sm='12'
                    md='12'
                    lg='12'
                    xs='12'
                    className='text-center mt-2'>
                    <p className='mb-0'>Surds</p>
                  </Col>
                  <Col sm='12' md='12' lg='12' xs='12'>
                    <SurdsBTNGroup>
                      <button>Simplify</button>
                      <button>Complete</button>
                      <button>Add</button>
                      <button>Subtract</button>
                      <button>Multiply</button>
                      <button>Divide</button>
                    </SurdsBTNGroup>
                  </Col>
                </PlainBox>
                <PlainBox>
                  <Row>
                    <Col
                      sm='12'
                      md='12'
                      lg='12'
                      xs='12'
                      className='text-center mt-4'>
                      <p className='mb-0'>Logarithms</p>
                    </Col>
                    <ButtonsGroups
                      title={'Simplify logs of:'}
                      List={logarithms}
                    />
                    <Col sm='6' md='6' lg='6' xs='6'>
                      <LogButton>Find value given:</LogButton>
                    </Col>
                    <Col sm='12' md='12' lg='12' xs='12'>
                      <LogButtonGroup smallsize={1}>
                        <button>logx Y ≈ N</button>
                        <button>logx Y = N and logA B = M</button>
                        <Button>Solve log equation</Button>
                      </LogButtonGroup>
                    </Col>
                  </Row>
                </PlainBox>
              </FullBoxBody>
            </Card>
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col xl='3' sm='6' md='3' lg='3' xs='6' className='mt-3'>
            <Link to='#!'>
              <SubmitBTN>Enter Names & Messages </SubmitBTN>
            </Link>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}

SecondaryLogarithms.propTypes = {}

export default SecondaryLogarithms

// const
const Heading = styled.h1`
  ${(props) => props.theme.primaryHeading}
`
const SubHeading = styled.h5`
  ${(props) => props.theme.subHeading}
`

const BoxBody = styled(CardBody)`
  background-color: #fbfbfb;
  padding: ' 9px 1rem';
`
const BoxCard = styled(Card)`
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  a {
    text-decoration: none;
  }
`
const BoxInLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const BoxHeading = styled.h3`
  font-size: 18px;
  margin-bottom: 0px;
  font-family: 'Saira', sans-serif;
  flex: 1 1 auto;
  color: black;
`
const BoxPara = styled.p`
  font-size: 13px;
  font-weight: 400;
  color: #7c7c7c;
  font-family: 'Roboto', sans-serif;
  margin-bottom: 0px;
  text-decoration: none;
  flex: 1 1 49%;
  @media (max-width: 1200px) {
    flex: 1 1 25%;
  }
`

const BoxImage = styled.div``

const FullBoxHeading = styled(CardHeader)`
  background-color: #fbfbfb;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: 500;
  text-transform: capitalize;
`

const FullBoxBody = styled(CardBody)`
  background-color: #fbfbfb;
`

const PlainBox = styled.div`
  p {
    font-size: 18px;
    font-family: ${(props) => props.theme.SeraiFont};
  }
`
const TableCustoms1 = styled(Table)`
  tr {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  th {
    width: 35%;
    margin-right: 30px;
    button {
      width: 100%;
      border: none;
      text-align: start;
      background: #fff;
      box-shadow: 0px 4px 9px rgb(0 0 0 / 5%);
      border-radius: 5px;
      font-size: 13px;
      padding: 5px 10px;
      font-family: ${(props) => props.theme.RobotoFont};
      font-weight: 500;
    }
  }

  td {
    button {
      width: 115px;
      height: 29px;
      border: none;
      border-radius: 27px;
      color: ${(props) => props.theme.buttonText};
      background: ${(props) => props.theme.primaryColor};
      font-family: ${(props) => props.theme.SeraiFont};
      box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.05);
      font-size: 12px;
    }
  }
  @media (max-width: 1200px) {
    th {
      margin-right: 10px;
    }
    td {
      button {
        width: 100px;
      }
    }
  }
`
const BTNGROUP = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 10px;
  button {
    border: none;
    color: white;
    background-color: ${(props) => props.theme.primaryColor};
    margin: 5px 0px;
    border-radius: 27px;
    font-size: 12px;
    height: 29px;
    padding: 0px 40px;
    box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.05);
  }
  @media (max-width: 1200px) {
    button {
      padding: 0px 23px;
    }
  }
`
const BTNGROUP1 = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 10px;

  button {
    border: none;
    color: white;
    background-color: ${(props) => props.theme.primaryColor};
    margin: 5px 0px;
    border-radius: 27px;
    font-size: 12px;
    width: 186.93px;
    box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.05);
  }
`
const SurdsBTNGroup = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 10px;
  button {
    border: none;
    color: white;
    background-color: ${(props) => props.theme.primaryColor};
    margin: 5px 10px;
    border-radius: 27px;
    font-size: 12px;
    width: 135px;
    box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.05);
    padding: 5px 10px;
  }
  @media (min-width: 1400px) {
    margin: 5px 35px;
  }
`

const LogButton = styled.button`
  width: 100%;
  margin-left: 10px;
  border: none;
  font-family: 'Saira';
  text-transform: capitalize;
  text-align: left;
  padding: 4px 8px;
  border-radius: 5px;
  background: white;
  box-shadow: 0px 4px 9px rgb(0 0 0 / 5%);
  font-weight: 500;
  margin-top: 20px;
`
const LogButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
  button {
    border: none;
    color: ${(props) => props.theme.buttonText};
    background-color: ${(props) => props.theme.primaryColor};
    padding: 5px 75px;
    border-radius: 27px;
    margin: 5px 0px;
    font-size: 12px;
    font-family: ${(props) => props.theme.SeraiFont};
  }
  @media (max-width: 1200px) {
    button {
      padding: 5px 48px;
    }
  }
`
const Button = styled.button`
  padding: 5px 60px !important;
  @media (max-width: 1200px) {
    padding: 5px 30px !important;
  }
`
const SubmitBTN = styled.button`
  border: none;
  background-color: ${(props) => props.theme.primaryColor};
  border-radius: 7px;
  color: white;
  margin-left: 20px;
  padding: 12px 35px;
  &:active {
  }
  @media (max-width: 1200px) {
    padding: 12px 14px;
  }
`
