import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import { HashRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import TheLayout from "./DefaultComponets/TheLayout/TheLayout";
import PdfLayout from "./DefaultComponets/TheLayout/pdfLayout";
import store from "./store";
import theme from "./theme";
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";
// import { getLabelsName } from "./action/labelAction";

class App extends Component {
  // componentDidMount() {
  //   store.dispatch(getLabelsName());
  // }

  render() {
    return (
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Router>
            <Switch>
              <Route
                exact
                path="/pdf"
                name="Pdf"
                render={(props) => <PdfLayout {...props} />}
              />
              <Route
                path="/"
                name="Home"
                render={(props) => <TheLayout {...props} />}
              />
            </Switch>
          </Router>
        </ThemeProvider>
      </Provider>
    );
  }
}

export default App;
