import React, { Fragment, useEffect, useState } from "react";
// import PropTypes from 'prop-types';
import { Col, Row, Card, CardBody, Container } from "reactstrap";
import styled from "styled-components";
import EarlyPrimaryFooter from "./EarlyPrimaryFooter";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect, useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import {
  primaryMathslist,
  getadditionList,
  getSubtractionList,
  getSubtractionFromList,
  getMultiplyList,
  getDividedList,
  getContinueadditionList,
  getContinueSubtract,
  getOnMultipleList,
  getOffMultipleList,
  getOnMultipleListSubtract,
  getOffMultipleListSubtract,
} from "../../action/primaryAction";
import { Link } from "react-router-dom";
import ToastMessage from "../../DefaultComponets/ToastMessage/ToastMessage";
import {
  add,
  add_to_make,
  divide,
  multiply,
  subtract,
  subtractFrom,
  continue_Number,
  starting_Number,
  starting_On_Number,
  starting_Off_Number,
  sub_continue_Number,
  sub_starting_Number,
  sub_starting_On_Number,
  sub_starting_Off_Number,
} from "../../api/data/earlyPrimary";

const EarlyPrimary = (props) => {
  const MathList = useSelector((state) => state.primaryMath);
  const removeValue = useDispatch();
  const [cdata, setCdata] = useState("");
  const [sdata, setSdata] = useState("");
  const [continue_sub_data, set_Continue_Sub_data] = useState("");
  const [starting_sub_data, set_Starting_Sub_data] = useState("");

  const [continueRandom, setContinueRandom] = useState("");
  const [startingRandom, setStartingRandom] = useState("");
  const [startingOffRandom, setStartingOffRandom] = useState("");

  const [continue_off_Random, setContinue_Off_Random] = useState("");
  const [starting_on_Random, setStarting_On_Random] = useState("");
  const [starting_Off_Random, setStarting_Off_Random] = useState("");

  const [subtract_continueRandom, setSubtract_ContinueRandom] = useState("");
  const [subtract_startingRandom, setSubtract_StartingRandom] = useState("");
  const [subtract_startingOffRandom, setSubtract_StartingOffRandom] =
    useState("");
  const [subtract_continue_off_Random, setSubtract_Continue_Off_Random] =
    useState("");
  const [subtract_starting_on_Random, setSubtract_Starting_On_Random] =
    useState("");
  const [subtract_starting_Off_Random, setSubtract_Starting_Off_Random] =
    useState("");
  //
  const [displayError, setDisplayError] = useState([]);

  const toastmessage = () => {
    toast.success("You can select maximum 3 activities only");
  };
  const [showModal, setShowModal] = useState("block");
  const setdisplayErrorValue = (value, type) => {
    // type 1 add 2 remove
    if (type === 1) {
      let checkData = displayError.includes(value);
      if (!checkData) {
        setDisplayError([...displayError, value]);
      }
    } else {
      var index = displayError.indexOf(value);
      if (index !== -1) {
        displayError.splice(index, 1);
      }
      setDisplayError(displayError);
    }
  };
  useEffect(() => {
    removeValue({
      type: "DISPLAY_MESSAGE_LENGTH",
      payload: displayError,
    });
  }, [displayError]);

  useEffect(() => {
    if (MathList.maxLength) {
      setDisplayError(MathList.maxLength);
    }
  }, []);

  const onAddValue = (value, i, string) => {
    if (!MathList.add_to_make || value !== MathList.add_to_make) {
      let validation = true;
      if (displayError.length >= 3) {
        toastmessage();
        validation = false;
      }
      if (validation) {
        setdisplayErrorValue(string, 1);
        props.primaryMathslist(value, 2);
      }
    } else if (MathList.add_to_make === value) {
      setdisplayErrorValue(string, 2);
      removeValue({
        type: "ADD_TO_MARK_REMOVE",
      });
    }
  };
  const addsRandomValue = (string) => {
    if (!MathList.rand_add_to_make) {
      let validation = true;
      if (displayError.length >= 3) {
        toastmessage();
        validation = false;
      }
      if (validation) {
        setdisplayErrorValue(string, 1);
        const random = Math.floor(Math.random() * add_to_make.length);
        props.primaryMathslist(add_to_make[random], 2, true);
      }
    } else {
      setdisplayErrorValue(string, 2);
      removeValue({
        type: "ADD_TO_MARK_REMOVE",
      });
    }
  };
  const onAddValue10 = (string) => {
    if (MathList.add_to_make_10) {
      removeValue({
        type: "PRIMAY_MATHS_REMOVE",
      });
      setdisplayErrorValue(string, 2);
    } else {
      if (displayError.length >= 3) {
        toastmessage();
        return;
      }
      setdisplayErrorValue(string, 1);
      props.primaryMathslist(10, 1);
    }
  };

  // second value select
  const onAddValue1 = (value, i, string) => {
    if (!MathList.add_value || value !== MathList.add_value) {
      let validation = true;
      if (displayError.length >= 3) {
        toastmessage();
        validation = false;
      }

      setdisplayErrorValue(string, 1);
      validation && props.getadditionList(value);
    } else if (MathList.add_value === value) {
      setdisplayErrorValue(string, 2);
      removeValue({
        type: "ADD_REMOVE",
      });
    }
  };
  // randmon number
  const addsRandomValue1 = (string) => {
    if (!MathList.rand_add_value) {
      let validation = true;
      if (displayError.length >= 3) {
        toastmessage();
        validation = false;
      }
      if (validation) {
        setdisplayErrorValue(string, 1);
        const random = Math.floor(Math.random() * add.length);
        props.getadditionList(add[random], true);
      }
    } else {
      setdisplayErrorValue(string, 2);
      removeValue({
        type: "ADD_REMOVE",
      });
    }
  };

  // Subtract
  const onSubValue = (value, i, string) => {
    if (!MathList.subtract_value || value !== MathList.subtract_value) {
      let validation = true;
      if (displayError.length >= 3) {
        toastmessage();
        validation = false;
      }

      if (validation) {
        setdisplayErrorValue(string, 1);
        props.getSubtractionList(value);
      }
    } else if (MathList.subtract_value === value) {
      setdisplayErrorValue(string, 2);
      removeValue({
        type: "SUBTRACT_REMOVE",
      });
    }
  };
  const subtractRandomValue = (string) => {
    if (!MathList.rand_subtract_value) {
      let validation = true;
      if (displayError.length >= 3) {
        toastmessage();
        validation = false;
      }
      if (validation) {
        setdisplayErrorValue(string, 1);
        const random = Math.floor(Math.random() * subtract.length);
        props.getSubtractionList(subtract[random], true);
      }
    } else {
      setdisplayErrorValue(string, 2);
      removeValue({
        type: "SUBTRACT_REMOVE",
      });
    }
  };
  //  Subtract from
  const onSubFromValue = (value, i, string) => {
    if (!MathList.subtractFrom_value || value !== MathList.subtractFrom_value) {
      let validation = true;
      if (displayError.length >= 3) {
        toastmessage();
        validation = false;
      }

      if (validation) {
        setdisplayErrorValue(string, 1);
        props.getSubtractionFromList(value, 2);
      }
    } else if (MathList.subtractFrom_value === value) {
      setdisplayErrorValue(string, 2);
      removeValue({
        type: "SUBTRACT_FROM_REMOVE",
      });
    }
  };

  const subRandomValue = (string) => {
    if (!MathList.rand_subtractFrom_value) {
      let validation = true;
      if (displayError.length >= 3) {
        toastmessage();
        validation = false;
      }
      if (validation) {
        setdisplayErrorValue(string, 1);
        const random = Math.floor(Math.random() * subtractFrom.length);
        props.getSubtractionFromList(subtractFrom[random], 2, true);
      }
    } else {
      setdisplayErrorValue(string, 2);
      removeValue({
        type: "SUBTRACT_FROM_REMOVE",
      });
    }
  };

  const onSubtractFromClick = (string) => {
    if (!MathList.subtractFromTEN_value) {
      let validation = true;
      if (displayError.length >= 3) {
        toastmessage();
        validation = false;
      }
      if (validation) {
        setdisplayErrorValue(string, 1);
        props.getSubtractionFromList(10, 1);
      }
    } else {
      setdisplayErrorValue(string, 2);
      removeValue({
        type: "SUBTRACT_FROM_10_RAMOVE",
      });
    }
  };

  // Multiplication
  const onMultiValue = (value, i, string) => {
    if (!MathList.multiple_value || value !== MathList.multiple_value) {
      let validation = true;
      if (displayError.length >= 3) {
        toastmessage();
        validation = false;
      }

      if (validation) {
        setdisplayErrorValue(string, 1);
        props.getMultiplyList(value);
      }
    } else if (MathList.multiple_value === value) {
      setdisplayErrorValue(string, 2);
      removeValue({
        type: "MULTIPLE_LIST_REMOVE",
      });
    }
  };

  const MultiRandom = (string) => {
    if (!MathList.rand_multiple_value) {
      let validation = true;
      if (displayError.length >= 3) {
        toastmessage();
        validation = false;
      }
      if (validation) {
        setdisplayErrorValue(string, 1);
        const random = Math.floor(Math.random() * multiply.length);
        props.getMultiplyList(multiply[random], 2, true);
      }
    } else {
      setdisplayErrorValue(string, 2);
      removeValue({
        type: "MULTIPLE_LIST_REMOVE",
      });
    }
  };
  //conitnue random

  const onContinueValue = (value, i, string, value2) => {
    if (
      (!MathList.continue_add_value || value !== MathList.continue_add_value) &&
      (!MathList.starting_add_value || value2 !== MathList.starting_add_value)
    ) {
      let validation = true;
      if (displayError.length >= 3) {
        toastmessage();
        validation = false;
      }
      if (validation) {
        setCdata(value);
        setSdata(value2);

        if (value2 !== undefined || value !== undefined) {
          if (value && value2) {
            setdisplayErrorValue(string, 1);
            props.getContinueadditionList(value, value2);
          }
        }
      }
    } else if (
      MathList.continue_add_value === value &&
      MathList.starting_add_value === value2
    ) {
      setdisplayErrorValue(string, 2);
      setCdata("");
      setSdata("");
      removeValue({
        type: "ADD_CONTINUE_REMOVE",
      });
    }
  };

  const onMultipleRandom = (e, string) => {
    e.preventDefault();

    let multipleOn = [];
    let multipleOff = [];
    if (!MathList.continue_on_multiple && !MathList.starting_on_multiple) {
      let validation = true;
      if (displayError.length >= 3) {
        toastmessage();
        validation = false;
      }
      if (validation) {
        setdisplayErrorValue(string, 1);

        let cNumber = continueRandom;
        let onNumber = startingRandom;
        let offNumber = startingOffRandom;
        // cNumber = _.shuffle(cNumber);
        cNumber = _.sample(continue_Number);
        setContinueRandom(cNumber);

        /* 
          ┌─────────────────────────────────────────────────────────────────────────────┐        
          │         Random On Multiple and Random Off Multiple off                      │
          │                     Dont change this code                                   │
          └─────────────────────────────────────────────────────────────────────────────┘
         */

        for (let i = 6; i <= 12; i++) {
          let result = i * cNumber;
          if (result) {
            multipleOn.push(result);
          }
        }
        // console.log("🚀MultipleOn", multipleOn);
        let inc = multipleOn[0] + 1;
        for (let j = 5; j < 12; j++) {
          multipleOff.push(inc++);
        }
        // console.log("🚀multipleOff", multipleOff);

        let CompareTwo = _.intersectionWith(multipleOn, multipleOff, _.isEqual);
        multipleOff = _.pullAllWith(multipleOff, CompareTwo, _.isEqual);

        /* 
         ┌─────────────────────────────────────────────────────────────────────────────┐     
         │         Random On Multiple and Random Off Multiple off   ^                  │
         │                     Dont change this code                ||                 │
         └─────────────────────────────────────────────────────────────────────────────┘
        */
        onNumber = _.sample(multipleOn);
        setStartingRandom(onNumber);
        offNumber = _.sample(multipleOff);
        setStartingOffRandom(offNumber);
        props.getOnMultipleList(cNumber, onNumber);
      }
    } else {
      setdisplayErrorValue(string, 2);
      setContinueRandom("");
      setStartingRandom("");
      setStartingOffRandom("");
      removeValue({
        type: "ADD_ON_MULTIPLE_REMOVE",
      });
    }
  };

  const offMultipleRandom = (e, string) => {
    e.preventDefault();

    let multipleOn = [];
    let multipleOff = [];
    if (!MathList.continue_off_multiple && !MathList.starting_off_multiple) {
      let validation = true;
      if (displayError.length >= 3) {
        toastmessage();
        validation = false;
      }
      if (validation) {
        setdisplayErrorValue(string, 1);

        let cNumber = continue_off_Random;
        let onNumber = starting_on_Random;
        let offNumber = starting_Off_Random;

        // cNumber = _.shuffle(cNumber);
        cNumber = _.sample(continue_Number);
        setContinue_Off_Random(cNumber);

        /* 
          ┌─────────────────────────────────────────────────────────────────────────────┐        
          │         Random On Multiple and Random Off Multiple off                      │
          │                     Dont change this code                                   │
          └─────────────────────────────────────────────────────────────────────────────┘
         */

        for (let i = 6; i <= 12; i++) {
          let result = i * cNumber;
          if (result) {
            multipleOn.push(result);
          }
        }
        // console.log("🚀MultipleOn", multipleOn);
        let inc = multipleOn[0] + 1;
        for (let j = 5; j < 12; j++) {
          multipleOff.push(inc++);
        }
        // console.log("🚀multipleOff", multipleOff);

        let CompareTwo = _.intersectionWith(multipleOn, multipleOff, _.isEqual);
        multipleOff = _.pullAllWith(multipleOff, CompareTwo, _.isEqual);

        /* 
         ┌─────────────────────────────────────────────────────────────────────────────┐        
         │         Random On Multiple and Random Off Multiple off   ^                  │
         │                     Dont change this code                ||                 │
         └─────────────────────────────────────────────────────────────────────────────┘
        */

        onNumber = _.sample(multipleOn);
        setStarting_On_Random(onNumber);
        offNumber = _.sample(multipleOff);
        setStarting_Off_Random(offNumber);
        props.getOffMultipleList(cNumber, offNumber);
      }
    } else {
      setdisplayErrorValue(string, 2);
      setContinue_Off_Random("");
      setStarting_On_Random("");
      setStarting_Off_Random("");
      removeValue({
        type: "ADD_OFF_MULTIPLE_REMOVE",
      });
    }
  };

  const subOnMultipleRandom = (e, string) => {
    e.preventDefault();

    let multipleOn = [];
    let multipleOff = [];
    if (
      !MathList.sub_continue_on_multiple &&
      !MathList.sub_starting_on_multiple
    ) {
      let validation = true;
      if (displayError.length >= 3) {
        toastmessage();
        validation = false;
      }
      if (validation) {
        setdisplayErrorValue(string, 1);

        let cNumber = subtract_continueRandom;
        let onNumber = subtract_startingRandom;
        let offNumber = subtract_startingOffRandom;

        // cNumber = _.shuffle(cNumber);
        cNumber = _.sample(continue_Number);
        setSubtract_ContinueRandom(cNumber);

        /* 
          ┌─────────────────────────────────────────────────────────────────────────────┐        
          │         Random On Multiple and Random Off Multiple off                      │
          │                     Dont change this code                                   │
          └─────────────────────────────────────────────────────────────────────────────┘
         */

        for (let i = 6; i <= 12; i++) {
          let result = i * cNumber;
          if (result) {
            multipleOn.push(result);
          }
        }
        // console.log("🚀MultipleOn", multipleOn);
        let inc = multipleOn[0] + 1;
        for (let j = 5; j < 12; j++) {
          multipleOff.push(inc++);
        }
        // console.log("🚀multipleOff", multipleOff);

        let CompareTwo = _.intersectionWith(multipleOn, multipleOff, _.isEqual);
        multipleOff = _.pullAllWith(multipleOff, CompareTwo, _.isEqual);

        /* 
         ┌─────────────────────────────────────────────────────────────────────────────┐        
         │         Random On Multiple and Random Off Multiple off   ^                  │
         │                     Dont change this code                ||                 │
         └─────────────────────────────────────────────────────────────────────────────┘
        */
        onNumber = _.sample(multipleOn);
        setSubtract_StartingRandom(onNumber);
        offNumber = _.sample(multipleOff);
        setSubtract_StartingOffRandom(offNumber);
        props.getOnMultipleListSubtract(cNumber, onNumber);
      }
    } else {
      setdisplayErrorValue(string, 2);
      setSubtract_ContinueRandom("");
      setSubtract_StartingRandom("");
      setSubtract_StartingOffRandom("");
      removeValue({
        type: "SUB_ON_MULTIPLE_REMOVE",
      });
    }
  };

  const subOffMultipleRandom = (e, string) => {
    e.preventDefault();

    let multipleOn = [];
    let multipleOff = [];
    if (
      !MathList.sub_continue_off_multiple &&
      !MathList.sub_starting_off_multiple
    ) {
      let validation = true;
      if (displayError.length >= 3) {
        toastmessage();
        validation = false;
      }
      if (validation) {
        setdisplayErrorValue(string, 1);

        let cNumber = subtract_continue_off_Random;
        let onNumber = subtract_starting_on_Random;
        let offNumber = subtract_starting_Off_Random;

        cNumber = _.shuffle(cNumber);
        cNumber = _.sample(continue_Number);
        setSubtract_Continue_Off_Random(cNumber);

        /* 
          ┌─────────────────────────────────────────────────────────────────────────────┐        
          │         Random On Multiple and Random Off Multiple off                      │
          │                     Dont change this code                                   │
          └─────────────────────────────────────────────────────────────────────────────┘
         */

        for (let i = 6; i <= 12; i++) {
          let result = i * cNumber;
          if (result) {
            multipleOn.push(result);
          }
        }
        // console.log("🚀MultipleOn", multipleOn);
        let inc = multipleOn[0] + 1;
        for (let j = 5; j < 12; j++) {
          multipleOff.push(inc++);
        }
        // console.log("🚀multipleOff", multipleOff);

        let CompareTwo = _.intersectionWith(multipleOn, multipleOff, _.isEqual);
        multipleOff = _.pullAllWith(multipleOff, CompareTwo, _.isEqual);

        /* 
         ┌─────────────────────────────────────────────────────────────────────────────┐        
         │         Random On Multiple and Random Off Multiple off   ^                  │
         │                     Dont change this code                ||                 │
         └─────────────────────────────────────────────────────────────────────────────┘
        */

        onNumber = _.sample(multipleOn);
        setSubtract_Starting_On_Random(onNumber);
        offNumber = _.sample(multipleOff);
        setSubtract_Starting_Off_Random(offNumber);
        props.getOffMultipleListSubtract(cNumber, offNumber);
      }
    } else {
      setdisplayErrorValue(string, 2);
      setSubtract_Continue_Off_Random("");
      setSubtract_Starting_On_Random("");
      setSubtract_Starting_Off_Random("");
      removeValue({
        type: "SUB_OFF_MULTIPLE_REMOVE",
      });
    }
  };

  // subtract-continue value
  const onSubtractContinueValue = (value, i, string, value2) => {
    if (
      (!MathList.continue_sub_value || value !== MathList.continue_sub_value) &&
      (!MathList.starting_sub_value || value2 !== MathList.starting_sub_value)
    ) {
      let validation = true;
      if (displayError.length >= 3) {
        toastmessage();
        validation = false;
      }
      if (validation) {
        setdisplayErrorValue(string, 1);
        set_Continue_Sub_data(value);
        set_Starting_Sub_data(value2);

        if (value2 !== undefined && value !== undefined) {
          if (value && value2) {
            props.getContinueSubtract(value, value2);
          }
        }
      }
    } else if (
      MathList.continue_sub_value === value ||
      MathList.starting_sub_value === value2
    ) {
      setdisplayErrorValue(string, 2);
      set_Continue_Sub_data("");
      set_Starting_Sub_data("");
      removeValue({
        type: "SUB_CONTINUE_REMOVE",
      });
    }
  };

  // Divination
  const onDivideValue = (value, i, string) => {
    if (!MathList.divided_value || value !== MathList.divided_value) {
      let validation = true;
      if (displayError.length >= 3) {
        toastmessage();
        validation = false;
      }

      if (validation) {
        setdisplayErrorValue(string, 1);
        props.getDividedList(value);
      }
    } else if (MathList.divided_value === value) {
      setdisplayErrorValue(string, 2);
      removeValue({
        type: "DIVIDED_LIST_REMOVE",
      });
    }
  };

  const DivideRadom = (string) => {
    if (!MathList.rand_divided_value) {
      let validation = true;
      if (displayError.length >= 3) {
        toastmessage();
        validation = false;
      }
      if (validation) {
        setdisplayErrorValue(string, 1);
        const random = Math.floor(Math.random() * divide.length);
        props.getDividedList(divide[random], true);
      }
    } else {
      setdisplayErrorValue(string, 2);
      removeValue({
        type: "DIVIDED_LIST_REMOVE",
      });
    }
  };

  // close
  const addMakeClose = (e) => {
    setdisplayErrorValue(e.target.value, 2);
    removeValue({
      type: "PRIMAY_MATHS_REMOVE",
    });
  };
  const addClose = (e) => {
    setdisplayErrorValue(e.target.value, 2);

    removeValue({
      type: "ADD_TO_MARK_REMOVE",
    });
    // setAddArray(...addArray)
  };
  const addClose1 = (e) => {
    setdisplayErrorValue(e.target.value, 2);
    removeValue({
      type: "ADD_REMOVE",
    });
  };
  const subClose = (e) => {
    setdisplayErrorValue(e.target.value, 2);
    removeValue({
      type: "SUBTRACT_REMOVE",
    });
  };
  const continueAddClose = (e) => {
    setdisplayErrorValue(e.target.value, 2);
    setCdata("");
    setSdata("");
    removeValue({
      type: "ADD_CONTINUE_REMOVE",
    });
  };
  const onMultipleAddClose = (e) => {
    setdisplayErrorValue(e.target.value, 2);

    setContinueRandom("");
    setStartingRandom("");
    setStartingOffRandom("");
    removeValue({
      type: "ADD_ON_MULTIPLE_REMOVE",
    });
  };
  const offMultipleAddClose = (e) => {
    setdisplayErrorValue(e.target.value, 2);
    setContinue_Off_Random("");
    setStarting_On_Random("");
    setStarting_Off_Random("");
    removeValue({
      type: "ADD_OFF_MULTIPLE_REMOVE",
    });
  };
  const continueSubClose = (e) => {
    setdisplayErrorValue(e.target.value, 2);
    set_Continue_Sub_data("");
    set_Starting_Sub_data("");
    removeValue({
      type: "SUB_CONTINUE_REMOVE",
    });
  };
  const subOnMultipleClose = (e) => {
    setdisplayErrorValue(e.target.value, 2);
    setSubtract_ContinueRandom("");
    setSubtract_StartingRandom("");
    setSubtract_StartingOffRandom("");
    removeValue({
      type: "SUB_ON_MULTIPLE_REMOVE",
    });
  };

  const subOffMultipleClose = (e) => {
    setdisplayErrorValue(e.target.value, 2);
    setSubtract_Continue_Off_Random("");
    setSubtract_Starting_On_Random("");
    setSubtract_Starting_Off_Random("");
    removeValue({
      type: "SUB_OFF_MULTIPLE_REMOVE",
    });
  };

  const subFrom10 = (e) => {
    setdisplayErrorValue(e.target.value, 2);
    removeValue({
      type: "SUBTRACT_FROM_10_RAMOVE",
    });
  };
  const subFromClose = (e) => {
    setdisplayErrorValue(e.target.value, 2);
    removeValue({
      type: "SUBTRACT_FROM_REMOVE",
    });
  };
  const multiclose = (e) => {
    setdisplayErrorValue(e.target.value, 2);

    removeValue({
      type: "MULTIPLE_LIST_REMOVE",
    });
  };
  const divClose = (e) => {
    setdisplayErrorValue(e.target.value, 2);
    removeValue({
      type: "DIVIDED_LIST_REMOVE",
    });
  };

  return (
    <Fragment>
      <Container >
        <Row>
          <ToastMessage />
          <Col xl="12" sm="12" md="12" lg="12">
            {/* <Heading>Early Primary Maths Tasks</Heading> */}
            <Heading>
              Step2: choose any three tasks from this set of Early Primary maths
              tasks
            </Heading>
          </Col>
          {/* <Col xl="12" sm="12" md="12" lg="12" className="mb-3">
          <SubHeading>Choose any three tasks from this set</SubHeading>
        </Col> */}
          <Col xl="5" sm="5" lg="5" md="5">
            <Row>
              <Boxed className="card-shadow">
                <BoxBody>
                  {/*  */}
                  <ChildBoxed
                    onClick={(e) => onAddValue10("Add_make_10")}
                    active={MathList.add_to_make_10}
                  >
                    <p> Add to make 10</p>
                  </ChildBoxed>

                  {/*Add to make */}

                  <ChildBoxedMake>
                    <CardHeading>
                      <p>Add to make</p>
                      <ButtonOrange
                        disabled={MathList.add_to_make ? true : false}
                        onClick={() => addsRandomValue("Add_make")}
                        active={MathList.rand_add_to_make}
                      >
                        Random Decade
                      </ButtonOrange>
                    </CardHeading>
                    {/*  */}
                    <Col lg="12" md="12">
                      <CustomsButton>
                        {add_to_make.map((add, i) => (
                          <MarkBTN
                            key={i}
                            disabled={MathList.rand_add_to_make ? true : false}
                            onClick={(e) => onAddValue(add, i, "Add_make")}
                            actived={
                              add === MathList.add_to_make ? true : false
                            }
                          >
                            {add}
                          </MarkBTN>
                        ))}
                      </CustomsButton>
                    </Col>
                  </ChildBoxedMake>

                  {/* Add */}
                  <ChildBoxedMake className="mt-3">
                    <CardHeading>
                      <p>Add</p>
                      <ButtonOrange
                        disabled={MathList.add_value}
                        onClick={() => addsRandomValue1("Add")}
                        active={MathList.rand_add_value}
                      >
                        Random Number
                      </ButtonOrange>
                    </CardHeading>
                    {/*  */}
                    <Col lg="12" md="12">
                      <CustomsButton>
                        {add.map((add, i) => (
                          <MarkBTN
                            key={i}
                            valueLength={add}
                            disabled={MathList.rand_add_value}
                            onClick={(e) => onAddValue1(add, i, "Add")}
                            actived={add === MathList.add_value}
                          >
                            {add}
                          </MarkBTN>
                        ))}
                      </CustomsButton>
                    </Col>
                  </ChildBoxedMake>

                  {/* continue adding */}
                  <ChildBoxedMake className="mt-3">
                    {/*  */}
                    <CardHeading>
                      <p>Three Tasks To Practise Continued Adding:</p>
                    </CardHeading>
                    <ChildBoxedMake className="mt-0">
                      <Col lg="12" md="12">
                        <CustomsButton>
                          <CustomsPara>Continue adding</CustomsPara>
                          {continue_Number.map((cAdd, i) => (
                            <MarkBTN
                              key={i}
                              valueLength={cAdd}
                              // disabled={MathList.rand_continue_add_value}
                              onClick={(e) =>
                                onContinueValue(
                                  cAdd,
                                  i,
                                  "continue_adding_manually"
                                )
                              }
                              actived={cAdd === cdata ? true : false}
                            >
                              {cAdd}
                            </MarkBTN>
                          ))}
                        </CustomsButton>
                      </Col>
                      {/* starting from */}
                      <Col lg="12" md="12">
                        <CustomsButton>
                          <CustomsPara>Starting from</CustomsPara>
                          {starting_Number.map((snum, i) => (
                            <MarkBTN
                              key={i}
                              valueLength={snum}
                              onClick={(e) =>
                                onContinueValue(
                                  cdata,
                                  i,
                                  "continue_adding_manually",
                                  snum
                                )
                              }
                              actived={snum === sdata ? true : false}
                            >
                              {snum}
                            </MarkBTN>
                          ))}
                        </CustomsButton>
                      </Col>
                    </ChildBoxedMake>
                    {/* random on multiple */}
                    <ChildBoxedMake className="mt-3">
                      {/*  */}
                      <Col lg="12" md="12">
                        <CustomsButton>
                          <CustomsParagraph>
                            Random Number chosen to continue adding, starting
                            from a random <b>on-multiple</b>
                          </CustomsParagraph>
                          <ButtonOrangeChild
                            // disabled={MathList.continue_add_value}
                            onClick={(e) =>
                              onMultipleRandom(e, "continue_add_on_multiple")
                            }
                            active={
                              continueRandom !== "" && startingRandom !== ""
                                ? true
                                : false
                            }
                          >
                            Select
                          </ButtonOrangeChild>
                        </CustomsButton>
                      </Col>
                      {/* starting from */}
                    </ChildBoxedMake>

                    {/* random off multiple */}
                    <ChildBoxedMake className="mt-3">
                      {/*  */}
                      <Col lg="12" md="12">
                        <CustomsButton>
                          <CustomsParagraph>
                            Random Number chosen to continue adding, starting
                            from a random <b>off-multiple</b>
                          </CustomsParagraph>
                          <ButtonOrangeChild
                            // disabled={MathList.starting_add_value && cdata === ""}

                            onClick={(e) =>
                              offMultipleRandom(e, "continue_add_off_multiple")
                            }
                            active={
                              continue_off_Random !== "" &&
                              starting_Off_Random !== ""
                                ? true
                                : false
                            }
                          >
                            Select
                          </ButtonOrangeChild>
                        </CustomsButton>
                      </Col>
                      {/* starting from */}
                    </ChildBoxedMake>
                  </ChildBoxedMake>
                </BoxBody>
              </Boxed>
            </Row>
          </Col>
          <Col xl="1" sm="1" lg="1" md="1"></Col>
          <Col xl="5" sm="5" lg="5" md="5">
            <Row>
              <Boxed className="card-shadow">
                <BoxBody>
                  {/*  */}
                  <ChildBoxed
                    onClick={() => onSubtractFromClick("Subtract_from_10")}
                    active={MathList.subtractFromTEN_value}
                  >
                    <p> Subtract from 10</p>
                  </ChildBoxed>

                  {/*Add to Subtract from */}

                  <ChildBoxedMake>
                    <CardHeading>
                      <p>Subtract from</p>
                      <ButtonOrange
                        disabled={MathList.subtractFrom_value}
                        onClick={() => subRandomValue("Subtract_from")}
                        active={MathList.rand_subtractFrom_value}
                      >
                        Random Decade
                      </ButtonOrange>
                    </CardHeading>
                    {/*  */}
                    <Col lg="12" md="12">
                      <CustomsButton>
                        {subtractFrom.map((sub, i) => (
                          <MarkBTN
                            key={i}
                            valueLength={sub}
                            disabled={MathList.rand_subtractFrom_value}
                            onClick={(e) =>
                              onSubFromValue(sub, i, "Subtract_from")
                            }
                            actived={sub === MathList.subtractFrom_value}
                          >
                            {sub}
                          </MarkBTN>
                        ))}
                      </CustomsButton>
                    </Col>
                  </ChildBoxedMake>

                  {/* Subtract */}
                  <ChildBoxedMake className="mt-3">
                    <CardHeading>
                      <p>Subtract</p>
                      <ButtonOrange
                        disabled={MathList.subtract_value ? true : false}
                        onClick={() => subtractRandomValue("subtract")}
                        active={MathList.rand_subtract_value}
                      >
                        Random Number
                      </ButtonOrange>
                    </CardHeading>
                    {/*  */}
                    <Col lg="12" md="12">
                      <CustomsButton>
                        {subtract.map((sub, i) => (
                          <MarkBTN
                            key={i}
                            valueLength={sub}
                            disabled={MathList.rand_subtract_value}
                            onClick={(e) => onSubValue(sub, i, "subtract")}
                            actived={sub === MathList.subtract_value}
                          >
                            {sub}
                          </MarkBTN>
                        ))}
                      </CustomsButton>
                    </Col>
                  </ChildBoxedMake>

                  {/* continue Subtract */}
                  <ChildBoxedMake className="mt-3">
                    <Col lg="12" md="12">
                      <CardHeading>
                        <p>Two Tasks To Practise Continued Subtraction</p>
                      </CardHeading>
                      <CustomsNewButton>
                        <CustomsParagraph>
                          Random Number chosen to continue subtracting, starting
                          from a random <b>on-multiple</b>
                        </CustomsParagraph>
                        <ButtonOrangeChild
                          onClick={(e) =>
                            subOnMultipleRandom(e, "subtract_on_multiple")
                          }
                          active={
                            subtract_continueRandom !== "" &&
                            subtract_startingRandom !== ""
                              ? true
                              : false
                          }
                        >
                          Select
                        </ButtonOrangeChild>
                      </CustomsNewButton>

                      <CustomsNewButton>
                        <CustomsParagraph>
                          Random Number chosen to continue subtracting, starting
                          from a random <b>off-multiple</b>
                        </CustomsParagraph>
                        <ButtonOrangeChild
                          onClick={(e) =>
                            subOffMultipleRandom(e, "subtract_off_multiple")
                          }
                          active={
                            subtract_continue_off_Random !== "" &&
                            subtract_starting_Off_Random !== ""
                              ? true
                              : false
                          }
                        >
                          Select
                        </ButtonOrangeChild>
                      </CustomsNewButton>
                    </Col>
                  </ChildBoxedMake>
                  {/* Multiple */}
                  <ChildBoxedMake className="mt-3">
                    <CardHeading>
                      <p>Multiply</p>
                      <ButtonOrange
                        disabled={MathList.multiple_value}
                        onClick={() => MultiRandom("Multiply")}
                        active={MathList.rand_multiple_value}
                      >
                        Random Number
                      </ButtonOrange>
                    </CardHeading>
                    {/*  */}
                    <Col lg="12" md="12">
                      <CustomsButton>
                        {multiply.map((multi, i) => (
                          <MarkBTN
                            key={i}
                            valueLength={multi}
                            disabled={MathList.rand_multiple_value}
                            onClick={(e) => onMultiValue(multi, i, "Multiply")}
                            actived={multi === MathList.multiple_value}
                          >
                            {multi}
                          </MarkBTN>
                        ))}
                      </CustomsButton>
                    </Col>
                  </ChildBoxedMake>

                  {/* Divide */}
                  <ChildBoxedMake className="mt-3">
                    <CardHeading>
                      <p>Divide</p>
                      <ButtonOrange
                        disabled={MathList.divided_value}
                        onClick={() => DivideRadom("Divide")}
                        active={MathList.rand_divided_value}
                      >
                        Random Number
                      </ButtonOrange>
                    </CardHeading>
                    {/*  */}
                    <Col lg="12" md="12">
                      <CustomsButton>
                        {divide.map((divide, i) => (
                          <MarkBTN
                            key={i}
                            valueLength={divide}
                            disabled={MathList.rand_divided_value}
                            onClick={(e) => onDivideValue(divide, i, "Divide")}
                            actived={divide === MathList.divided_value}
                          >
                            {divide}
                          </MarkBTN>
                        ))}
                      </CustomsButton>
                    </Col>
                  </ChildBoxedMake>
                </BoxBody>
              </Boxed>
            </Row>
          </Col>
          {!MathList.loading &&
          (MathList.primaryMathList.length > 0 ||
            MathList.addToMark.length > 0 ||
            MathList.add.length > 0 ||
            MathList.subtract.length > 0 ||
            MathList.subtractFromTEN.length > 0 ||
            MathList.subtractFrom.length > 0 ||
            MathList.subtractFromTEN.length > 0 ||
            MathList.divided.length > 0 ||
            MathList.continue_add.length > 0 ||
            MathList.on_multiple_data.length > 0 ||
            MathList.off_multiple_data.length > 0 ||
            MathList.continue_sub.length > 0 ||
            MathList.sub_on_multiple_data.length > 0 ||
            MathList.sub_off_multiple_data.length > 0 ||
            MathList.multiple.length > 0) ? (
            <Row>
              <div className="fixed-bottom ">
                <Icon
                  onClick={(e) =>
                    setShowModal(showModal === "none" ? "block" : "none")
                  }
                  value={showModal === "none" ? true : false}
                >
                  {showModal === "none" ? (
                    <i className="fa fa-chevron-up fa-md"></i>
                  ) : (
                    <i className="fa fa-chevron-down fa-md"></i>
                  )}
                </Icon>
                <Boxed
                  className="card-around-shadow"
                  style={{ display: showModal }}
                >
                  {/* <BoxBody> */}
                  <Row>
                    <Col lg={9}>
                      <OverFlow>
                        {!MathList.loading &&
                        MathList.continue_add.length > 0 ? (
                          <EarlyPrimaryFooter
                            title={"Starting from"}
                            number={` ${MathList.starting_add_value}, add ${MathList.continue_add_value} five times `}
                            closeBTN={(e) => continueAddClose(e)}
                            name={"continue_adding_manually"}
                            mathlist={MathList.continue_add}
                          />
                        ) : null}
                        {!MathList.loading &&
                        MathList.on_multiple_data.length > 0 ? (
                          <EarlyPrimaryFooter
                            title={"Starting from"}
                            number={` ${MathList.starting_on_multiple}, add ${MathList.continue_on_multiple} five times `}
                            closeBTN={(e) => onMultipleAddClose(e)}
                            name={"continue_add_on_multiple"}
                            mathlist={MathList.on_multiple_data}
                          />
                        ) : null}
                        {MathList.off_multiple_data.length > 0 ? (
                          <EarlyPrimaryFooter
                            title={"Starting from"}
                            number={` ${MathList.starting_off_multiple}, add ${MathList.continue_off_multiple} five times `}
                            closeBTN={(e) => offMultipleAddClose(e)}
                            name={"continue_add_off_multiple"}
                            mathlist={MathList.off_multiple_data}
                          />
                        ) : null}
                        {!MathList.loading &&
                        MathList.continue_sub.length > 0 ? (
                          <EarlyPrimaryFooter
                            title={"Starting from"}
                            number={`${MathList.starting_sub_value}, sub ${MathList.continue_sub_value} five times`}
                            closeBTN={(e) => continueSubClose(e)}
                            name={"continue_subtract_manually"}
                            mathlist={MathList.continue_sub}
                          />
                        ) : null}
                        {MathList.sub_on_multiple_data.length > 0 ? (
                          <EarlyPrimaryFooter
                            title={"Starting from"}
                            number={` ${MathList.sub_starting_on_multiple}, sub ${MathList.sub_continue_on_multiple} five times `}
                            closeBTN={(e) => subOnMultipleClose(e)}
                            name={"subtract_on_multiple"}
                            mathlist={MathList.sub_on_multiple_data}
                          />
                        ) : null}
                        {MathList.sub_off_multiple_data.length > 0 ? (
                          <EarlyPrimaryFooter
                            title={"Starting from"}
                            number={` ${MathList.sub_starting_off_multiple}, sub ${MathList.sub_continue_off_multiple} five times `}
                            closeBTN={(e) => subOffMultipleClose(e)}
                            name={"subtract_off_multiple"}
                            mathlist={MathList.sub_off_multiple_data}
                          />
                        ) : null}
                        {!MathList.loading &&
                        MathList.primaryMathList.length > 0 ? (
                          <EarlyPrimaryFooter
                            title={"Add to make"}
                            number={MathList.add_to_make_10}
                            closeBTN={(e) => addMakeClose(e)}
                            name={"Add_make_10"}
                            mathlist={MathList.primaryMathList}
                          />
                        ) : null}
                        {!MathList.loading && MathList.addToMark.length > 0 ? (
                          <EarlyPrimaryFooter
                            title={"Add to make"}
                            number={
                              MathList.add_to_make
                                ? MathList.add_to_make
                                : MathList.rand_add_to_make
                            }
                            closeBTN={(e) => addClose(e)}
                            name={"Add_make"}
                            mathlist={MathList.addToMark}
                          />
                        ) : null}
                        {!MathList.loading && MathList.add.length > 0 ? (
                          <EarlyPrimaryFooter
                            title={"Add"}
                            number={
                              MathList.add_value
                                ? MathList.add_value
                                : MathList.rand_add_value
                            }
                            closeBTN={(e) => addClose1(e)}
                            name={"Add"}
                            mathlist={MathList.add}
                          />
                        ) : null}
                        {!MathList.loading && MathList.subtract.length > 0 ? (
                          <EarlyPrimaryFooter
                            title={"Subtract"}
                            number={
                              MathList.subtract_value
                                ? MathList.subtract_value
                                : MathList.rand_subtract_value
                            }
                            closeBTN={(e) => subClose(e)}
                            name={"subtract"}
                            mathlist={MathList.subtract}
                          />
                        ) : null}
                        {!MathList.loading &&
                        MathList.subtractFromTEN.length > 0 ? (
                          <EarlyPrimaryFooter
                            title={"Subtract from"}
                            number={
                              MathList.subtractFromTEN_value
                                ? MathList.subtractFromTEN_value
                                : MathList.rand_subtractFromTEN_value
                            }
                            // number={context.subtraction_from_10}
                            closeBTN={(e) => subFrom10(e)}
                            name="Subtract_from_10"
                            mathlist={MathList.subtractFromTEN}
                          />
                        ) : null}
                        {!MathList.loading &&
                        MathList.subtractFrom.length > 0 ? (
                          <EarlyPrimaryFooter
                            title={"Subtract from"}
                            number={
                              MathList.subtractFrom_value
                                ? MathList.subtractFrom_value
                                : MathList.rand_subtractFrom_value
                            }
                            closeBTN={(e) => subFromClose(e)}
                            name="Subtract_from"
                            mathlist={MathList.subtractFrom}
                          />
                        ) : null}
                        {!MathList.loading && MathList.multiple.length > 0 ? (
                          <EarlyPrimaryFooter
                            title={"Multiply by"}
                            number={
                              MathList.multiple_value
                                ? MathList.multiple_value
                                : MathList.rand_multiple_value
                            }
                            closeBTN={(e) => multiclose(e)}
                            name="Multiply"
                            mathlist={MathList.multiple}
                          />
                        ) : null}
                        {!MathList.loading && MathList.divided.length > 0 ? (
                          <EarlyPrimaryFooter
                            title={"Divide by"}
                            number={
                              MathList.divided_value
                                ? MathList.divided_value
                                : MathList.rand_divided_value
                            }
                            // number={context.divide}
                            closeBTN={(e) => divClose(e)}
                            name="Divide"
                            mathlist={MathList.divided}
                          />
                        ) : null}
                      </OverFlow>
                    </Col>
                    <Col
                      md={3}
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        alignContent: "center",
                        justifyContent: "center",
                      }}
                    >
                      {MathList.maxLength.length === 3 ? (
                        <ButtonProcess>
                          <Link to="/early-primary/student">
                            <button>Proceed to Enter Names & Messages</button>
                          </Link>
                        </ButtonProcess>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                </Boxed>
              </div>
            </Row>
          ) : null}
        </Row>
      </Container>
    </Fragment>
  );
};

export default connect(null, {
  primaryMathslist,
  getadditionList,
  getSubtractionList,
  getSubtractionFromList,
  getMultiplyList,
  getDividedList,
  getContinueadditionList,
  getContinueSubtract,
  getOnMultipleList,
  getOffMultipleList,
  getOnMultipleListSubtract,
  getOffMultipleListSubtract,
})(EarlyPrimary);

const Heading = styled.h2`
  font-size: 20px;
`;

const SubHeading = styled.h5`
  font-size: 19px;
  color: #848484;
`;
const CustomsPara = styled.p`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  white-space: nowrap;
  width: 30%;
  margin-right: 10px;
`;
const CustomsParagraph = styled.p`
  // display: flex;
  justify-content: space-between;
  font-size: 13px;
  // white-space: nowrap;
  width: 70%;
  margin-right: 10px;
`;

const Boxed = styled(Card)`
  border-radius: 0.5rem;
  border: none;
`;

const BoxBody = styled(CardBody)`
  padding: 2rem 0.5rem;
`;

const ChildBoxed = styled(Card)`
  border: ${(props) =>
    props.active ? "1px solid #fff" : "1px solid " + props.theme.primaryColor};
  cursor: pointer;
  background-color: ${(props) =>
    props.active ? props.theme.primaryColor : "#fff"};
  color: ${(props) => (props.active ? "#fff" : props.theme.primaryColor)};
  p {
    padding: 10px 12px;
    margin-bottom: 0px;
    font-weight: 500;
  }
  margin-bottom: 16px;
`;
const ChildBoxedMake = styled(Card)`
  padding: 10px 12px;
`;

const CardHeading = styled.div`
  display: flex;
  justify-content: space-between;
  p {
    font-weight: 500;
  }
`;
const ButtonOrange = styled.button`
  height: 24px;
  width: 118px;
  border-radius: 35px;
  background-color: ${(props) =>
    props.active ? props.theme.primaryColor : "#fff"};
  border: none;
  font-size: 12px;
  padding: 0px;
  color: ${(props) => (props.active ? "white" : "black")};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  border: ${(props) =>
    props.active ? "1px solid #fff" : "1px solid " + props.theme.primaryColor};
`;
const CardHeadingChild = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  p {
    font-weight: 500;
  }
`;
const ButtonOrangeChild = styled.button`
  height: 24px;
  width: 125px;
  border-radius: 35px;
  background-color: ${(props) =>
    props.active ? props.theme.primaryColor : "#fff"};
  border: none;
  font-size: 12px;
  padding: 2px 5px;
  margin-right: 5px;
  color: ${(props) => (props.active ? "white" : "black")};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  border: ${(props) =>
    props.active ? "1px solid #fff" : "1px solid " + props.theme.primaryColor};
`;
const CustomsButton = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  @media (max-width: 1200px) {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
  }
`;
const CustomsNewButton = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 1200px) {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
  }
`;

const MarkBTN = styled.button`
  ${(props) => {
    if (props.actived) {
      return `
      background-color:${props.theme.primaryColor};      
      cursor:pointer;
      `;
    } else if (props.disabled) {
      return `
      background-color:#ffff !important;
      cursor:not-allowed;
      `;
    } else {
      return `
      background-color:#ffff;
      cursor:pointer;
      `;
    }
  }}
  border:${(props) => "1px solid " + props.theme.primaryColor};
  margin-right: 4px;
  border-radius: 4px;
  font-size: 12px;
  color: ${(props) => (props.actived ? "#fff" : "#000")};
  /* padding: ${(props) => (props.valueLength <= 9 ? "1px 4px" : "1px 2px")}; */
  width: 24px;
  height: 100%;
  @media (max-width: 1200px) {
    margin-top: 2px;
  }
`;
const ButtonProcess = styled.div`
  margin-top: 25px;
  background-color: ${(props) => props.theme.primaryColor};
  position: relative;
  border-radius: 7px;
  text-align: center;
  button {
    background-color: ${(props) => props.theme.primaryColor};
    border-radius: 7px;
    padding: 12px 4px;
    border: none;
    font-weight: 600;
    color: #fff;
  }
  a {
    text-decoration: none;
    color: #fff;
  }
`;
const Icon = styled.div`
  float: right;
  position: relative;
  top: ${(props) => (props.value === true ? "-6px" : "1px")};
  right: 10px;
  z-index: 10;
  cursor: pointer;
`;
const OverFlow = styled.div`
  display: flex;
  /* overflow-x: auto; */

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* border-radius: 10px; */
  }
  ::-webkit-scrollbar {
    height: 8px; /* height of horizontal scrollbar ← You're missing this */
    width: 8px; /* width of vertical scrollbar */
    border: 1px solid #d5d5d5;
  }
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.primaryColor};
    border-radius: 10px;
    height: 100%;
  }
`;
