import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
// import PropTypes from 'prop-types'
import { Row, Col, Card, CardHeader, CardBody, Container } from "reactstrap";
import styled from "styled-components";

import ToastMessage from "../../DefaultComponets/ToastMessage/ToastMessage";
import EarlyPrimaryFooter from "../Commons/Primary/Card/Index";
import Right_arrow from "../../assets/image/arrow-right.png";
import { InlineMath } from "react-katex";
import "katex/dist/katex.min.css";
import { Conversions } from "../../api/data/PrimaryMaths";
import {
  getAllFraction,
  getAllDecimal,
  getAllImproper,
  getAllDecimalFind,
  getAllPercentage,
  getAllFindOf,
  getAllMidImproper,
} from "../../action/primaryRational";
import { connect, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

const PrimaryMaths = (props) => {
  const dispatch = useDispatch();
  const {
    getAllImproper,
    getAllFraction,
    getAllDecimal,
    getAllDecimalFind,
    getAllPercentage,
    getAllFindOf,
    getAllMidImproper,
  } = props;
  const primaryMaths = useSelector((state) => state.primaryMaths);

  const { rationalList } = primaryMaths;
  const [showModal, setShowModal] = useState("block");
  const onCLick = (value) => {
    if (rationalList.length > 5) {
      toast.success("You can select maximum 6 activities only");
      return;
    }

    if (value === "simplify_fractions") {
      getAllImproper(value, 1);
    }
    if (value === "convert_to_improper_fractions") {
      getAllImproper(value, 2);
    }
    if (value === "convert_to_mixed_numbers") {
      getAllImproper(value, 3);
    }
    if (value === "convert_fractions_to_decimals") {
      getAllImproper(value, 4);
    }
    if (value === "convert_fractions_to_percentages") {
      getAllImproper(value, 5);
    }
    if (value === "convert_these_decimals_to_improper_or_proper_fractions") {
      getAllImproper(value, 6);
    }
    if (value === "convert_these_decimals_to_percentages") {
      getAllImproper(value, 7);
    }
    if (value === "convert_these_percentages_to_improper_or_proper_fractions") {
      getAllImproper(value, 8);
    }
    if (value === "convert_these_percentages_to_decimals") {
      getAllImproper(value, 9);
    }
    if (value === "simplify_mid_fractions") {
      getAllMidImproper(value, 1);
    }
    if (value === "convert_to_improper_mid_fractions") {
      getAllMidImproper(value, 2);
    }
    if (value === "convert_to_mixed_mid_numbers") {
      getAllMidImproper(value, 3);
    }
    if (value === "convert_mid_fractions_to_decimals") {
      getAllMidImproper(value, 4);
    }
    if (value === "convert_mid_fractions_to_percentages") {
      getAllMidImproper(value, 5);
    }
    if (
      value === "convert_mid_these_decimals_to_improper_or_proper_fractions"
    ) {
      getAllMidImproper(value, 6);
    }
    if (value === "convert_mid_these_decimals_to_percentages") {
      getAllMidImproper(value, 7);
    }
    if (
      value === "convert_mid_these_percentages_to_improper_or_proper_fractions"
    ) {
      getAllMidImproper(value, 8);
    }
    if (value === "convert_mid_these_percentages_to_decimals") {
      getAllMidImproper(value, 9);
    }
    // ************************************
    if (value === "find_1/2_of") {
      getAllFindOf(value, 1);
    }
    // ********************************

    if (value === "find_1/4_of") {
      getAllFindOf(value, 2);
    }
    if (value === "find_3/4_of") {
      getAllFindOf(value, 3);
    }
    if (value === "find_1/10_of") {
      getAllFindOf(value, 4);
    }
    if (value === "find_1/5_of") {
      getAllFindOf(value, 5);
    }
    if (value === "find_1/3_of") {
      getAllFindOf(value, 6);
    }
    //********************************** */
    if (value === "add_unit_fractions") {
      getAllFraction(value, 1);
    }
    if (value === "add_proper_fractions") {
      getAllFraction(value, 2);
    }
    if (value === "subtract_unit_fractions") {
      getAllFraction(value, 3);
    }
    if (value === "subtract_proper_fractions") {
      getAllFraction(value, 4);
    }
    if (value === "multiply_fractions") {
      getAllFraction(value, 5);
    }
    if (value === "divide_fractions") {
      console.debug(true);
      getAllFraction(value, 6);
    }
    if (value === "square_these_fractions") {
      getAllFraction(value, 7);
    }
    if (value === "square_root_these_fractions") {
      getAllFraction(value, 8);
    }

    // ********************************************
    if (value === "adding_decimal_fractions") {
      getAllDecimal(value, 1);
    }
    if (value === "subtracting_decimal_fractions") {
      getAllDecimal(value, 2);
    }
    if (value === "multiplying_decimal_fractions") {
      getAllDecimal(value, 3);
    }
    if (value === "divide_decimal_fractions") {
      getAllDecimal(value, 4);
    }
    if (value === "squaring_decimal_fractions") {
      getAllDecimal(value, 5);
    }
    if (value === "square_rooting_decimal_fractions") {
      getAllDecimal(value, 6);
    }
    if (value === "find_0.5_of") {
      getAllDecimalFind(value, 1);
    }
    if (value === "find_0.25_of") {
      getAllDecimalFind(value, 2);
    }
    if (value === "find_0.75_of") {
      getAllDecimalFind(value, 3);
    }
    if (value === "find_0.1_of") {
      getAllDecimalFind(value, 4);
    }
    if (value === "find_0.2_of") {
      getAllDecimalFind(value, 5);
    }
    if (value === "find_0.3_of") {
      getAllDecimalFind(value, 6);
    }

    if (value === "find_50%_of_quantity") {
      getAllPercentage(value, 1);
    }
    if (value === "find_25%_of_quantity") {
      getAllPercentage(value, 2);
    }
    if (value === "find_75%_of_quantity") {
      getAllPercentage(value, 3);
    }
    if (value === "find_10%_of_quantity") {
      getAllPercentage(value, 4);
    }
    if (value === "find_20%_of_quantity") {
      getAllPercentage(value, 5);
    }
    if (value === "find_33_1/3%_of_quantity") {
      getAllPercentage(value, 6);
    }
    if (value === "increase_by_10%") {
      getAllPercentage(value, 7);
    }

    if (value === "decrease_by_10%") {
      getAllPercentage(value, 8);
    }
    if (value === "increase_by_15%") {
      getAllPercentage(value, 9);
    }
    if (value === "decrease_by_15%") {
      getAllPercentage(value, 10);
    }
  };
  const OnClose = (value) => {
    dispatch({
      type: "REMOVE_RATIONAL_NUMBER",
      payload: value,
    });
  };
  const closeBTN = (e) => {
    dispatch({
      type: "REMOVE_RATIONAL_NUMBER",
      payload: e.target.value,
    });
  };

  return (
    <Fragment>
      <Container>
        <Row className="mt-4">
          <ToastMessage />
          <Col xl="12" sm="12" md="12" lg="12" className="mb-3">
            <HeadingSecondary>
            Step2: choose any six tasks from this set of Primary rational number maths tasks
            </HeadingSecondary>
          </Col>
          {/* <Col xl="8" sm="8" md="8" lg="8" className="text-center ">
            <Row className="ml-15">
              <Col xl="12" sm="12" md="12" lg="12">
                <Heading>Primary Maths</Heading>
              </Col>
              <Col xl="12" sm="12" md="12" lg="12" className="mb-5">
                <SubHeading>
                  Choose six tasks from any of the Primary Maths sets
                </SubHeading>
              </Col>
            </Row>
          </Col> */}
          {/* <Col xl="4" sm="4" md="4" lg="4">
            <BoxCard>
              <Link to="/primary-maths/whole-number">
                <BoxBody>
                  <BoxInLine>
                    <BoxHeading>Primary</BoxHeading>
                    <BoxPara boxpadding={1}>Rational Number</BoxPara>
                    <BoxImage>
                      <img src={Right_arrow} alt="right-arrow" />
                    </BoxImage>
                  </BoxInLine>
                </BoxBody>
              </Link>
            </BoxCard>
          </Col> */}
        </Row>
      </Container>
      <Container>
        <Row style={{ height: "83vh" }}>
          <Col xl="6" sm="12" md="6" lg="6" xs="12">
            <Card>
              <BoxHeader>Rational number</BoxHeader>
              <BoxBody>
                <TableResponsive>
                  <CustomTable className="card-table table table-borderless table-p-0 ">
                    <thead>
                      <tr>
                        <th>CONVERSIONS</th>
                        <th>MID-PRIMARY</th>
                        <th>LATE-PRIMARY</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Conversions.map((s, i) => {
                        let operationDataOne = rationalList.find(
                          (t) => t.type === s.opt1?.key
                        );
                        let opt0 = rationalList.find(
                          (t) => t.type === s.opt0?.key
                        );

                        // console.log(operationDataOne);
                        // console.log(opt0);
                        return (
                          <tr key={i}>
                            {s?.opt1?.value ? (
                              <td colSpan={s.opt1?.col}>
                                <TableBTNText
                                  isActive={operationDataOne ? true : false}
                                >
                                  {s?.opt1?.value}
                                </TableBTNText>
                              </td>
                            ) : (
                              <td colSpan={1}></td>
                            )}
                            {/* mid primary */}
                            {s?.opt0?.value ? (
                              <td colSpan={s.opt0?.col}>
                                <TableBTNOrange
                                  isActive={opt0 ? true : false}
                                  onClick={
                                    !opt0
                                      ? () => onCLick(s.opt0.key)
                                      : () => OnClose(s.opt0.key)
                                  }
                                >
                                  {/* {s?.opt0?.value} */}
                                  Select
                                </TableBTNOrange>
                              </td>
                            ) : (
                              <td colSpan={1}></td>
                            )}
                            {/* let primary */}
                            {s?.opt1?.value ? (
                              <td colSpan={s.opt1?.col}>
                                <TableBTNOrange
                                  isActive={operationDataOne ? true : false}
                                  onClick={
                                    !operationDataOne
                                      ? () => onCLick(s.opt1.key)
                                      : () => OnClose(s.opt1.key)
                                  }
                                >
                                  {/* {s?.opt1?.value} */}
                                  Select
                                </TableBTNOrange>
                              </td>
                            ) : (
                              <td colSpan={1}></td>
                            )}
                          </tr>
                        );
                      })}
                    </tbody>
                  </CustomTable>
                </TableResponsive>
              </BoxBody>
            </Card>
          </Col>
          <Col xl="6" sm="12" md="6" lg="6" xs="12">
            <Card>
              <BoxHeader>Rational number</BoxHeader>
              <BoxBody>
                <TableResponsive>
                  <CustomTable className="card-table table table-borderless table-p-0 ">
                    <thead>
                      <tr>
                        {/* <th>CONVERSIONS</th>
                        <th>MID-PRIMARY</th>
                        <th>LATE-PRIMARY</th> */}
                        <th colSpan={2}>FRACTIONS</th>
                        <th colSpan={2}>DECIMALS</th>
                        <th>PERCENTAGES</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Conversions.map((s, i) => {
                        let opt2 = rationalList.find(
                          (t) => t.type === s.opt2?.key
                        );
                        let opt2_2 = rationalList.find(
                          (t) => t.type === s.opt2_2?.key
                        );
                        let opt3 = rationalList.find(
                          (t) => t.type === s.opt3?.key
                        );
                        let opt3_2 = rationalList.find(
                          (t) => t.type === s.opt3_2?.key
                        );
                        let opt4 = rationalList.find(
                          (t) => t.type === s.opt4?.key
                        );
                        // console.log("🚀  opt4", opt4)
                        return (
                          <tr key={i}>
                            {s?.opt2?.value &&
                              (s.opt2?.col === 2 ? (
                                <td colSpan="2">
                                  <TableBTN
                                    isActive={opt2 ? true : false}
                                    onClick={
                                      !opt2
                                        ? () => onCLick(s.opt2.key)
                                        : () => OnClose(s.opt2.key)
                                    }
                                  >
                                    {s?.opt2?.value}
                                  </TableBTN>
                                </td>
                              ) : (
                                <>
                                  <td>
                                    <TableBTN
                                      colSpan="1"
                                      isActive={opt2 ? true : false}
                                      onClick={
                                        !opt2
                                          ? () => onCLick(s.opt2.key)
                                          : () => OnClose(s.opt2.key)
                                      }
                                    >
                                      {s?.opt2?.value}
                                    </TableBTN>
                                  </td>
                                  <td>
                                    <TableBTN
                                      colSpan="1"
                                      isActive={opt2_2 ? true : false}
                                      onClick={
                                        !opt2_2
                                          ? () => onCLick(s.opt2_2.key)
                                          : () => OnClose(s.opt2_2.key)
                                      }
                                    >
                                      {s?.opt2_2?.value}
                                    </TableBTN>
                                  </td>
                                </>
                              ))}
                            {s?.opt3?.value ? (
                              s.opt3?.col === 2 ? (
                                <td colSpan="2">
                                  <TableBTN
                                    isActive={opt3 ? true : false}
                                    onClick={
                                      !opt3
                                        ? () => onCLick(s.opt3.key)
                                        : () => OnClose(s.opt3.key)
                                    }
                                  >
                                    {s?.opt3?.value}
                                  </TableBTN>
                                </td>
                              ) : (
                                <>
                                  <td>
                                    <TableBTN
                                      colSpan="1"
                                      isActive={opt3 ? true : false}
                                      onClick={
                                        !opt3
                                          ? () => onCLick(s.opt3.key)
                                          : () => OnClose(s.opt3.key)
                                      }
                                    >
                                      {s?.opt3?.value}
                                    </TableBTN>
                                  </td>
                                  <td>
                                    <TableBTN
                                      colSpan="1"
                                      isActive={opt3_2 ? true : false}
                                      onClick={
                                        !opt3_2
                                          ? () => onCLick(s.opt3_2.key)
                                          : () => OnClose(s.opt3_2.key)
                                      }
                                    >
                                      {/* dotvalue */}
                                      {s?.opt3_2?.math ? (
                                        <InlineMath math="{\text{Find }} 0.\dot{3} {\text{ of}}" />
                                      ) : (
                                        s?.opt3_2?.value
                                       )} 
                                       {/* {s?.opt3_2?.value} */}
                                    </TableBTN>
                                  </td>
                                </>
                              )
                            ) : (
                              <td colSpan={2}></td>
                            )}
                            {s?.opt4?.value ? (
                              <td
                                colSpan={s.opt4?.col}
                                onClick={
                                  !opt4
                                    ? () => onCLick(s.opt4.key)
                                    : () => OnClose(s.opt4.key)
                                }
                              >
                                <TableBTN isActive={opt4 ? true : false}>
                                  {s?.opt4?.value}
                                </TableBTN>
                              </td>
                            ) : (
                              <td colSpan={1}></td>
                            )}
                          </tr>
                        );
                      })}
                    </tbody>
                  </CustomTable>
                </TableResponsive>
              </BoxBody>
            </Card>
          </Col>

          <Row>
            {rationalList.length > 0 && (
              <div className="fixed-bottom ">
                <Icon
                  onClick={(e) =>
                    setShowModal(showModal === "none" ? "block" : "none")
                  }
                  value={showModal === "none" ? true : false}
                >
                  {showModal === "none" ? (
                    <i className="fa fa-chevron-up fa-lg"></i>
                  ) : (
                    <i className="fa fa-chevron-down fa-lg"></i>
                  )}
                </Icon>

                <Boxed
                  className="card-around-shadow"
                  style={{ display: showModal }}
                >
                  <Row>
                    <Col lg={10}>
                      <OverFlow>
                        {rationalList.map((number, i) => (
                          <EarlyPrimaryFooter
                            key={i}
                            className="my-2"
                            title={number.title}
                            closeBTN={(e) => closeBTN(e)}
                            name={number.type}
                            mathlist={number.result}
                          />
                        ))}
                      </OverFlow>
                    </Col>

                    <Col
                      md={2}
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        alignContent: "center",
                        justifyContent: "center",
                      }}
                    >
                      <ButtonProcess>
                        <Link to="/primary-maths/rational-number/student">
                          <button>Proceed to Enter Names & Messages</button>
                        </Link>
                      </ButtonProcess>
                    </Col>
                  </Row>
                </Boxed>
                {/* </Col> */}
              </div>
            )}
          </Row>
        </Row>
      </Container>
    </Fragment>
  );
};

export default connect(null, {
  getAllImproper,
  getAllFraction,
  getAllDecimal,
  getAllDecimalFind,
  getAllPercentage,
  getAllFindOf,
  getAllMidImproper,
})(PrimaryMaths);

//
const Heading = styled.h1`
  font-size: 24px;
`;
const HeadingSecondary = styled.h2`
  font-size: 20px;
`;
const BoxBody = styled(CardBody)`
  background-color: #fbfbfb;
`;
const TableResponsive = styled.div`
  @media (max-width: 991px) {
    overflow-x: auto;
  }
`;

const BoxHeader = styled(CardHeader)`
  background-color: #fbfbfb;
  text-align: center;
`;
const SubHeading = styled.h5`
  font-size: 19px;
  color: #848484;
`;

const CustomTable = styled.table`
  thead {
    font-family: Metropolis;
    font-size: 9px;
    font-style: normal;
    font-weight: 600;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    @media (min-width: 1200px) {
      font-size: 13px;
    }
  }
`;
const TableBTN = styled.button`
  background: ${(props) =>
    props.isActive ? props.theme.primaryColor : "#ffffff"};
  box-shadow: 3px 5px 9px rgb(0 0 0 / 5%);
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.primaryColor};
  width: ${(props) => (props.operation ? "100%" : "100%")};
  font-size: 11px;
  color: ${(props) => (props.isActive ? "#ffffff" : "black")};
  text-align: start;
  padding: 5px 0px 5px 5px;
  .katex {
    font: normal 13 px var(--katexfont) !important;
    color: ${(props) => (props.isActive ? "#ffffff" : "black")}!important;
  }
`;

const TableBTNOrange = styled.button`
  background: ${(props) =>
    props.isActive ? props.theme.primaryColor : "#ffffff"};
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.05);
  border-radius: 27px;
  /* width: 95.58px; */
  width: 100%;
  height: 29px;
  border: ${(props) =>
    props.active !== true
      ? ` 1.5px solid ${props.theme.primaryColor}`
      : "white"};

  font-size: 11px;
  color: ${(props) =>
    props.isActive ? "#ffffff" : `${props.theme.primaryColor}`};
`;

const TableBTNText = styled.button`
  background: #ffffff;
  box-shadow: 3px 5px 9px rgb(0 0 0 / 5%);
  border-radius: 5px;
  border: none;
  width: ${(props) => (props.operation ? "100%" : "100%")};
  font-size: 11px;

  text-align: start;
  padding: 5px 0px 5px 6px;
`;

const Boxed = styled(Card)`
  border: none;
  padding: 5px 10px;
`;
const ButtonProcess = styled.div`
  margin-top: 25px;
  background-color: ${(props) => props.theme.primaryColor};
  position: relative;
  border-radius: 7px;
  text-align: center;
  button {
    background-color: ${(props) => props.theme.primaryColor};
    border-radius: 7px;
    padding: 12px 4px;
    border: none;
    font-weight: 600;
    color: #fff;
  }
  a {
    text-decoration: none;
    color: #fff;
  }
`;
const BoxCard = styled(Card)`
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  a {
    text-decoration: none;
  }
`;

const BoxInLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const BoxHeading = styled.h3`
  font-size: 18px;
  margin-bottom: 0px;
  font-family: "Saira";
  flex: 1 1 auto;
  color: black;
`;

const BoxPara = styled.p`
  font-size: 13px;
  font-weight: 400;
  color: #7c7c7c;
  font-family: "Roboto";
  margin-bottom: 0px;
  text-decoration: none;
  flex: ${(props) => (props.boxpadding ? "1 1 35%" : "1 1 49%")};
  @media (max-width: 1200px) {
    flex: ${(props) => (props.boxpadding ? "1 1 30%" : "1 1 49%")};
  }
`;
const BoxImage = styled.div``;

const OverFlow = styled.div`
  display: flex;
  /* overflow-x: auto; */

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* border-radius: 10px; */
  }
  ::-webkit-scrollbar {
    height: 8px; /* height of horizontal scrollbar ← You're missing this */
    width: 8px; /* width of vertical scrollbar */
    border: 1px solid #d5d5d5;
  }
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.primaryColor};
    border-radius: 10px;
    height: 100%;
  }
`;

const Icon = styled.div`
  float: right;
  position: relative;
  top: ${(props) => (props.value === true ? "-8px" : "7px")};
  right: 10px;
  z-index: 10;
  cursor: pointer;
`;
