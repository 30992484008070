import React, { useState } from 'react';
import Funsheet from '../Commons/Funsheet/defaultFunsheet';
// import PropTypes from 'prop-types'
import swal from 'sweetalert';
import { toast } from 'react-toastify';
import { v4 as uuidv4, v4 } from 'uuid';
import { useSelector } from 'react-redux';
import Card from './Card';

const PrimaryMathFunsheet = (props) => {
  const MathList = useSelector((state) => state.primaryMath);
  const [studentList, setStudentList] = useState([]);
  const [commentList, setCommentList] = useState([]);
  const [studentInput, setStudentInput] = useState(String);
  const [commentInput, setCommentInput] = useState(String);
  const [comments, setComments] = useState([]);
  const [commentSelected, setCommentSelected] = useState(null);
  const [commentError, setCommentError] = useState('');
  const [studentError, setStudentError] = useState('');
  //
  const onSubmit = (e) => {
    e.preventDefault();
    let validationFlag = true;
    let students = studentInput.trim();

    if (!students.length) {
      validationFlag = false;
      setStudentError('Student field is required');
    }
    if (validationFlag) {
      setStudentError('');

      if (students.match(/\n/g)) {
        let studentArrayData = students.split('\n');
        let studentMap = studentArrayData.map((data) => {
          return {
            id: v4(),
            message: data.trim(),
          };
        });

        // :use -> contact two array
        let finalData = [...studentList, ...studentMap];

        setStudentList(finalData);
      } else {
        let studentArrayData = students.split(',');
        let studentMap = studentArrayData.map((data) => {
          return {
            id: v4(),
            message: data,
          };
        });
        let finalData = [...studentList, ...studentMap];
        setStudentList(finalData);
      }
      setStudentInput('');
    }
  };
  const studentClear = () => {
    setStudentList([]);
  };
  const studentDelete = (data, i) => {
    swal({
      title: 'Are you sure?',
      text: `Are you sure that you want to delete ${data.message}?`,
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        toast.success('Student has been deleted successfully');
        let filterData = [...studentList];
        filterData.splice(i, 1);
        setStudentList(filterData);
      }
    });
  };
  const onKeyPress = async (e) => {
    if (e.key === 'Enter') {
      let students = studentInput.trim();
      let validationFlag = true;
      if (!students.length > 0) {
        validationFlag = false;
        setStudentError('Student field is required');
      }
      if (validationFlag) {
        setStudentError('');

        if (students.match(/\n/g)) {
          let studentArrayData = students.split('\n');
          let studentMap = await studentArrayData.map((data) => {
            return {
              id: v4(),
              message: data,
            };
          });
          // :use -> contact two array
          let finalData = [...studentList, ...studentMap];

          setStudentList(finalData);
        } else {
          let studentArrayData = students.split(',');
          let studentMap = studentArrayData.map((data) => {
            return {
              id: v4(),
              message: data,
            };
          });
          let finalData = [...studentList, ...studentMap];

          setStudentList(finalData);
        }
      }
      setTimeout(() => {
        setStudentInput('');
      }, 1);
      // setStudentInput('')
    }
  };
  const onStudentPaste = (e) => {
    let studentData = e.clipboardData.getData('Text');
    let students = studentData.trim();
    if (students.match(/\n/g)) {
      let studentArrayData = students.split('\n');
      let studentMap = studentArrayData.map((data) => {
        return {
          id: v4(),
          message: data.trim(),
        };
      });

      // :use -> contact two array
      let finalData = [...studentList, ...studentMap];

      setStudentList(finalData);
    } else {
      let studentArrayData = students.split(',');
      let studentMap = studentArrayData.map((data) => {
        return {
          id: v4(),
          message: data,
        };
      });
      let finalData = [...studentList, ...studentMap];

      setStudentList(finalData);
    }
    setTimeout(() => {
      setStudentInput('');
    }, 1);
  };
  //
  const onSubmitComment = (e) => {
    e.preventDefault();
    let validationFlag = true;
    let showAlertMessage = false;
    if (!commentInput.length) {
      validationFlag = false;
      setCommentError('Message field is required');
    }
    if (validationFlag) {
      setCommentError('');
      let comments = commentInput.trim();
      let commentArray = comments.split('\n');

      let newData = commentArray.map((data) => {
        if (data.length > 48) {
          showAlertMessage = true;
        }
        return {
          id: uuidv4(),
          message: data,
          discard: true,
        };
      });

      let finalData = [...commentList, ...newData];
      setCommentList(finalData);
      setTimeout(() => {
        setCommentInput('');
      }, 1);

      if (showAlertMessage) {
        showErrorMessage();
      }
    }
  };

  const DeleteComment = (data, i) => {
    swal({
      title: 'Are you sure?',
      text: `Are you sure that you want to delete comment?`,
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        toast.success('Comment has been deleted successfully');
        let filterData = [...commentList];
        filterData.splice(i, 1);
        setCommentList(filterData);
      }
    });
  };
  const onCommentPaste = (e) => {
    let studentData = e.clipboardData.getData('Text');

    let showAlertMessage = false;
    let comments = studentData.trim();
    let commentArray = comments.split('\n');

    let newData = commentArray.map((data) => {
      if (data.length > 48) {
        showAlertMessage = true;
      }
      return {
        id: uuidv4(),
        message: data,
        discard: true,
      };
    });

    let finalData = [...commentList, ...newData];
    setCommentList(finalData);

    setTimeout(() => {
      setCommentInput('');
    }, 1);
    if (showAlertMessage) {
      showErrorMessage();
    }
  };
  const commentClear = () => {
    setCommentList([]);
    setCommentSelected(null);
  };

  const onKeyPressComment = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      let validationFlag = true;
      let showAlertMessage = false;
      if (!commentInput.length) {
        validationFlag = false;
        setCommentError('Message field is required');
      }
      if (validationFlag) {
        setCommentError('');
        let comments = commentInput.trim();
        let commentArray = comments.split('\n');

        let newData = commentArray.map((data) => {
          if (data.length > 48) {
            // setShowAlert(true)
            showAlertMessage = true;
          }
          return {
            id: uuidv4(),
            message: data,
            discard: true,
          };
        });

        let finalData = [...commentList, ...newData];
        setCommentList(finalData);
        setTimeout(() => {
          setCommentInput('');
        }, 1);
        if (showAlertMessage) {
          showErrorMessage();
        }
      }
    }
  };
  const DiscardSpace = (data, i) => {
    // set comment space data
    let commentDataExist = comments.find((e) => e.id === data.id);

    if (!commentDataExist) {
      let commentObj = {
        id: data.id,
        comment: data.message,
      };
      setComments([...comments, commentObj]);
    }
    // remove comment space
    let finalData = [...commentList];
    let data1 = finalData[i].message.split(/\s/).join('');
    finalData[i].message = data1;
    finalData[i].discard = false;
    setCommentList(finalData);
  };
  const KeepSpace = (data, i) => {
    let finalData = [...commentList];
    for (let j = 0; j < comments.length; j++) {
      const element = comments[j];
      if (element.id === data.id) {
        finalData[i].message = element.comment;
        finalData[i].discard = true;
      }
    }

    setCommentList(finalData);
  };

  const onListClick = (i) => {
    if (commentSelected === i) {
      setCommentSelected(null);
    } else if (!commentSelected || commentSelected !== i) {
      setCommentSelected(i);
    }
  };

  const showErrorMessage = () => {
    let message = '';

    swal({
      text: 'Some of the message is longer than allowed limit, please check all messages',
    });
    return message;
  };

  // on Submit Click
  const onPDFGenerate = () => {
    //
    // console.log(true);
    // let dataArray = []
    // dataArray.push(
    //   { make_to_add_10: MathList.add_to_make_10 },
    //   {
    //     make_to_add: MathList.add_to_make || MathList.rand_add_to_make,
    //   },
    //   {
    //     add: MathList.add_value || MathList.rand_add_value,
    //   },
    //   {
    //     subtract: MathList.subtract_value || MathList.rand_subtract_value,
    //   },
    //   {
    //     subtract_from_10:
    //       MathList.subtractFromTEN_value || MathList.rand_subtractFromTEN_value,
    //   },
    //   {
    //     subtract_from:
    //       MathList.subtractFrom_value || MathList.rand_subtractFrom_value,
    //   },
    //   {
    //     multiply: MathList.multiple_value || MathList.rand_multiple_value,
    //   },
    //   {
    //     divide: MathList.divided_value || MathList.rand_divided_value,
    //   }
    // )
    // let newObj = {}
    // newObj.student = studentList.map((data) => {
    //   return data.message
    // })
    // newObj.messages = commentList.map((data) => {
    //   return data.message
    // })
    // newObj.studentFunsheet = dataArray
    // props.earlyPrimaryPdfGenerate(newObj)
  };
  return (
    <Funsheet
      studentClear={studentClear}
      studentList={studentList}
      onSubmit={onSubmit}
      setStudentInput={setStudentInput}
      studentInput={studentInput}
      studentDelete={studentDelete}
      onListClick={onListClick}
      commentSelected={commentSelected}
      onKeyPress={onKeyPress}
      studentError={studentError}
      onStudentPaste={onStudentPaste}
      commentClear={commentClear}
      commentList={commentList}
      commentInput={commentInput}
      onSubmitComment={onSubmitComment}
      setCommentInput={setCommentInput}
      DeleteComment={DeleteComment}
      DiscardSpace={DiscardSpace}
      KeepSpace={KeepSpace}
      onKeyPressComment={onKeyPressComment}
      commentError={commentError}
      onCommentPaste={onCommentPaste}
      onPDFGenerate={onPDFGenerate}
    >
      <Card />
    </Funsheet>
  );
};

PrimaryMathFunsheet.propTypes = {};

export default PrimaryMathFunsheet;
