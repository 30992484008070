import { combineReducers } from 'redux'
import PrimaryMathReducer from './PrimaryMathReducer'
import PrimaryMathsReducer from './PrimaryMathsReducer'
import languageReducer from './languageReducer'
import labelReducer from './labelReducer'
import { loadingBarReducer } from 'react-redux-loading-bar'
export default combineReducers({
  primaryMath: PrimaryMathReducer,
  primaryMaths: PrimaryMathsReducer,
  loadingBar: loadingBarReducer,
  languages: languageReducer,
  label: labelReducer,
})
