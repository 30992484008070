const initialState = {
  primaryMathAddition: [],
  loading: true,
  rationalList: [],
}

const PrimaryMathReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case 'GET_WHOLE_NUMBER':
      return {
        ...state,
        primaryMathAddition: payload,
        loading: false,
      }
    case 'REMOVE_WHOLE_NUMBER':
      return {
        ...state,
        primaryMathAddition: state.primaryMathAddition.filter(
          (number) => number.type !== payload
        ),
        loading: false,
      }
    // Rational
    case 'GET_RATIONAL_NUMBER':
      return {
        ...state,
        rationalList: payload,
        loading: false,
      }
    case 'REMOVE_RATIONAL_NUMBER':
      return {
        ...state,
        rationalList: state.rationalList.filter(
          (number) => number.type !== payload
        ),
        loading: false,
      }

    default:
      return state
  }
}
export default PrimaryMathReducer
